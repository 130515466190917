import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthLayout, Card, SubTitle, Title } from 'components';
import { ForgotPasswordForm } from 'components/ForgotPasswordForm';
import { ForgotPasswordFormData } from 'components/ForgotPasswordForm/ForgotPasswordForm';
import { Routes } from 'enums/Routes';
import { useRestorePassword } from 'hooks/api/useRestorePassword';

import styles from './styles.module.scss';

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { mutate: handleRestorePassword, isPending } = useRestorePassword();

  const methods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = useCallback(
    ({ email }: ForgotPasswordFormData) => {
      const trimedEmail = email.trim();
      handleRestorePassword({ email: trimedEmail });
    },
    [handleRestorePassword]
  );

  return (
    <AuthLayout>
      <Card>
        <Title
          className={styles.title}
          title={t('Page.Auth.ForgotPassword.Title')}
        />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.ForgotPassword.Subtitle')}
        </SubTitle>

        <FormProvider {...methods}>
          <ForgotPasswordForm isLoading={isPending} onSubmit={onSubmit} />
        </FormProvider>

        <SubTitle className={styles['footer-subtitle']}>
          {t('Page.Auth.ForgotPassword.Note')}

          <Link className={styles['main-link']} to={Routes.Login}>
            {t('Page.Auth.ForgotPassword.ReturnToLogin')}
          </Link>
        </SubTitle>
      </Card>
    </AuthLayout>
  );
};
