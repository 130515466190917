import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { activateAccount } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { useSnackbar } from '../useSnackbar';
import { useUserInfo } from './useUserInfo';

export const useActivateAccount = (callback?: () => void) => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const snackbar = useSnackbar();

  return useMutation<User, ApiError>({
    mutationFn: () => activateAccount(),
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.userInfo, data);
      snackbar.success.commonSuccess(t('Notifications.ChangesSavedSuccess'));
      mixpanel?.track(MixpanelEvents.AccountActivation, {
        'User ID': userInfo?.id,
        Email: userInfo?.email
      });

      if (callback) callback();
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
