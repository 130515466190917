import { FC, useMemo } from 'react';
import { MapTypes } from 'enums';
import { Feature, FeatureCollection } from 'geojson';

import { HEAT_LAYER_DATA } from '../constants/mapSettings';
import { DataGroup } from '../hooks/useMapDataGroups';
import { MapLayer } from '../MapLayer';

interface Props {
  activeYear: number | null;
  dataGroups: DataGroup[];
  groupFilter: string[];
}

export const HeatMapLayer: FC<Props> = ({
  activeYear,
  dataGroups,
  groupFilter
}) => {
  const features = useMemo(() => {
    const features: Feature[] = [];

    dataGroups.forEach(({ value, label, name, isOther }) => {
      if (groupFilter.includes(isOther ? name : label)) {
        value
          .filter(
            (item) =>
              isOther ||
              (item[2] !== undefined && item[2] <= Number(activeYear))
          )
          .forEach((coordinates) => {
            features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coordinates[1], coordinates[0]]
              }
            } as Feature);
          });
      }
    });

    return features;
  }, [activeYear, dataGroups, groupFilter]);

  const sourceData: FeatureCollection = {
    type: 'FeatureCollection',
    features
  };

  return (
    <MapLayer
      mapType={MapTypes.Heat}
      sourceData={sourceData}
      layerData={HEAT_LAYER_DATA}
    />
  );
};
