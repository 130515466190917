import { FC } from 'react';
import { MapTypes } from 'enums';
import { Feature, FeatureCollection } from 'geojson';

import {
  CLUSTERS_LAYER_DATA,
  COUNT_LAYER_DATA,
  DEFAULT_CLUSTER_SOURCE_ID,
  UNCLUSTERED_LAYER_DATA
} from '../constants/mapSettings';
import { DataGroup } from '../hooks/useMapDataGroups';
import { MapLayer } from '../MapLayer';

interface Props {
  activeYear: number | null;
  dataGroups: DataGroup[];
  groupFilter: string[];
}

const clusterMapLayers = [
  CLUSTERS_LAYER_DATA,
  COUNT_LAYER_DATA,
  UNCLUSTERED_LAYER_DATA
];

export const ClusterMapLayer: FC<Props> = ({
  activeYear,
  dataGroups,
  groupFilter
}) => {
  const sourceData: FeatureCollection = {
    type: 'FeatureCollection',
    features: []
  };

  dataGroups.forEach(({ value, label, name, isOther }) => {
    if (groupFilter.includes(isOther ? name : label)) {
      value
        .filter(
          (item) =>
            isOther || (item[2] !== undefined && item[2] <= Number(activeYear))
        )
        .forEach((coordinates) => {
          sourceData.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [coordinates[1], coordinates[0]]
            }
          } as Feature);
        });
    }
  });

  const sourceOptions = {
    id: DEFAULT_CLUSTER_SOURCE_ID,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50
  };

  return (
    <>
      {clusterMapLayers.map((layerData, index) => (
        <MapLayer
          mapType={MapTypes.Cluster}
          key={layerData.id}
          index={index}
          sourceData={sourceData}
          layerData={layerData}
          sourceOptions={sourceOptions}
        />
      ))}
    </>
  );
};
