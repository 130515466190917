import { FC, useMemo, useRef, useState } from 'react';
import MapGl, { type MapRef, NavigationControl } from 'react-map-gl';
import { WidgetEmptyState } from 'components';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { MapTypes } from 'enums';

import { SelectedFilterItem } from '../WidgetFilter';
import { ClusterMapLayer } from './ClusterMapLayer';
import { MAP_PROJECTION } from './constants/mapSettings';
import { DotsMapLayer } from './DotsMapLayer';
import { FilterMapLayer } from './FilterMapLayer';
import { FoundationYearFilter } from './FoundationYearFilter';
import { HeatMapLayer } from './HeatMapLayer';
import { useFoundationYearFilter } from './hooks/useFoundationYearFilter';
import { useMap } from './hooks/useMap';
import { useMapDataGroups } from './hooks/useMapDataGroups';
import { MapHeader } from './MapHeader';
import { MapHeatScale } from './MapHeatScale';
import { MapLegend } from './MapLegend';
import { MapTypeSelect } from './MapTypeSelect';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  data: DashboardComponent;
}

export interface MapSizes {
  width: number;
  height: number;
}

export interface MapBoundaries {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

export const Map: FC<Props> = ({ title, data }) => {
  const { filters, params } = data;

  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.Dots);
  const [mapSizes, setMapSizes] = useState<MapSizes | null>(null);
  const [activeYear, setActiveYear] = useState<number | null>(null);
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [selectedRegionOnMap, setSelectedRegionOnMap] =
    useState<SelectedFilterItem>(null);
  const [mapBoundaries, setMapBoundaries] = useState<MapBoundaries | null>(
    null
  );

  const dataGroups = useMapDataGroups({
    data: data.params?.data,
    mapType
  });

  const { startYear, endYear, foundationsByYear, isFilterVisible } =
    useFoundationYearFilter({
      filters,
      mapType,
      activeYear,
      dataGroups,
      setActiveYear
    });

  const mapDataVisualisation = useMemo(() => {
    switch (mapType) {
      case MapTypes.Dots:
        return (
          <DotsMapLayer
            mapType={mapType}
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      case MapTypes.Heat:
        return (
          <HeatMapLayer
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      case MapTypes.Cluster:
        return (
          <ClusterMapLayer
            dataGroups={dataGroups}
            activeYear={activeYear}
            groupFilter={groupFilter}
          />
        );
      default:
        return null;
    }
  }, [mapType, dataGroups, activeYear, groupFilter]);

  const { handleMapLoad } = useMap({
    data: params?.data,
    mapType: data?.mapType,
    mapRef,
    filters,
    mapSizes,
    headerRef,
    containerRef,
    mapBoundaries,
    setMapType,
    setMapSizes,
    setGroupFilter,
    setMapBoundaries,
    setSelectedRegionOnMap
  });

  return (
    <div className={styles.container}>
      <MapHeader
        type={params?.type || ''}
        title={title}
        hasFilter={!!params?.codes?.length}
        headerRef={headerRef}
        selectedItem={selectedRegionOnMap}
      />

      {params?.data?.length ? (
        <div className={styles.map}>
          <div ref={containerRef}>
            <MapGl
              ref={mapRef}
              scrollZoom={false}
              dragRotate={false}
              style={{ ...mapSizes }}
              onLoad={handleMapLoad}
              mapStyle="mapbox://styles/mapbox/light-v11"
              projection={MAP_PROJECTION}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            >
              <NavigationControl showCompass={false} position="top-left" />

              {!!params?.codes?.length && (
                <FilterMapLayer
                  codes={params.codes}
                  selectedRegionOnMap={selectedRegionOnMap}
                />
              )}

              {mapDataVisualisation}

              <MapTypeSelect
                mapType={mapType}
                widgetId={data.id}
                groupFilter={groupFilter}
                setMapType={setMapType}
              />
              <MapHeatScale mapType={mapType} />
              <MapLegend
                widgetId={data.id}
                mapType={mapType}
                dataGroups={dataGroups}
                groupFilter={groupFilter}
                setGroupFilter={setGroupFilter}
              />
            </MapGl>

            {isFilterVisible && (
              <FoundationYearFilter
                width={containerRef.current?.offsetWidth || 0}
                endYear={endYear}
                startYear={startYear}
                activeYear={Number(activeYear)}
                setActiveYear={setActiveYear}
                foundationsByYear={foundationsByYear}
              />
            )}
          </div>
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};
