import { useQuery } from '@tanstack/react-query';
import { fetchChats } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums/QueryKeys.enum';

export const useFilteredChats = (showArchived: boolean) => {
  const { data, refetch, isLoading } = useQuery<Chat[], ApiError>({
    queryKey: queryKeys.filteredChats({ includeArchived: showArchived }),
    queryFn: () => fetchChats({ includeArchived: showArchived }),
    retry: 1
  });

  return { data, refetch, isLoading };
};
