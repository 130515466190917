import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as LogoIcon } from 'assets/logo-inverted.svg';
import { ReactComponent as SidebarIcon } from 'assets/sidebar-inverted.svg';
import cn from 'classnames';
import { Sidebar } from 'components';
import { Routes } from 'enums/Routes';
import {
  useAuthContext,
  useGlobalShortcutsContext,
  useIsExternalUserDashboard,
  useMobile
} from 'hooks';
import { useUserInfo } from 'hooks/api';

import { DashboardsInfo } from './DashboardsInfo';
import { HamburgerMenu } from './HamburgerMenu';

import styles from './styles.module.scss';

const limitedHeaderRoutes = [Routes.Checkout, Routes.OneTimePaymentCheckout];

export const Header: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMobile();
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();
  const shortcutsContext = useGlobalShortcutsContext();
  const isDashboardPage = /dashboard/.test(location.pathname);
  const isExternalUserDashboard = useIsExternalUserDashboard();

  const showOnlyLogo = limitedHeaderRoutes.includes(
    location.pathname as Routes
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () =>
    setIsSidebarOpen((prevSidebarState) => !prevSidebarState);

  const openSidebar = useCallback(() => setIsSidebarOpen(true), []);

  const closeSidebar = useCallback(() => setIsSidebarOpen(false), []);

  const handleUpgradePlanButtonClick = () => {
    navigate(Routes.SubscriptionPlans);
  };

  useEffect(() => {
    shortcutsContext.setSidebarVisibilityHandler(() => toggleSidebar);

    return () => shortcutsContext.setSidebarVisibilityHandler(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserVerified = userInfo?.hasCompleteInfo && isAuthenticated;
  const showExtraData = isUserVerified && !showOnlyLogo;
  const showUpgradePlanButton = !isMobile && userInfo?.isFreeUser;

  return (
    <>
      {showExtraData && !isExternalUserDashboard && (
        <Sidebar
          onClose={closeSidebar}
          onOpen={openSidebar}
          open={isSidebarOpen}
        />
      )}

      <header
        className={cn(
          styles.header,
          isExternalUserDashboard && 'hidden',
          isDashboardPage && styles['dashboard-header']
        )}
      >
        <div className={styles['logo-container']}>
          <Link to={Routes.NewChat} className={styles.logo}>
            <LogoIcon />
          </Link>

          {showExtraData && (
            <SidebarIcon
              onClick={openSidebar}
              data-testid="sidebar-icon"
              className={styles['sidebar-icon']}
            />
          )}
        </div>
        <div className={styles.navigation}>
          {showExtraData && (
            <>
              <DashboardsInfo />

              {showUpgradePlanButton && (
                <Button
                  disableRipple
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={handleUpgradePlanButtonClick}
                  className={cn(styles['upgrade-plan'], 'brilliance')}
                >
                  {t('Common.UpgradePlan')}
                </Button>
              )}

              <HamburgerMenu />
            </>
          )}
        </div>
      </header>
    </>
  );
};
