/* eslint-disable react/no-unknown-property */
import { FC, useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Mesh } from 'three';

interface Props {
  color: string;
  position: [x: number, y: number, z: number];
}

export const Particle: FC<Props> = ({ color, position }) => {
  const particleRef = useRef<Mesh>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const direction = useRef(0);
  const force = 0.004;

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      direction.current = (Math.random() - 0.5) * force;
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useFrame(() => {
    if (particleRef.current) {
      particleRef.current.position.y += direction.current;
    }
  });

  return (
    <mesh castShadow ref={particleRef} position={position}>
      <sphereGeometry args={[0.012, 5, 5]} />
      <meshPhongMaterial shininess={0.2} color={color} />
    </mesh>
  );
};
