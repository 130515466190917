import * as d3 from 'd3';

import { SelectedFilterItem } from '../../WidgetFilter';

interface ZoomableIcicleData {
  name: string;
  full_name?: string;
  code?: string;
  value?: number;
  children?: Array<ZoomableIcicleData> | null;
}

export const renderZoomableIcicleChart = ({
  width,
  height,
  data,
  isMobile,
  selectItem,
  svgElement
}: {
  width: number;
  height: number;
  isMobile: boolean;
  data: ZoomableIcicleData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
  svgElement: d3.Selection<SVGGElement | null, unknown, null, undefined>;
}) => {
  svgElement.selectAll('*').remove();

  const color = d3.scaleOrdinal(
    d3.quantize(
      d3.interpolateRgbBasis(['#f57a00', '#ffe0b0']),
      (data.children?.length || 0) + 1
    )
  );

  const hierarchy = d3
    .hierarchy<ZoomableIcicleData>(data)
    .sum((d) => d.value || 0)
    .sort((a, b) => b.height - a.height || (b.value || 0) - (a.value || 0));

  const root = d3
    .partition<ZoomableIcicleData>()
    .size([height, ((hierarchy.height + 1) * width) / 3])(hierarchy);

  // Append cells.
  const cell = svgElement
    .selectAll('g')
    .data(root.descendants())
    .join('g')
    .attr('transform', (d) => `translate(${d.y0},${d.x0})`);

  const rectHeight = (d: d3.HierarchyRectangularNode<ZoomableIcicleData>) => {
    return d.x1 - d.x0 - Math.min(1, (d.x1 - d.x0) / 2);
  };

  const calculateDimensions = (
    d: d3.HierarchyRectangularNode<ZoomableIcicleData>
  ) => {
    const width = d.y1 - d.y0 - 1;
    const height = rectHeight(d);

    return { width, height };
  };

  const labelVisible = (d: d3.HierarchyRectangularNode<ZoomableIcicleData>) => {
    return calculateDimensions(d).height >= 35 ? 'visibile' : 'hidden';
  };

  const rect = cell
    .append('rect')
    .attr('width', (d) => calculateDimensions(d).width)
    .attr('height', (d) => calculateDimensions(d).height)
    .attr('fill-opacity', (d) => {
      return d.depth <= 1 ? 0.6 : 0.4;
    })
    .attr('fill', (d) => {
      if (!d.depth) return '#ef6a00';
      // @ts-expect-error error type
      // eslint-disable-next-line no-param-reassign
      while (d.depth > 1) d = d.parent;
      return color(d.data.name);
    })
    .style('cursor', 'pointer')
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .on('click', (e, element) => clicked(e, element));

  cell
    .append('foreignObject')
    .attr('x', 3)
    .attr('y', 0)
    .attr('width', (d) => Math.max(calculateDimensions(d).width - 3, 0))
    .attr('height', (d) => Math.max(calculateDimensions(d).height - 3, 0))
    .attr('visibility', (d) => labelVisible(d))
    .style('user-select', 'none')
    .attr('pointer-events', 'none')
    .append('xhtml:div')
    .style('overflow', 'hidden')
    .style('white-space', 'normal')
    .style('text-overflow', 'ellipsis')
    .style('font-weight', (d) => (d === root ? 'bold' : null))
    .style('font-size', isMobile ? '22px' : '14px')
    .style('line-height', '1.5')
    .html((d) => d.data.full_name || d.data.name);

  cell.append('title').text((d) => d.data.full_name || d.data.name);

  // On click, change the focus and transitions it into view.
  let focus = root;
  const clicked = (
    event: MouseEvent,
    p: d3.HierarchyRectangularNode<ZoomableIcicleData>
  ) => {
    // @ts-expect-error error type
    // eslint-disable-next-line no-param-reassign
    focus = focus === p ? (p = p.parent) : p;

    if (p) {
      selectItem(
        p.depth === 0
          ? null
          : {
              name: p.data.name,
              code: p.data.code || ''
            }
      );
    }

    if (p) {
      root.each(
        // eslint-disable-next-line no-return-assign
        (d) => {
          if (p) {
            // eslint-disable-next-line no-param-reassign
            (d as any).target = {
              x0: ((d.x0 - p.x0) / (p.x1 - p.x0)) * height,
              x1: ((d.x1 - p.x0) / (p.x1 - p.x0)) * height,
              y0: d.y0 - p.y0,
              y1: d.y1 - p.y0
            };
          }
        }
      );

      const t = cell
        .transition()
        .duration(500)
        // @ts-expect-error error type
        .attr('transform', (d) => `translate(${d.target?.y0},${d.target?.x0})`);

      rect
        .transition(t)
        // @ts-expect-error error type
        .attr('height', (d) => rectHeight(d.target));

      const labels = svgElement.selectAll('foreignObject');

      labels
        // @ts-expect-error error type
        .attr('visibility', (d) => labelVisible(d.target))
        .attr('height', (d) =>
          // @ts-expect-error error type
          Math.max(calculateDimensions(d.target).height - 3, 0)
        );
    }
  };
};
