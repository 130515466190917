import { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, MobileStepper as MuiMobileStepper } from '@mui/material';
import { ReactComponent as ViewListIcon } from 'assets/view-list.svg';
import { ReactComponent as ViewSliderIcon } from 'assets/view-slider.svg';
import cn from 'classnames';
import { IconSwitch, WidgetEmptyState } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';

import { CompaniesTable } from './CompaniesTable';
import { ManufactureCard } from './ManufactureCard';
import { SalesCard } from './SalesCard';

import styles from './styles.module.scss';

interface Props {
  data?: DashboardParams;
}

enum CardViews {
  Slider = 'slider',
  List = 'list'
}

export const ManufactureProfile: FC<Props> = ({ data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [cardView, setCardView] = useState(CardViews.List);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const maxSteps = (data?.companies?.length || 0) + 1;
  const isLastStep = activeStep === maxSteps - 1;

  const toggleCardView = (checked: boolean) => {
    setCardView(checked ? CardViews.List : CardViews.Slider);
  };

  return (
    <div className={styles.container}>
      <div className={styles.switch}>
        <IconSwitch
          onChange={toggleCardView}
          checkedIcon={<ViewSliderIcon className={styles.icon} />}
          uncheckedIcon={<ViewListIcon className={styles.icon} />}
          checked={cardView === CardViews.List}
        />
      </div>
      {!!data?.companies?.length && cardView === CardViews.Slider ? (
        <>
          <div
            className={cn(styles.background, isLastStep && styles.visible)}
          />
          <SwipeableViews
            axis="x"
            index={activeStep}
            containerStyle={{
              height: '100%',
              alignItems: isLastStep ? 'center' : 'flex-start'
            }}
            className={styles['card-wrapper']}
            onChangeIndex={handleStepChange}
            slideStyle={{
              padding: 0,
              overflowY: 'hidden',
              boxSizing: 'border-box',
              height: '100%'
            }}
          >
            {(data.companies || []).map((company, index) => (
              <div
                className="full-height"
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}-${company.location}`}
              >
                {Math.abs(activeStep - index) <= 2 ? (
                  <ManufactureCard data={company} />
                ) : null}
              </div>
            ))}

            <SalesCard />
          </SwipeableViews>
          <div className={styles['stepper-container']}>
            <MuiMobileStepper
              classes={{
                root: styles.stepper,
                dot: styles.dot,
                dotActive: styles['active-dot']
              }}
              steps={maxSteps}
              position="static"
              nextButton={null}
              backButton={null}
              activeStep={activeStep}
            />
            <div>
              <IconButton
                size="small"
                color="secondary"
                className={styles.button}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={handleNext}
                className={styles.button}
                disabled={activeStep === maxSteps - 1}
              >
                <KeyboardArrowRight />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <CompaniesTable data={data} />
      )}
      {!data?.companies?.length && cardView === CardViews.Slider && (
        <WidgetEmptyState />
      )}
    </div>
  );
};
