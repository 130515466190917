import { useQuery } from '@tanstack/react-query';
import { fetchMessagesByChatId } from 'actions/chatsActions';
import { Message } from 'entities/Message.entity';
import { queryKeys } from 'enums/QueryKeys.enum';

export const useMessages = (
  chatId: string | undefined,
  isMessageSending: boolean
) => {
  return useQuery<Message[]>({
    enabled: !!chatId && !isMessageSending,
    queryKey: queryKeys.chatMessages(chatId || ''),
    queryFn: () => fetchMessagesByChatId(chatId || ''),
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: Infinity
  });
};
