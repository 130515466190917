import { FC, useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { UpdateUserInfoData } from 'actions/userActions';
import { Tooltip } from 'components';
import { useLanguage } from 'hooks';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';
import { validateEmail, validateRequired } from 'utils/helpers/validators';

import { ConfirmEmailChangeModal } from './ConfirmEmailChangeModal';

import styles from './styles.module.scss';

interface Props {
  isGoogleUser?: boolean;
}

const AVAILABLE_LANGUAGES = [
  {
    value: 'en',
    title: 'English'
  },
  {
    value: 'de',
    title: 'German'
  }
];

export const AccountInfo: FC<Props> = ({ isGoogleUser }) => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const preferredLanguage = useLanguage();

  const { mutate: updateUserInfo, isPending: isUserInfoUpdating } =
    useUpdateUserInfo();

  const isPreferredLanguageAvailable = AVAILABLE_LANGUAGES.find(
    (item) => item.value === preferredLanguage
  );

  const [isConfirmEmailModalOpen, setConfirmEmailModalOpen] = useState(false);

  const openConfirmEmailModal = useCallback(
    () => setConfirmEmailModalOpen(true),
    []
  );
  const closeConfirmEmailModal = useCallback(
    () => setConfirmEmailModalOpen(false),
    []
  );

  const methods = useForm<UpdateUserInfoData>({
    defaultValues: {
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      email: userInfo?.email || '',
      preferredLanguage: isPreferredLanguageAvailable
        ? preferredLanguage
        : DEFAULT_PREFERRED_LANGUAGE
    }
  });

  const isFormEdited = methods.formState.isDirty;

  const submitFormData = () => {
    updateUserInfo(methods.getValues());

    methods.reset(methods.getValues());
  };

  const onSubmit = useCallback(
    (data: UpdateUserInfoData) => {
      if (userInfo?.email !== data.email) {
        openConfirmEmailModal();
        return;
      }

      submitFormData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUserInfo]
  );

  const submitEmailChange = () => {
    submitFormData();
    closeConfirmEmailModal();
  };

  const isAccountDeactivated = !!userInfo?.deactivatedAt;

  return (
    <>
      <ConfirmEmailChangeModal
        onSubmit={submitEmailChange}
        isOpen={isConfirmEmailModalOpen}
        onCancel={closeConfirmEmailModal}
        email={methods.getValues()?.email}
      />
      <FormProvider {...methods}>
        <form
          className={styles.container}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <h4 className={styles.heading}>
            {t('Page.Profile.Settings.PersonalInformation')}
          </h4>
          <div className={styles.label}>{t('Form.Label.FirstName')}</div>
          <Controller
            name="firstName"
            rules={{
              validate: (value: UpdateUserInfoData['firstName']) => {
                const trimmedFirstName = value?.trim();
                if (!validateRequired(trimmedFirstName)) {
                  return t('Form.Validation.FirstName.Required');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="firstName"
                size="medium"
                error={!!methods.formState.errors?.firstName}
                helperText={methods.formState.errors.firstName?.message}
                placeholder={t('Form.Placeholder.FirstName')}
                className={styles['input-container']}
                inputProps={{
                  className: styles.input
                }}
              />
            )}
          />
          <div className={styles.label}>{t('Form.Label.LastName')}</div>
          <Controller
            name="lastName"
            rules={{
              validate: (value: UpdateUserInfoData['lastName']) => {
                const trimmedLastName = value?.trim();
                if (!validateRequired(trimmedLastName)) {
                  return t('Form.Validation.LastName.Required');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="lastName"
                size="medium"
                error={!!methods.formState.errors.lastName}
                helperText={methods.formState.errors.lastName?.message}
                placeholder={t('Form.Placeholder.LastName')}
                className={styles['input-container']}
                inputProps={{
                  className: styles.input
                }}
              />
            )}
          />

          <h4 className={styles.heading}>
            {t('Page.Profile.Settings.AccountInformation')}
          </h4>
          <div className={styles.label}>{t('Form.Label.Email')}</div>
          <Controller
            name="email"
            rules={{
              validate: (value: UpdateUserInfoData['email']) => {
                const trimmedEmail = value?.trim() || '';
                if (!validateRequired(trimmedEmail)) {
                  return t('Form.Validation.Email.Required');
                }

                if (!validateEmail(trimmedEmail)) {
                  return t('Form.Validation.Email.NotValid');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="email"
                size="medium"
                disabled={isGoogleUser}
                error={!!methods?.formState?.errors.email}
                helperText={methods?.formState?.errors.email?.message}
                placeholder={t('Form.Placeholder.Email')}
                className={styles['input-container']}
                inputProps={{
                  className: styles.input
                }}
              />
            )}
          />

          <div className={styles.label}>
            {t('Form.Label.PreferredLanguage')}
          </div>
          <Controller
            name="preferredLanguage"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                size="medium"
                id="preferredLanguage"
                MenuProps={{
                  className: styles.menu,
                  transitionDuration: 0
                }}
                input={
                  <OutlinedInput
                    inputProps={{
                      className: styles.input
                    }}
                  />
                }
              >
                {AVAILABLE_LANGUAGES?.map(({ value, title }) => (
                  <MenuItem key={value} value={value}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Tooltip
            disabled={!isAccountDeactivated}
            title={t('Common.DeactivatedAccountNote')}
          >
            <div className={styles['button-container']}>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                className={styles.submit}
                loading={isUserInfoUpdating}
                disabled={!isFormEdited || isAccountDeactivated}
              >
                {t('Common.SaveChanges')}
              </LoadingButton>
            </div>
          </Tooltip>
        </form>
      </FormProvider>
    </>
  );
};
