import { FC } from 'react';
import { AppLink } from 'components';
import { PulsatingDot } from 'components/PulsatingDot';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { Notification } from 'entities/Notification.entity';
import { Themes } from 'enums';
import { useLanguage } from 'hooks';

import styles from './styles.module.scss';

type Props = Notification;

export const NotificationItem: FC<Props> = ({
  link,
  title,
  viewedAt,
  createdAt,
  linkTitle,
  description
}) => {
  const hasCta = link && linkTitle;
  const language = useLanguage();
  const locale = language === 'de' ? de : enUS;

  return (
    <div className={styles.container}>
      <div className={styles.meta}>
        <span className={styles.date}>
          {format(createdAt, 'LLL d, y, p', { locale })}
        </span>

        {!viewedAt && (
          <PulsatingDot
            className={styles.dot}
            theme={Themes.Success}
            size="small"
          />
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.body}>
          <h3 className={styles.title}>{title}</h3>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          />
        </div>

        {hasCta && (
          <AppLink className={styles.link} to={link}>
            {linkTitle}
          </AppLink>
        )}
      </div>
    </div>
  );
};
