import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { UserCoin } from 'entities/UserCoin.entity';
import { CommonApi } from 'services/API/Common/CommonApi';

export const fetchCoins = async (): Promise<UserCoin> => {
  try {
    const response = await CommonApi.fetchCoins();

    return UserCoin.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchBaseExternalDashboard = async (
  chatId: string,
  messageId: string
): Promise<DashboardData> => {
  try {
    const response = await CommonApi.fetchBaseExternalDashboard(
      chatId,
      messageId
    );

    return DashboardData.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchExternalDashboardById = async (
  dashboardId: string
): Promise<DashboardData> => {
  try {
    const response = await CommonApi.fetchExternalDashboardById(dashboardId);

    return DashboardData.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};
