import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as GieniLogo } from 'assets/logo-inverted.svg';
import { AuthLayout, Card, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useContactGieniUrl } from 'hooks';

import styles from './styles.module.scss';

export const ResetPasswordConfirm: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const contactGieniUrl = useContactGieniUrl();
  const appRedirectPath = searchParams.get('appRedirectPath');
  const question = searchParams.get('question');

  let redirectPath = appRedirectPath || Routes.Login;

  if (appRedirectPath && question) {
    redirectPath = `${appRedirectPath}?question=${question}`;
  }

  return (
    <AuthLayout>
      <Card className={styles.card}>
        <>
          <GieniLogo className={styles.logo} />
          <Title
            className={styles.title}
            title={t('Page.Auth.ResetPasswordConfirm.Title')}
          />
          <SubTitle className={styles.subtitle}>
            {t('Page.Auth.ResetPasswordConfirm.Subtitle')}
          </SubTitle>
          <Link to={redirectPath} className={styles['button-container']}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
            >
              {appRedirectPath
                ? t('Page.Auth.ResetPasswordConfirm.BackToChat')
                : t('Page.Auth.ResetPasswordConfirm.Login')}
            </Button>
          </Link>

          <span
            className={styles['support-info']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('Page.Auth.ResetPasswordConfirm.SupportInfo', {
                url: contactGieniUrl
              })
            }}
          />
        </>
      </Card>
    </AuthLayout>
  );
};
