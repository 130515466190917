import { FC } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import cn from 'classnames';
import { useMobile } from 'hooks';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeStep: number;
  onNext: () => void;
  onBack: () => void;
  processedImages: Array<string>;
  onStepChange: (activeStep: number) => void;
}

export const ImageModal: FC<Props> = ({
  isOpen,
  onClose,
  activeStep,
  onStepChange,
  onNext,
  onBack,
  processedImages
}) => {
  const isMobile = useMobile();

  const maxSteps = processedImages.length;

  return isOpen ? (
    <Dialog
      classes={{
        paper: cn(styles.modal)
      }}
      open={isOpen}
      onClose={onClose}
      className={cn(styles.content)}
    >
      <div className={styles['modal-content']}>
        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={onStepChange}
          slideClassName={styles.slide}
        >
          {processedImages.map((image, index) => (
            <img
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={image}
              alt="machine detail"
              className={styles['zoomed-image']}
            />
          ))}
        </SwipeableViews>
        {!isMobile && (
          <>
            <IconButton
              size="small"
              disableRipple
              onClick={onBack}
              className={styles.button}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              size="small"
              disableRipple
              onClick={onNext}
              className={styles.button}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </IconButton>
          </>
        )}
      </div>
    </Dialog>
  ) : null;
};
