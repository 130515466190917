// eslint-disable-next-line max-classes-per-file
import { custom, deserialize, list, primitive, serializable } from 'serializr';

export class TreemapItem {
  @serializable
  size: number = 0;

  @serializable
  value?: number = 0;

  @serializable
  name: string = '';

  @serializable
  code?: string = '';

  @serializable
  full_name?: string = '';

  @serializable
  model?: string = '';

  @serializable(list(primitive()))
  technologies?: string[] | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: any[]) => {
        return sourcePropertyValue;
      },
      (jsonValue: any[]) => {
        return jsonValue;
      }
    )
  )
  children?: any[] = [];

  static deserialize(json: Object | string): TreemapItem {
    return deserialize(TreemapItem, json);
  }
}
