import { LocalStorage } from 'services/LocalStorage';
import { SessionStorage } from 'services/SessionStorage';
import { CURRENT_USER_EMAIL_KEY, USER_AUTHENTICATED } from 'utils/constants';

export const saveUserEmailToStorage = (email: string): void => {
  LocalStorage.setItem(CURRENT_USER_EMAIL_KEY, email);
};

export const setIsUserAuthenticated = (isAuthenticated: boolean): void => {
  LocalStorage.setItem(USER_AUTHENTICATED, isAuthenticated);
};

export const clearStorage = (): void => {
  LocalStorage.reset();
  SessionStorage.reset();
};
