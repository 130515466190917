import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { changePassword, PasswordData } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useChangeUserPassword = () => {
  const { t } = useTranslation();

  const snackbar = useSnackbar();

  return useMutation<void, ApiError, PasswordData>({
    mutationFn: async (passwordData: PasswordData) => {
      await changePassword(passwordData);
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess() {
      snackbar.success.commonSuccess(t('Notifications.ChangePasswordSuccess'));
    }
  });
};
