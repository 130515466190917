import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { FeedbackTypes, MapTypes } from 'enums';
import { OtherMapData } from 'hooks/api/useFetchOtherMapData';
import { RegionsMapData } from 'hooks/api/useFetchRegionsMapData';
import {
  DashboardApi,
  NestedDashboardPayload
} from 'services/API/Dashboard/DashboardApi';
import { UserApi } from 'services/API/User/UserApi';

export const fetchNestedDashboards = async (
  dashboardId: string
): Promise<DashboardData[]> => {
  try {
    const response = await DashboardApi.fetchNestedDashboards(dashboardId);

    return response.data.map(DashboardData.deserialize).reverse();
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchDashboardById = async (
  dashboardId: string
): Promise<DashboardData> => {
  try {
    const response = await DashboardApi.fetchDashboardById(dashboardId);

    return DashboardData.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const rateDashboardWidget = async ({
  text,
  dashboardId,
  widgetId,
  feedbackType
}: {
  text?: string;
  dashboardId: string;
  feedbackType: FeedbackTypes;
  widgetId: DashboardComponent['id'];
}): Promise<void> => {
  try {
    UserApi.trackActivity();

    await DashboardApi.rateDashboardWidget({
      text,
      widgetId,
      dashboardId,
      feedbackType
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createNestedDashboard = async ({
  dashboardId,
  data
}: {
  dashboardId: string;
  data: Array<NestedDashboardPayload>;
}): Promise<{ dashboardId: string }> => {
  try {
    const response = await DashboardApi.createNestedDashboard(
      dashboardId,
      data
    );

    return response.data;
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateWidgetFilter = async ({
  dashboardId,
  widgetId,
  filters,
  mapType
}: {
  dashboardId: string;
  widgetId: DashboardComponent['id'];
  filters: string[];
  mapType?: MapTypes;
}): Promise<void> => {
  try {
    await DashboardApi.updateWidgetFilter({
      dashboardId,
      widgetId,
      filters,
      mapType
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateDashboardTitle = async ({
  dashboardId,
  title
}: {
  dashboardId: string;
  title: string;
}): Promise<void> => {
  try {
    await DashboardApi.updateDashboardTitle({
      dashboardId,
      title
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchOtherMapData = async (): Promise<OtherMapData> => {
  try {
    const response = await DashboardApi.fetchOtherMapData();

    return response.data;
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchRegionsMapData = async (): Promise<RegionsMapData> => {
  try {
    const response = await DashboardApi.fetchRegionsMapData();

    return response.data;
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};
