import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import styles from './Loader.module.scss';

interface Props {
  className?: string;
  loaderClassName?: string;
}

export const Loader: React.FC<Props> = ({ className, loaderClassName }) => (
  <div
    data-testid="loader"
    className={cn(styles['loader-container'], className)}
  >
    <CircularProgress className={cn(styles.loader, loaderClassName)} />
  </div>
);
