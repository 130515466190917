import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactComponent as ChevronIcon } from 'assets/ChevronIcon.svg';
import { MapTypes } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import { useUpdateWidgetFilter } from 'hooks/api';

import styles from './styles.module.scss';

interface Props {
  mapType: MapTypes;
  widgetId: string;
  groupFilter: string[];
  setMapType: Dispatch<SetStateAction<MapTypes>>;
}

export const MapTypeSelect: FC<Props> = ({
  mapType,
  widgetId,
  groupFilter,
  setMapType
}) => {
  const { t } = useTranslation();
  const { dashboardId } = useParams();
  const { mutate: updateWidgetsFiltersMutation } = useUpdateWidgetFilter({
    dashboardId: dashboardId || '',
    widgetId
  });
  const isExternalUserDashboard = useIsExternalUserDashboard();

  const MAP_TYPES = [
    {
      label: t('Page.Dashboard.Map.Maptype.Dot'),
      value: MapTypes.Dots
    },
    {
      label: t('Page.Dashboard.Map.Maptype.Heat'),
      value: MapTypes.Heat
    },
    {
      label: t('Page.Dashboard.Map.Maptype.Cluster'),
      value: MapTypes.Cluster
    }
  ];

  const handleMapTypeChange = (event: SelectChangeEvent<MapTypes>) => {
    setMapType(event.target.value as MapTypes);

    if (isExternalUserDashboard) {
      return;
    }

    updateWidgetsFiltersMutation({
      filters: groupFilter,
      mapType: event.target.value as MapTypes
    });
  };

  return (
    <div className={styles.container}>
      <Select
        size="small"
        value={mapType}
        IconComponent={ChevronIcon}
        className={styles['map-type-select']}
        onChange={handleMapTypeChange}
        MenuProps={{
          className: styles['map-type-select-menu'],
          transitionDuration: 0
        }}
      >
        {MAP_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
