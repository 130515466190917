import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Routes } from 'enums';
import { useMobile } from 'hooks';
import { useUserCoins, useUserInfo } from 'hooks/api';

import styles from './styles.module.scss';

export const DashboardsInfo = () => {
  const { t } = useTranslation();
  const { data: userCoins } = useUserCoins();
  const { data: userInfo } = useUserInfo();
  const previousCountValue = useRef<number | undefined>();
  const [isAnimating, setIsAnimating] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (
      userCoins?.count &&
      previousCountValue?.current &&
      userCoins?.count !== previousCountValue?.current
    ) {
      setIsAnimating(true);
    }

    previousCountValue.current = userCoins?.count;
  }, [userCoins?.count]);

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <div className={cn(styles.dashboards)} data-testid="coins-container">
      {userInfo?.isFreeUser && !userInfo?.deactivatedAt && (
        <>
          {!!userCoins?.count && (
            <span>
              {isMobile
                ? t('Common.DashboardsMobile')
                : t('Common.AvailableDashboards')}
            </span>
          )}

          {!userCoins?.count && userInfo?.isFreeUser && (
            <Link
              className={styles['recharge-dashboards']}
              to={Routes.SubscriptionPlans}
            >
              {t('Common.RechargeDashboards')}
            </Link>
          )}

          {!!userCoins?.count && (
            <div
              onAnimationEnd={handleAnimationEnd}
              className={cn(
                styles.counter,
                isAnimating && styles['counter-animating']
              )}
            >
              {userCoins?.count}
            </div>
          )}
        </>
      )}
    </div>
  );
};
