import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MapTypes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  mapType: MapTypes;
}

export const MapHeatScale: FC<Props> = ({ mapType }) => {
  const { t } = useTranslation();

  return mapType === MapTypes.Heat ? (
    <div className={styles.container}>
      <span className={styles['heatmap-description']}>
        {t('Page.Dashboard.Map.HeatmapLegend.HighDensity')}
      </span>
      <div className={styles['heatmap-scale']} />
      <span className={styles['heatmap-description']}>
        {t('Page.Dashboard.Map.HeatmapLegend.LowDensity')}
      </span>
    </div>
  ) : null;
};
