import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { updateEmailPreferences } from 'actions/preferencesActions';
import { ApiError } from 'entities/ApiError.entity';
import { EmailPreferences } from 'services/API/Preferences/PreferencesApi';

import { useSnackbar } from '../useSnackbar';

export const useUpdateEmailPreferences = (onSuccess: () => void) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, EmailPreferences>({
    mutationFn: (data) => updateEmailPreferences(data),
    onSuccess: () => {
      snackbar.success.commonSuccess(t('Page.Auth.Unsubscribe.ChangesSaved'));
      onSuccess();
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
