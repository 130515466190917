import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationToastProps } from 'components/NotificationsProvider/NotificationsProvider';
import { Routes } from 'enums';
import { NotificationTypes } from 'enums/NotificationsTypes.enum';
import { LocalStorage } from 'services/LocalStorage';
import { SHOW_NEW_DEVELOPMENTS_TOAST } from 'utils/constants';

import { useUserInfo } from './api';

type ReturnSettings = {
  toastData: NotificationToastProps | null;
  handleCloseNewDevelopmentsToast(): void;
};

export const useNewDevelopmentsToast = (): ReturnSettings => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const { data: userInfo } = useUserInfo();

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.showReleaseNotes) {
        const showNewDevelopmentsToast = LocalStorage.getItem(
          SHOW_NEW_DEVELOPMENTS_TOAST
        ) as boolean | null;

        if (showNewDevelopmentsToast === null) {
          LocalStorage.setItem(SHOW_NEW_DEVELOPMENTS_TOAST, true);
          setIsVisible(true);
        } else {
          setIsVisible(showNewDevelopmentsToast);
        }
      } else {
        LocalStorage.removeItem(SHOW_NEW_DEVELOPMENTS_TOAST);
        setIsVisible(false);
      }
    }
  }, [userInfo]);

  const handleCloseNewDevelopmentsToast = () => {
    setIsVisible(false);
    LocalStorage.setItem(SHOW_NEW_DEVELOPMENTS_TOAST, false);
  };

  return {
    toastData: isVisible
      ? {
          id: 'new-developments-toast',
          title: t('Common.NewDevelopmentsToast.Title'),
          link: Routes.NewDevelopments,
          description: t('Common.NewDevelopmentsToast.Subtitle'),
          type: NotificationTypes.NewDevelopments
        }
      : null,
    handleCloseNewDevelopmentsToast
  };
};
