import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { useUserInfo } from 'hooks/api/useUserInfo';

export const useHotjarAnalytics = () => {
  const { data: userInfo } = useUserInfo();
  const isNotDevelopmentEnv = process.env.NODE_ENV !== 'development';

  useEffect(() => {
    if (
      isNotDevelopmentEnv &&
      process.env.REACT_APP_HOTJAR_SITE_ID &&
      process.env.REACT_APP_HOTJAR_VERSION_ID
    ) {
      Hotjar.init(
        +process.env.REACT_APP_HOTJAR_SITE_ID as number,
        +process.env.REACT_APP_HOTJAR_VERSION_ID as number
      );
    }
  }, [isNotDevelopmentEnv]);

  useEffect(() => {
    if (userInfo && isNotDevelopmentEnv) {
      Hotjar.identify(userInfo.id, {
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        language: userInfo.preferredLanguage
      });
    }
  }, [userInfo, isNotDevelopmentEnv]);
};
