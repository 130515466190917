export const capitalizeFirstLetter = (text: string): string =>
  text
    .trim()
    .toLowerCase()
    .replace(/^\w/, (char) => char.toUpperCase());

export const generateTitleFromSlug = (slug: string): string => {
  let title = slug.replace(/[-_]/g, ' ');

  title = capitalizeFirstLetter(title.trim());

  return title;
};
