import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import styles from './styles.module.scss';

interface Props {
  title: string;
  createdAt: Date;
}

export const DashboardHeader: FC<Props> = ({ title, createdAt }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['heading-wrapper']}>
      <div className={styles.heading}>
        <p className={styles.title}>
          {t('Page.Dashboard.HeadingTitle', {
            question: title
          })}
        </p>
        <div className={styles.date}>
          <span className={styles.row}>
            {format(createdAt, 'EEEE, d MMM, yyyy')}
          </span>
          <span className={styles.row}>{format(createdAt, 'h:maaa')}</span>
        </div>
      </div>
    </div>
  );
};
