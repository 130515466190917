import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AuthLayout,
  Card,
  CompleteUserInfoForm,
  CompleteUserInfoFormData,
  SubTitle,
  Title
} from 'components';
import { Routes } from 'enums';
import { useLanguage } from 'hooks';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';

import styles from './styles.module.scss';

export const CompleteUserInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const { mutate: updateUserInfo, isPending: isUserInfoUpdating } =
    useUpdateUserInfo(false);
  const preferredLanguage = useLanguage();

  const methods = useForm<CompleteUserInfoFormData>({
    defaultValues: {
      lastName: userInfo?.lastName,
      company: ''
    }
  });

  const handleSubmit = useCallback(
    ({ lastName, company }: CompleteUserInfoFormData) => {
      const trimmedLastName = lastName.trim();
      const trimmedCompany = company.trim();

      updateUserInfo({
        lastName: trimmedLastName,
        company: trimmedCompany,
        preferredLanguage
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUserInfo, userInfo?.firstName]
  );

  useEffect(() => {
    if (userInfo?.hasCompleteInfo) {
      const redirectPath = searchParams.get('appRedirectPath');

      navigate(redirectPath || Routes.NewChat);
    }
  }, [navigate, userInfo, searchParams]);

  const subtitle = t(
    userInfo?.lastName
      ? 'Page.CompleteUserInfo.SubtitleShort'
      : 'Page.CompleteUserInfo.SubtitleFull',
    {
      firstName: userInfo?.firstName || ''
    }
  );

  return !isUserInfoLoading ? (
    <AuthLayout>
      <Card>
        <Title
          className={styles.title}
          title={t('Page.CompleteUserInfo.Title')}
        />
        <SubTitle className={styles.subtitle}>{subtitle}</SubTitle>
        <FormProvider {...methods}>
          <CompleteUserInfoForm
            onSubmit={handleSubmit}
            isLoading={isUserInfoUpdating}
            submitLabel={t('Page.CompleteUserInfo.Submit')}
          />
        </FormProvider>
      </Card>
    </AuthLayout>
  ) : null;
};
