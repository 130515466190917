import { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cn from 'classnames';
import { CommonTooltip } from 'components';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  className?: string;
  isExpanded?: boolean;
  setExpandedRowName?: (name: string) => void;
}

export const CompanyNameCell: FC<Props> = ({
  title = '',
  className,
  isExpanded,
  setExpandedRowName
}) => (
  <div className={cn(className, styles.container)}>
    <KeyboardArrowDownIcon
      onClick={(e) => {
        e.stopPropagation();
        if (setExpandedRowName) {
          setExpandedRowName(isExpanded ? '' : title);
        }
      }}
      className={cn(styles['arrow-icon'], isExpanded && styles.expanded)}
    />
    <CommonTooltip
      title={title || ''}
      className={cn(styles.title, 'overflowed-text')}
    />
  </div>
);
