import { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { useMobile } from 'hooks';

import { SelectedFilterItem } from '../../WidgetFilter';
import { renderZoomableIcicleChart } from './renderZoomableIcicleChart';

import './styles.scss';

interface Props {
  data: DashboardParamsData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
}

const width = 990;
const height = 1200;
export const ZoomableIcicle: FC<Props> = ({ data, selectItem }) => {
  const svgRef = useRef<SVGGElement>(null);
  const isMobile = useMobile();

  const modifiedData = {
    name: data.name,
    children: data.children
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    renderZoomableIcicleChart({
      width,
      height,
      isMobile,
      selectItem,
      data: modifiedData,
      svgElement: svg
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <svg
      // @ts-expect-error svg type
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0, 0, ${width}, ${height}`}
      style={{ height: 'auto', maxWidth: '100%', overflow: 'hidden' }}
    />
  );
};
