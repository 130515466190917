import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationCard } from 'components/NotificationCard';
import { Notification } from 'entities/Notification.entity';
import { queryKeys, Routes } from 'enums';
import { useNotifications } from 'hooks/api';
import { useNewDevelopmentsToast } from 'hooks/useNewDevelopmentsToast';
import { queryClient } from 'index';
import { DEFAULT_NOTIFICATIONS_FILTERS } from 'utils/constants';

import styles from './styles.module.scss';

const restrictedRoutes = [
  Routes.Auth,
  Routes.Login,
  Routes.Confirm,
  Routes.Checkout,
  Routes.Registration,
  Routes.CompleteUserInfo,
  Routes.NewDevelopments,
  Routes.SubscriptionConfirm,
  Routes.CoinsPaymentConfirm,
  Routes.OneTimePaymentCheckout,
  Routes.RestorePassword,
  Routes.ForgotPassword,
  Routes.Notifications
];

export type NotificationToastProps = Pick<
  Notification,
  'id' | 'title' | 'link' | 'description' | 'type' | 'isHidden'
>;

export const NotificationsProvider: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: notifications } = useNotifications(
    DEFAULT_NOTIFICATIONS_FILTERS
  );
  const { toastData, handleCloseNewDevelopmentsToast } =
    useNewDevelopmentsToast();

  const handleNotificationClick =
    ({ link }: NotificationToastProps) =>
    () => {
      navigate(link || Routes.Notifications);
    };

  const handleNotificationClose =
    ({ id, type }: NotificationToastProps) =>
    () => {
      switch (type) {
        case 'new-developments':
          handleCloseNewDevelopmentsToast();
          break;

        default:
          queryClient.setQueryData(
            queryKeys.notifications(DEFAULT_NOTIFICATIONS_FILTERS),
            (items: Notification[]) =>
              items?.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    isHidden: true
                  };
                }

                return item;
              })
          );
      }
    };

  const extendedNotifications = useMemo(() => {
    const notificationsToSet: NotificationToastProps[] = [
      ...(notifications || [])
    ];

    if (toastData) {
      notificationsToSet.unshift(toastData);
    }

    return notificationsToSet.filter((notification) => !notification.isHidden);
  }, [notifications, toastData]);

  const isNotificationsVisible =
    !restrictedRoutes.includes(location.pathname as Routes) &&
    extendedNotifications?.length;

  return isNotificationsVisible ? (
    <div className={styles.container}>
      {extendedNotifications?.map((notification) => (
        <NotificationCard
          key={notification.id}
          onClick={handleNotificationClick(notification)}
          onClose={handleNotificationClose(notification)}
          title={notification.title}
          body={notification.description}
          type={notification.type}
        />
      ))}
    </div>
  ) : null;
};
