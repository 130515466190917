import { useEffect } from 'react';
import { Notification } from 'entities/Notification.entity';
import { NotificationTypes } from 'enums/NotificationsTypes.enum';
import { useUpdateNotificationsStatus } from 'hooks/api';

export const useReadFetchedNotifications = (notifications?: Notification[]) => {
  const { mutate: updateNotificationsStatusMutation } =
    useUpdateNotificationsStatus();

  useEffect(() => {
    if (notifications?.length) {
      const notificationsIdsToUpdate = notifications
        .filter(
          ({ type, viewedAt }) =>
            !viewedAt && type !== NotificationTypes.UnpaidSubscription
        )
        .map(({ id }) => id);

      if (notificationsIdsToUpdate.length) {
        updateNotificationsStatusMutation(notificationsIdsToUpdate);
      }
    }
  }, [notifications, updateNotificationsStatusMutation]);
};
