import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardCompany } from 'entities/DashboardCompany.entity';

import { ImageModal } from '../../ImageModal';

import styles from './styles.module.scss';

interface Props {
  images: DashboardCompany['images'];
}

export const ImagesSection: FC<Props> = ({ images }) => {
  const { t } = useTranslation();
  const [zoomedInImage, setZoomedInImage] = useState<string>('');
  const [processedImages, setProcessedImages] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  const closeImageModal = () => setZoomedInImage('');
  const convertImageToBase64 = async (url: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => resolve(url);
        reader.readAsDataURL(blob);
      });
    } catch {
      return Promise.resolve(url);
    }
  };

  const fetchImages = async () => {
    const convertedImages = (await Promise.all(
      (images || []).map(convertImageToBase64)
    )) as string[];

    setProcessedImages(convertedImages);
  };

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onImageClick = (image: string, index: number) => {
    setZoomedInImage(image);
    setActiveStep(index);
  };

  return (
    <>
      <ImageModal
        isOpen={!!zoomedInImage}
        onClose={closeImageModal}
        activeStep={activeStep}
        onNext={handleNext}
        onBack={handleBack}
        onStepChange={handleStepChange}
        processedImages={processedImages}
      />
      <div className={styles['images-container']}>
        <div className={styles['image-title']}>
          {t('Page.Dashboard.ManufactureProfile.MachinePartsImages')}
        </div>
        <div className={styles.images}>
          {(processedImages || []).map((image, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${image}-${index}`}
              className={styles.image}
              onClick={() => onImageClick(image, index)}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
