import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { scheduleSubscription } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes, SubscriptionCycles } from 'enums';

import { useSnackbar } from '../useSnackbar';

export const useScheduleSubscription = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<void, ApiError, { period: SubscriptionCycles }>({
    mutationFn: ({ period }) => scheduleSubscription({ period }),
    onSuccess: () => {
      navigate(Routes.SubscriptionUpgradeConfirm);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
