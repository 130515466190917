import { FC, ReactNode, useMemo } from 'react';
import * as React from 'react';
import {
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as BaseSelect,
  type SelectChangeEvent
} from '@mui/material';

import styles from './styles.module.scss';

const inputProps = {
  'data-testid': 'grouped-select-input'
};

export interface GroupOption {
  title: string;
  values: {
    value: any;
    label: string;
  }[];
}

interface Props {
  label: string;
  value: any;
  options: GroupOption[];
  valueColor: string;
  anotherLabel?: string;
  onChange(e: SelectChangeEvent): void;
  handleDisableMenuItem?: (data: string) => boolean;
}

export const GroupedSelect: FC<Props> = ({
  label,
  value,
  options,
  valueColor,
  anotherLabel,
  onChange,
  handleDisableMenuItem
}) => {
  const mappedOptions = useMemo(() => {
    const result: ReactNode[] = [];

    options.forEach(({ title, values }) => {
      result.push(
        <ListSubheader key={title} data-testid="select-list-subheader">
          {title.replace(/__/g, ' ')}
        </ListSubheader>
      );

      values.forEach(({ label, value }, index) => {
        result.push(
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${title}__${value}__${index}`}
            disabled={
              label === anotherLabel || !!handleDisableMenuItem?.(title)
            }
            value={`${title}__${label}__${value}`}
            data-testid="select-menu-item"
          >
            {label}
          </MenuItem>
        );
      });
    });

    return result;
  }, [options, anotherLabel, handleDisableMenuItem]);

  return (
    <div className={styles.container}>
      <InputLabel data-testid="grouped-label" htmlFor="grouped-select">
        {label}
      </InputLabel>
      <BaseSelect
        label={label}
        value={value}
        data-testid="grouped-select"
        id={`grouped-select-${label}`}
        style={{ '--value-color': valueColor } as React.CSSProperties}
        onChange={onChange}
        inputProps={inputProps}
        MenuProps={{
          className: styles.menu,
          transitionDuration: 0
        }}
      >
        {mappedOptions}
      </BaseSelect>
    </div>
  );
};
