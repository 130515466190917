import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { Tooltip } from 'components';
import { SubscriptionStatuses } from 'enums';
import {
  useActiveSubscription,
  useCancelSubscription,
  useRenewSubscription,
  useUserInfo
} from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const RenewSubscription: FC = () => {
  const { t } = useTranslation();
  const { data: activeSubscription } = useActiveSubscription();
  const { data: userInfo } = useUserInfo();
  const { mutate: cancelSubscription } = useCancelSubscription();
  const { mutate: renewSubscription } = useRenewSubscription();
  const isSubscriptionActive =
    activeSubscription?.status === SubscriptionStatuses.Active;

  const handleToggleChange = () => {
    if (isSubscriptionActive) {
      cancelSubscription();
    } else {
      renewSubscription();
    }

    mixpanel?.track(MixpanelEvents.ToggleStatusChanged, {
      'User ID': userInfo?.id,
      Email: userInfo?.email,
      'Date/Time of Status Change': userInfo?.createdAt,
      'New Status': isSubscriptionActive ? 'Disabled' : 'Enabled'
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <span className={styles.title}>
          {t('Page.Profile.Billing.RenewSubscriptionTitle')}
        </span>
        <span className={styles.subtitle}>
          {t('Page.Profile.Billing.RenewSubscriptionSubtitle')}
        </span>
      </div>
      <div className={styles.actions}>
        <Tooltip
          title={t(
            'Page.Profile.Billing.AutoSubscriptionDeactivatedAccountTooltip'
          )}
          disabled={!userInfo?.deactivatedAt}
          className={styles.tooltip}
        >
          <span>
            <Switch
              onColor="#636677"
              offColor="#636677"
              offHandleColor="#444657"
              onHandleColor="#00D878"
              width={40}
              height={24}
              handleDiameter={16}
              activeBoxShadow="0"
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={handleToggleChange}
              disabled={!!userInfo?.deactivatedAt}
              checked={isSubscriptionActive && !userInfo?.deactivatedAt}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
