import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ViewNumberIcon } from 'assets/numbers.svg';
import { ReactComponent as ViewPercentageIcon } from 'assets/percent.svg';
import cn from 'classnames';
import {
  CommonTooltip,
  IconSwitch,
  Tooltip,
  WidgetEmptyState
} from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { Themes } from 'enums';
import millify from 'millify';

import { HorizontalBarChart } from './HorizontalBarChart';
import { VerticalBarChart } from './VerticalBarChart';

import styles from './styles.module.scss';

export const BarChartThemeColors = Object.freeze({
  [Themes.Success]: '#7DEAB2',
  [Themes.Warning]: '#FFCB7D'
});

interface Props {
  theme?: Themes.Success | Themes.Warning;
  data: DashboardParams;
}

const MAX_VISIBLE_BARS = 10;

export const barChartTickFormatter = (
  value: number | string,
  unit: string
): string => {
  const limit = 3;
  const total = +value;

  if (value.toString().length > limit) {
    return `${millify(total, {
      precision: 1,
      lowercase: true
    })}${unit}`;
  }

  return `${value.toString()}${unit}`;
};

enum BarChartViews {
  Percentage = 'percentage',
  Absolute = 'absolute'
}

export const BarChart: FC<Props> = ({ data, theme = Themes.Success }) => {
  const { t } = useTranslation();
  const [chartView, setChartView] = useState(BarChartViews.Percentage);
  const [isNumberTooltipOpen, setIsNumberTooltipOpen] = useState(false);
  const [isNumberOffTooltipOpen, setIsNumberOffTooltipOpen] = useState(false);
  const [isPercentageTooltipOpen, setIsPercentageTooltipOpen] = useState(false);
  const [isPercentageOffTooltipOpen, setIsPercentageOffTooltipOpen] =
    useState(false);

  const openNumberTooltip = () => setIsNumberTooltipOpen(true);
  const closeNumberTooltip = () => setIsNumberTooltipOpen(false);

  const openNumberOffTooltip = () => setIsNumberOffTooltipOpen(true);
  const closeNumberOffTooltip = () => setIsNumberOffTooltipOpen(false);

  const openPercentageTooltip = () => setIsPercentageTooltipOpen(true);
  const closePercentageTooltip = () => setIsPercentageTooltipOpen(false);

  const openPercentageOffTooltip = () => setIsPercentageOffTooltipOpen(true);
  const closePercentageOffTooltip = () => setIsPercentageOffTooltipOpen(false);

  const mappedData = data?.labels?.map((label, index) => {
    return {
      name: label,
      value:
        chartView === BarChartViews.Percentage
          ? data?.values?.[index]
          : data?.absolute_values?.[index]
    };
  });

  const toggleChartView = (checked: boolean) => {
    setChartView(checked ? BarChartViews.Absolute : BarChartViews.Percentage);
  };

  const unit = chartView === BarChartViews.Percentage ? '%' : '';

  return (
    <div className={styles.container}>
      <div className={styles.switch}>
        <IconSwitch
          onChange={toggleChartView}
          checkedHandleIcon={
            <Tooltip
              open={isNumberOffTooltipOpen}
              title={
                chartView === BarChartViews.Percentage
                  ? t('Page.Dashboard.BarChart.Percentage')
                  : t('Page.Dashboard.BarChart.Numbers')
              }
            >
              <ViewNumberIcon
                className={styles.icon}
                onMouseOver={openNumberOffTooltip}
                onMouseLeave={closeNumberOffTooltip}
              />
            </Tooltip>
          }
          uncheckedHandleIcon={
            <Tooltip
              open={isPercentageOffTooltipOpen}
              title={t('Page.Dashboard.BarChart.Percentage')}
            >
              <ViewPercentageIcon
                className={styles.icon}
                onMouseOver={openPercentageOffTooltip}
                onMouseLeave={closePercentageOffTooltip}
              />
            </Tooltip>
          }
          checkedIcon={
            <Tooltip
              open={isPercentageTooltipOpen}
              title={t('Page.Dashboard.BarChart.Percentage')}
            >
              <ViewPercentageIcon
                onMouseOver={openPercentageTooltip}
                onMouseLeave={closePercentageTooltip}
              />
            </Tooltip>
          }
          uncheckedIcon={
            <Tooltip
              open={isNumberTooltipOpen}
              title={t('Page.Dashboard.BarChart.Numbers')}
            >
              <ViewNumberIcon
                onMouseOver={openNumberTooltip}
                onMouseLeave={closeNumberTooltip}
              />
            </Tooltip>
          }
          checked={chartView === BarChartViews.Absolute}
        />
      </div>
      <CommonTooltip
        title={data?.title || t('Page.Dashboard.BarChart.Title')}
        className={cn(styles.title, 'overflowed-text-multiline')}
      />
      {mappedData?.length ? (
        <div className={styles['bar-chart-container']}>
          {mappedData.length > MAX_VISIBLE_BARS ? (
            <HorizontalBarChart data={mappedData} unit={unit} theme={theme} />
          ) : (
            <VerticalBarChart data={mappedData} unit={unit} theme={theme} />
          )}
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};
