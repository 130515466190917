import { useMutation } from '@tanstack/react-query';
import { cancelSubscription } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';

export const useCancelSubscription = () => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError>({
    mutationFn: () => cancelSubscription(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.activeSubscriptionDetails
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.subscriptions });
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
