import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { signUp, SignupPayload } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import mixpanel, { MixpanelEvents } from 'mixpanel';

export const useSignUp = (handleReset: () => void) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, SignupPayload>({
    mutationFn: async ({ userData, question, appRedirectPath }) => {
      await signUp({ userData, question, appRedirectPath });
    },
    onSuccess(
      _,
      {
        userData: { email, firstName, lastName, preferredLanguage },
        shouldRedirect = true
      }
    ) {
      mixpanel?.track(MixpanelEvents.Signup, {
        distinct_id: email,
        'First Name': firstName,
        'Last Name': lastName,
        'Preferred Language': preferredLanguage
      });

      snackbar.success.signupSuccess();

      if (shouldRedirect) navigate(Routes.Auth);
    },
    onError(error) {
      snackbar.error.commonError(error);
      handleReset();
    }
  });
};
