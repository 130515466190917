import { useMutation } from '@tanstack/react-query';
import { getSubscriptionSecret } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { SubscriptionCycles } from 'enums';

import { useSnackbar } from '../useSnackbar';

export const useClientSecret = (onSuccess: (secret: string) => void) => {
  const snackbar = useSnackbar();

  return useMutation<
    { clientSecret: string },
    ApiError,
    { period: SubscriptionCycles; page: string | null }
  >({
    mutationFn: ({ period, page }) => getSubscriptionSecret({ period, page }),
    onSuccess: async (data) => {
      await onSuccess(data.clientSecret);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
