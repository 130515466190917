import { useQuery } from '@tanstack/react-query';
import { fetchUserAvatar } from 'actions/userActions';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';

import { useUserInfo } from './useUserInfo';

export const useUserAvatar = () => {
  const { data: userInfo } = useUserInfo();
  const { isAuthenticated } = useAuthContext();

  return useQuery<Blob>({
    enabled: isAuthenticated && !!userInfo?.avatarFilename,
    queryKey: queryKeys.userAvatar,
    queryFn: fetchUserAvatar,
    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: 100
  });
};
