import { date, deserialize, list, object, serializable } from 'serializr';

import { DashboardComponent } from './DashboardComponent.entity';
import { DashboardFilter } from './DashboardFIlter.entity';
import { DashboardSequence } from './DashboardSequence.entity';

export class DashboardData {
  @serializable
  id: string = '';

  @serializable
  originDashboardId: string = '';

  @serializable(list(object(DashboardComponent)))
  widgets: DashboardComponent[] | null = null;

  @serializable(object(DashboardSequence))
  sequence = new DashboardSequence();

  @serializable(list(object(DashboardFilter)))
  filters?: DashboardFilter[] = [];

  @serializable
  langCode?: string = '';

  @serializable
  title?: string = '';

  @serializable
  userId?: string = '';

  @serializable
  revision?: number;

  @serializable
  chatId?: string = '';

  @serializable
  messageId?: string = '';

  @serializable
  hasDashboardData: boolean = false;

  @serializable
  hasNestedDashboards: boolean = false;

  @serializable
  isPaid?: boolean = false;

  @serializable(date())
  createdAt: Date = new Date();

  static deserialize(json: Object | string): DashboardData {
    return deserialize(DashboardData, json);
  }
}
