// @ts-expect-error type error
import { Library } from '@observablehq/stdlib';
import * as d3 from 'd3';

import { SelectedFilterItem } from '../../WidgetFilter';

import './styles.scss';

const library = new Library();

interface ZoomableSunburstData {
  name: string;
  full_name?: string;
  code?: string;
  value?: number;
  children?: Array<ZoomableSunburstData> | null;
}

export const renderZoomableTreemapChart = ({
  width,
  height,
  data,
  isMobile,
  selectItem,
  svgElement,
  chartColors
}: {
  width: number;
  height: number;
  isMobile: boolean;
  data: ZoomableSunburstData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
  chartColors: { start: string; end: string; root: string };
  svgElement: d3.Selection<SVGGElement | null, unknown, null, undefined>;
}) => {
  svgElement.selectAll('*').remove();

  // Create the scales.
  const x = d3.scaleLinear().rangeRound([0, width]);
  const y = d3.scaleLinear().rangeRound([0, height]);

  const color = d3.scaleOrdinal(
    d3.quantize(
      d3.interpolateRgbBasis([chartColors.start, chartColors.end]),
      (data.children?.length || 0) + 1
    )
  );

  const tile = (
    node: d3.HierarchyRectangularNode<ZoomableSunburstData>,
    x0: number,
    y0: number,
    x1: number,
    y1: number
  ) => {
    d3.treemapBinary(node, 0, 0, width, height);

    if (node.children) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of node.children) {
        child.x0 = x0 + (child.x0 / width) * (x1 - x0);
        child.x1 = x0 + (child.x1 / width) * (x1 - x0);
        child.y0 = y0 + (child.y0 / height) * (y1 - y0);
        child.y1 = y0 + (child.y1 / height) * (y1 - y0);
      }
    }
  };

  const hierarchy = d3
    .hierarchy<ZoomableSunburstData>(data)
    .sum((d) => d.value || 0)
    .sort((a, b) => (b.value || 0) - (a.value || 0));
  const root = d3.treemap<ZoomableSunburstData>().tile(tile)(hierarchy);

  const render = (
    group: d3.Selection<SVGGElement, unknown, null, undefined>,
    root: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) => {
    const node = group
      .selectAll('g')
      ?.data((root?.children || [])?.concat(root))
      ?.join('g');

    node
      .attr('cursor', 'pointer')
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .on('click', (event, d) => (d === root ? zoomout(root) : zoomin(d)));

    node.append('title').text((d) => `${d.data.full_name || d.data.name}`);

    node
      .append('rect')
      // @ts-expect-error type error
      // eslint-disable-next-line no-param-reassign,no-return-assign
      .attr('id', (d) => (d.leafUid = library.DOM.uid('leaf')).id)
      // eslint-disable-next-line no-nested-ternary
      .attr('fill', (d) => {
        if (!d.depth) return chartColors.root;
        // @ts-expect-error error type
        // eslint-disable-next-line no-param-reassign
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr('fill-opacity', (d) => {
        return d.depth <= 1 ? 0.6 : 0.4;
      })
      .attr('stroke', '#fff');

    node
      .append('clipPath')
      // @ts-expect-error type error
      // eslint-disable-next-line no-param-reassign,no-return-assign
      .attr('id', (d) => (d.clipUid = library.DOM.uid('clip')).id)
      .append('use')
      // @ts-expect-error type error
      .attr('xlink:href', (d) => d.leafUid.href);

    node
      .append('foreignObject')
      .attr('x', 3)
      .attr('y', 0)
      .attr('width', (d) => x(d.x1) - x(d.x0) - 3)
      .attr('height', (d) => y(d.y1) - y(d.y0) - 3)
      // @ts-expect-error type error
      .attr('clip-path', (d) => d.clipUid)
      .append('xhtml:div')
      .style('overflow', 'hidden')
      .style('white-space', 'normal')
      .style('text-overflow', 'ellipsis')
      .style('font-weight', (d) => (d === root ? 'bold' : null))
      .style('font-size', isMobile ? '22px' : '14px')
      .style('line-height', '1.5')
      .style('pointer-events', 'none')
      .html((d) => d.data.full_name || d.data.name);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    group.call(position, root);
  };

  // Display the root.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  let group = svgElement.append('g').call(render, root);

  function position(
    group: any,
    root: d3.HierarchyRectangularNode<ZoomableSunburstData>
  ) {
    group
      .selectAll('g')
      .attr(
        'transform',
        (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) =>
          d === root ? `translate(0, -100)` : `translate(${x(d.x0)},${y(d.y0)})`
      )
      .select('rect')
      .attr('width', (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) =>
        d === root ? width : x(d.x1) - x(d.x0)
      )
      .attr('height', (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) =>
        d === root ? 100 : y(d.y1) - y(d.y0)
      );

    group
      .selectAll('foreignObject')
      .attr('width', (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) =>
        d === root ? width - 3 : x(d.x1) - x(d.x0) - 3
      )
      .attr('height', (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) =>
        d === root ? 100 - 3 : y(d.y1) - y(d.y0) - 3
      );
  }

  // When zooming in, draw the new nodes on top, and fade them in.
  const zoomin = (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) => {
    if (d === root && d.depth === 0) {
      selectItem(null);
      return;
    }

    selectItem(
      d.depth === 0
        ? null
        : {
            name: d.data.name,
            code: d.data.code || ''
          }
    );

    if (!d.children) return;

    const group0 = group.attr('pointer-events', 'none');
    // eslint-disable-next-line no-multi-assign
    const group1 = (group = svgElement.append('g').call(render, d));

    x.domain([d.x0, d.x1]);
    y.domain([d.y0, d.y1]);

    svgElement
      .transition()
      .duration(500)
      // @ts-expect-error type error
      .call((t) => group0.transition(t).remove().call(position, d.parent))
      .call((t) =>
        // @ts-expect-error type error
        group1
          // @ts-expect-error type error
          .transition(t)
          .attrTween('opacity', () => d3.interpolate(0, 1))
          .call(position, d)
      );
  };

  // When zooming out, draw the old nodes on top, and fade them out.
  const zoomout = (d: d3.HierarchyRectangularNode<ZoomableSunburstData>) => {
    if (d === root && d.depth === 0) {
      selectItem(null);
      return;
    }
    const group0 = group.attr('pointer-events', 'none');
    // @ts-expect-error type error
    // eslint-disable-next-line no-multi-assign
    const group1 = (group = svgElement.insert('g', '*').call(render, d.parent));

    selectItem(
      d.depth === 0
        ? null
        : {
            name: d.data.name,
            code: d.data.code || ''
          }
    );

    if (d.parent) {
      x.domain([d.parent.x0, d.parent.x1]);
      y.domain([d.parent.y0, d.parent.y1]);
    }

    svgElement
      .transition()
      .duration(500)
      .call((t) =>
        // @ts-expect-error type error
        group0
          // @ts-expect-error type error
          .transition(t)
          .remove()
          .attrTween('opacity', () => d3.interpolate(1, 0))
          .call(position, d)
      )
      // @ts-expect-error type error
      .call((t) => group1.transition(t).call(position, d.parent));
  };
};
