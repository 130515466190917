import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Collapse } from '@mui/material';
import cn from 'classnames';
import { Machine } from 'entities/Machine.entity';

import styles from './styles.module.scss';

interface Props {
  data: Machine;
}

const mapRows = (data?: Record<string, any> | null) =>
  data
    ? Object.entries(data)
        .filter(([, value]) => value && typeof value !== 'object')
        .map(([key, value]) => ({
          label: key,
          value: value ? value.toString() : ''
        }))
    : [];

export const MachineRow: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const expandRow = useCallback(() => {
    setExpanded(true);
  }, []);

  const collapseRow = useCallback(() => setExpanded(false), []);

  const machineDetailsRows = mapRows(data.details);
  const machineFeaturesRows = mapRows(data.features);

  return (
    <div key={data.model} className={styles.row}>
      <div className={styles.content}>
        <div className={styles.cells}>
          <div className={styles.cell}>
            <span className={styles['cell-title']}>
              {t('Page.Dashboard.ManufactureProfile.Machines')}
            </span>
            <span className={cn(styles['cell-value'])}>
              {data.details?.Brand}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles['cell-title']}>
              {t('Page.Dashboard.ManufactureProfile.Type')}
            </span>
            <span className={cn(styles['cell-value'])}>
              {data.details?.Model}
            </span>
          </div>
          <div className={styles.cell}>
            <span className={styles['cell-title']}>
              {t('Page.Dashboard.ManufactureProfile.Amt')}
            </span>
            <span className={cn(styles['cell-value'])}>
              {data.details?.Quantity}
            </span>
          </div>
        </div>
        {expanded ? (
          <RemoveIcon className={styles.icon} onClick={collapseRow} />
        ) : (
          <AddIcon className={styles.icon} onClick={expandRow} />
        )}
      </div>
      <Collapse unmountOnExit in={expanded} className={styles.rows}>
        <>
          {!!machineDetailsRows?.length && (
            <div className={styles.section}>
              <p className={styles.title}>
                {t('Page.Dashboard.ManufactureProfile.MachineDetails')}
              </p>
              <div className={styles['expand-rows']}>
                {machineDetailsRows.map((row) => (
                  <div key={row.label} className={styles['expand-row']}>
                    <span className={styles.label}>{row.label}</span>
                    <span className={styles.value}>{row.value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!machineFeaturesRows?.length && (
            <div className={styles.section}>
              <p className={styles.title}>
                {t('Page.Dashboard.ManufactureProfile.Features')}
              </p>
              <div className={styles['expand-rows']}>
                {machineFeaturesRows.map((row) => (
                  <div key={row.label} className={styles['expand-row']}>
                    <span className={styles.label}>{row.label}</span>
                    <span className={styles.value}>{row.value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </Collapse>
    </div>
  );
};
