import { FC, PropsWithChildren, ReactElement } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  title: string;
  open?: boolean;
  disabled?: boolean;
  className?: string;
  placement?: TooltipProps['placement'];
  enterTouchDelay?: number;
}

export const Tooltip: FC<PropsWithChildren<Props>> = ({
  open,
  title,
  disabled,
  children,
  className,
  placement = 'bottom',
  enterTouchDelay = 0
}) => (
  <MuiTooltip
    open={open}
    title={title}
    placement={placement}
    disableHoverListener={disabled}
    disableTouchListener={disabled}
    disableFocusListener={disabled}
    enterTouchDelay={enterTouchDelay}
    classes={{ tooltip: cn(styles.tooltip, className && className) }}
  >
    {children as ReactElement}
  </MuiTooltip>
);
