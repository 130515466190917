import { useMemo } from 'react';
import { SubscriptionStatuses } from 'enums';

import { useSubscriptions } from './api';

export const useScheduledSubscription = () => {
  const { data: subscriptions } = useSubscriptions();

  const scheduledSubscription = useMemo(() => {
    if (!subscriptions?.length) {
      return null;
    }

    return subscriptions.find(
      ({ status }) => status === SubscriptionStatuses.Scheduled
    );
  }, [subscriptions]);

  return scheduledSubscription;
};
