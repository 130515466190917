import { FC } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  name: string;
  imageUrl?: string;
  className?: string;
}

export const Avatar: FC<Props> = ({ imageUrl, name, className }) =>
  imageUrl ? (
    <img
      alt={name}
      src={imageUrl}
      data-testid="avatar-img"
      className={cn(styles.avatar, className)}
    />
  ) : (
    <div className={cn(styles.placeholder, className)}>
      {name.charAt(0).toUpperCase()}
    </div>
  );
