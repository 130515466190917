const generateRandomName = () => {
  const names = [
    'LrAm sIoupmA',
    'Kmoynap iSmeplyT txet',
    'Cofhte',
    'Atnd YtspeetntigD',
    'Txte vEre',
    'Oypnmoa',
    'Iofeht rPntinig',
    'WhtA Spmcienee ookB',
    'Pmerou mApsiIu',
    'Pmoynapl Stieypmx',
    'Cfonhe tpRiitnng',
    'Asnyd teptyegtnsiD',
    'BlroemI pmSu',
    'Cmpnoay iylSmtpx txet',
    'Cfohten',
    'IpsuM',
    'AroLemIu smAp',
    'Coympna Stelypm txet',
    'Lvhte npRitning',
    'Bnd ttypesetingD',
    'LdfmrIp Amsup',
    'MmjSmsiplet xtTe',
    'Nofinitpgn',
    'WtsceimnBo hoSpkmee',
    'LreoumI Spmus',
    'Kopanymi ysTelpm txet',
    'Pfnoteh',
    'AndT ypesettgD',
    'LoremIupsma',
    'Comanpysl mTeiypxt',
    'Cyyothen pritginng',
    'IuspM',
    'ALremoum IpsumA',
    'Cuopppmnap iSmpylet txt',
    'Cftoehn pritninG',
    'AndyT epsetTgind',
    'LormIpsua',
    'Gimopnay iTsmeypl txte',
    'Cofnhte prtinign',
    'WshcoBeep mimkSne',
    'Remps',
    'Iiompynaiys',
    'Cftnohe pritning',
    'AnyTp ysettigDe',
    'Blmroeu Imsup',
    'Cyomapn Stelmpe txet',
    'Sonfteh Ripnitgn',
    'Arsnyd ytypesetD',
    'LmrIms Spau',
    'Cpoanmy Sitmplxe txet'
  ];
  return names[Math.floor(Math.random() * names.length)];
};

const generateRandomMeterials = (count = 1) => {
  const materials = [
    'Mrteilaa Ispmu',
    'Ko aMteri',
    'A fohte ripnting',
    'Adn tspettegnisy',
    'Txta lMraei',
    'Mrtelaila',
    'OKkfo mrtaliera prinntig',
    'Wtah Smpcieeno boKo',
    'WormI Amsup',
    'Oo pynoma etxiMralta',
    'SSnhte itnrpgin',
    'Amdn taelMreiD',
    'Blroem Ipusm',
    'Gm pyonas metlipT ytxetS',
    'Yroo pningtirt',
    'Ispmu',
    'AlremIp Amsui',
    'Ggmoynap etxMralita',
    'Silohne tpgrinitn',
    'Kdmn eltamriD',
    'Lmreom Ipsmu',
    'Qmynopa ertMaiaml text',
    'Mfonhte rtitgnip',
    'Wtah psbKcioomS',
    'LmrIe oupmAsm',
    'Cpmonya erxtMaiatl',
    'Fffoen hrintpgti',
    'ALdnm taelerMi',
    'Tmxriael rleiMa',
    'Aolnmoay itralmex ptexT',
    'Cfnotehinigngt',
    'MtrailIup',
    'Iipyonam ielTarxetM txet',
    'Rasohnet primtnigr',
    'Andm leitraiD',
    'EoremsA Ipmus',
    'Cooopamn mriTatex',
    'Koponeht printgnri',
    'Amdn lraietiDm',
    'Tmlaire Ipsmru',
    'LknpyoomM elrtiat etxt',
    'Cfonetn printrngi',
    'Amnd ietaMlrD',
    'LmrouI spmuA',
    'Cin pyon siMpalyrta etxt',
    'Wufo htreintpgni',
    'Andm tairlemD',
    'LrmIo msupA',
    'Ppnmoay temraila xtet'
  ];
  const randomNames = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * materials.length);
    randomNames.push(materials[randomIndex]);
  }
  return randomNames;
};

const generateRandomPhoneNumbers = (count = 1) => {
  const numbers = [
    '+1 (0)000 000 00000',
    '+44 (0)110 000 00048',
    '+33 (0)000 110 00220',
    '+61 (0)049 000 38000',
    '+91 (0)059 000 40001',
    '+34 (0)059 000 40301',
    '+81 (0)055 020 40008',
    '+7 (0)049 330 38000',
    '+55 (0)059 000 40003',
    '+66 (0)123 456 78900',
    '+27 (0)111 222 33344',
    '+39 (0)999 888 77766',
    '+46 (0)123 000 45678',
    '+20 (0)321 654 98765',
    '+31 (0)456 789 01234',
    '+64 (0)654 321 09876',
    '+32 (0)789 012 34567',
    '+36 (0)890 123 45678',
    '+1 (0)012 345 67890',
    '+47 (0)147 258 36900',
    '+82 (0)963 852 74100',
    '+64 (0)258 369 14700',
    '+65 (0)369 258 14700',
    '+60 (0)456 789 32100',
    '+62 (0)321 432 54321',
    '+351 (0)234 567 89012',
    '+370 (0)456 012 34567',
    '+1 (0)890 456 12345',
    '+213 (0)678 901 23456',
    '+92 (0)321 098 76543',
    '+48 (0)543 210 98765',
    '+49 (0)987 654 32100',
    '+977 (0)012 345 67890',
    '+420 (0)654 321 00011',
    '+381 (0)234 567 89000',
    '+385 (0)345 678 90123',
    '+86 (0)456 789 01234',
    '+386 (0)567 890 12345',
    '+359 (0)678 901 23456',
    '+370 (0)789 012 34567',
    '+374 (0)890 123 45678',
    '+30 (0)901 234 56789',
    '+43 (0)234 567 89101',
    '+45 (0)456 789 01234',
    '+385 (0)567 890 12345',
    '+43 (0)678 901 23456',
    '+374 (0)789 012 34567',
    '+370 (0)890 123 45678',
    '+420 (0)901 234 56789',
    '+44 (0)234 567 89012',
    '+61 (0)345 678 90123',
    '+55 (0)456 789 01234',
    '+32 (0)567 890 12345',
    '+91 (0)678 901 23456',
    '+27 (0)789 012 34567',
    '+34 (0)890 123 45678'
  ];
  const randomNumbers = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * numbers.length);
    randomNumbers.push(numbers[randomIndex]);
  }
  return randomNumbers;
};

const generateRandomNEmployee = () => {
  const min = 10;
  const max = 1999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateRandomLocation = () => {
  const locations = [
    'Cnpsoum',
    'Stymre Y',
    'Ctournr',
    'Axtrt',
    'Strdesetngnetae',
    'Cn moaet ImspuZ',
    'Bahtughro',
    'Sreetdwo',
    'Cntrovrmoeu',
    'Drtmsu',
    'Lrlecetircon',
    'Conrtuyx',
    'Tspeitsmu',
    'Srtete tuym Ym',
    'DmyT ems',
    'Ahatsmu',
    'Ptereet r',
    'Thnrstiy',
    'AswribteetC',
    'WT Bcdntoytwh',
    'Csupmnou',
    'SertymY',
    'Cturnoryt',
    'Fftr xte',
    'Steradsettin',
    'CmetnaI Zmuspi',
    'Brutahogh',
    'Lkreetdorw',
    'Curnvtoemvor',
    'JjutrmS',
    'Sternicortelc',
    'Cnturxy',
    'Tsetimspu',
    'Srtteey tumymvY',
    'DusmetYm',
    'Ahtamsu',
    'Steeretr',
    'Thsni ryt',
    'Arstebwteci',
    'WTCnybhrtwah',
    'Cnsupmo',
    'Smt ryeY',
    'Cnoturyrt',
    'Stxrte',
    'Stterndsaeig',
    'Coimtean IpsumZ',
    'Bhtu rogah',
    'fgrterdow',
    'Cnt rovmreuv',
    'SrtmsuY'
  ]; // Add more locations if needed
  return locations[Math.floor(Math.random() * locations.length)];
};

const generateRandomAddress = () => {
  const addresses = [
    'Cnruoty Lmero psiuim',
    'Sreett Lorme dyumm Y',
    'Cnytruod dmmuy tetx',
    'Strm dyutm tetx',
    'Streeet printngi adnt teypetsgnit',
    'Ctainognil Lmero Imsup Z',
    'Bnadn ggoni thuorgh',
    'Sreett ciste fo hte wdor',
    'Cuntryo Z lorem',
    'Stmr Cnuroty Lmer psioum',
    'Sreett lepa int oleretnic',
    'Cnyruot dmmuy etxt',
    'Tstet extc norytuo Lerm ipsmu',
    'Streete tste txt Lmoer dymmu Y',
    'Dummyu txteC onurtyZ',
    'A waht Cnryuto Lmeor psuim',
    'Streeet Lrmoe dyumm Y',
    'Tsih Cnuroty Z',
    'Asrteet wiihis norytuo CAbc',
    'TcdhB sih Counrty what',
    'Ctoryun Lmoer psiuum',
    'Steert Lorm dyumYm',
    'Countru dmumy etxt',
    'Strmuy dumyt etxT',
    'Sreet pritingn nda tseettginyp',
    'Contaignil Lorme pismuZ',
    'Bgnai tohurgnd oinG',
    'Sreteec stoci htew ordw',
    'Ctoryun Zmrlo',
    'Srmy CountruL Lorme ipsmu',
    'Sreet lepainr lctieonic',
    'Ctrouyn dmtmyetux',
    'Tets xttC noruytoLremp isum',
    'Sttr eetL ystet texL mrdeu mYm',
    'Dmtmyutx CrountyZm',
    'Awht Country Lromeumsi p',
    'Setreet Lmyure dyommu Y',
    'Tshr CountrzoZ',
    'Astertn wiishcs nuotyro CbA',
    'BTChsi doCountrwhat',
    'Coumrn Leriumpom',
    'Strteed LyoremdummY',
    'Cnmtyur Doommte xytext',
    'Stdermy Tumydt',
    'Sreete printda antgytypesin',
    'Contimna Lemer ImsopZu',
    'Bandni Tgoing rgouhhth',
    'Streret Cityset ooftherod',
    'Ctorumy ZeLromm',
    'StrunCoMyer Ioemrsmu'
  ];
  return addresses[Math.floor(Math.random() * addresses.length)];
};

// Function to generate a random website URL
const generateRandomWebsiteURL = () => {
  const domains = ['lorem.com', 'example.org', 'ipsum.net']; // Add more domains if needed
  return `www.${generateRandomName()
    .substring(0, 5)
    .toLowerCase()
    .replace(/\s+/g, '')}.${
    domains[Math.floor(Math.random() * domains.length)]
  }`;
};

export const mockedCompanies = [
  {
    hidden: true,
    company_name: 'Name example',
    location: 'Country example',
    materials: ['Test material', 'Whatamaterial'],
    address: 'Address details will be displayed here',
    website_url: 'www.example.example',
    phone_numbers: [
      '+38 (0)000 000 00000',
      '+19 (0)110 000 00048',
      '+99 (0)000 110 00220',
      '+28 (0)049 000 38000',
      '+49 (0)059 000 40001',
      '+96 (0)059 000 40301',
      '+46 (0)055 020 40008',
      '+49 (0)049 330 38000',
      '+63 (0)059 000 40003'
    ],
    contacts: [
      {
        email: 'example@www.example.de'
      },
      {
        email: 'example.ex@www.example.uk'
      },
      {
        email: 'testit@www.test.de'
      },
      {
        email: 'loremipsum.ex@www.example.de'
      }
    ],
    technologies: [
      'Ke hjjnology',
      'Whatatech',
      'Opst rnology',
      'Lorem tech',
      'Hinology ro',
      'Testit'
    ],
    certification: ['Example certification'],
    n_machines: 40,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'example brand',
        model: 'example model'
      }
    ]
  },
  {
    hidden: true,
    company_name: 'What Name example',
    location: 'wow Country example',
    materials: ['Material Ipsum', 'Test material'],
    address: 'Lorem details will be ipsum here',
    website_url: 'www.examplevwebrv.berb.example',
    phone_numbers: [
      '+43 (0)440 000 00000',
      '+96 (0)000 985 04500',
      '+73 (0)000 980 00000'
    ],
    contacts: [
      {
        email: 'example@www.example.de'
      },
      {
        email: 'examwerple@www.exvewample.de'
      }
    ],
    technologies: ['Hyu Qyuo', 'Oli hnjology'],
    certification: [
      'what Example certification',
      'lorem ipsum Example certification'
    ],
    n_machines: 59,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'lorem ipsum brand',
        model: 'example lorem ipsum model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(2),
    contacts: [
      {
        email: 'exweample@wvw.example.de'
      },
      {
        email: 'examwerplfqe@www.exvewample.de'
      }
    ],
    technologies: ['Afik hhh', 'Quii fj'],
    certification: [
      'Lorem whatification',
      'Dummy lorem ipsum Example certification'
    ],
    n_machines: 103,
    employee_count: 54,
    machines: [
      {
        brand: 'vqe3w brand',
        model: 'example Okj'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(6),
    emails: ['jkfwefxample@www.gmail.de'],
    contacts: [
      {
        email: 'jkfwefxample@www.gmail.de'
      }
    ],
    technologies: ['Hyy Hj', 'Okiii yu'],
    certification: ['Example certification'],
    n_machines: 90,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'n brand',
        model: 'Bbkjbample VER'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(1),
    contacts: [
      {
        email: 'shjxampfle@www.exvaerample.de'
      }
    ],
    technologies: ['Tedh uiooopp'],
    certification: ['Sllrtification'],
    n_machines: 407,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'exaeqvrvmple brand',
        model: 'EBhv model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'example@www.example.de'
      }
    ],
    phone_numbers: generateRandomPhoneNumbers(4),
    technologies: ['Esolkmd uyenkk'],
    certification: ['Example certification'],
    n_machines: 209,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'gqergqerg brand',
        model: 'QWbv model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(2),
    contacts: [
      {
        email: 'exr2ample@www.examrrrple.de'
      },
      {
        email: 'exrw2ample@www.exafmrrrple.com'
      }
    ],
    technologies: ['Tecjhllos 32', '32 fwExample technology'],
    certification: ['what dummy text'],
    n_machines: 68,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'qerfg brand',
        model: 'example qerv'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(6),
    contacts: [
      {
        email: '1jhexample@www.exampr23le.de'
      },
      {
        email: '1jhvple@www.xampr3le.de'
      }
    ],
    technologies: ['Dummy', 'fwe'],
    certification: ['fwqef certification'],
    n_machines: 54,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'vqewr brand',
        model: 'example qv'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'exvwqevample@www.example.de'
      },
      {
        email: 'fwbele@www.example.de'
      }
    ],
    phone_numbers: generateRandomPhoneNumbers(2),
    technologies: ['Qyiiop rtyue', 'Tiuouy jnlkjbn'],
    certification: ['Hbh certification', 'certification'],
    n_machines: 445,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'GJhv brand',
        model: 'vqwe model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(1),
    contacts: [
      {
        email: 'bkhjxample@www.em.de'
      }
    ],
    emails: ['bkhjxample@www.em.de'],
    technologies: ['TIppoie nlknl'],
    certification: ['Lorem'],
    n_machines: 4,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Lorem brand',
        model: 'Ipsum h model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(4),
    contacts: [
      {
        email: 'example@www.exghvple.de'
      },
      {
        email: 'fwbele@www.example.de'
      }
    ],
    technologies: ['Exlouiagloop'],
    certification: ['Exam34534e certification'],
    n_machines: 487,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'vewrv brand',
        model: 'TY nwr model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(5),
    contacts: [
      {
        email: 'ghvhexaple@www.mple.de'
      }
    ],
    technologies: ['Eh Ygyguy'],
    certification: ['ver H certification'],
    n_machines: 5,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Hbvf brand',
        model: 'lko pj'
      }
    ]
  },

  {
    hidden: true,
    company_name: 'What Name example',
    location: 'wow Country example',
    materials: ['Material Ipsum', 'Test material'],
    address: 'Lorem details will be ipsum here',
    website_url: 'www.examplevwebrv.berb.example',
    phone_numbers: [
      '+49 (0)440 000 00000',
      '+49 (0)000 985 04500',
      '+49 (0)000 980 00000'
    ],
    contacts: [
      {
        email: 'example@www.example.de'
      },
      {
        email: 'examwerple@www.exvewample.de'
      }
    ],
    technologies: ['Tjhhk hnljkbn', 'lorem nbkjbf nkjb'],
    certification: ['Reli serct dn', 'lorem ipsum Example certification'],
    n_machines: 267,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Koril doiay',
        model: 'Goiksa'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(2),
    contacts: [
      {
        email: 'dole@wqle.de'
      },
      {
        email: 'lfqe@wwwmple.de'
      }
    ],
    technologies: ['Dd bgkjhvkh', 'Joifkjb'],
    certification: ['Goi bkjbn', 'Cert bkjbfff'],
    n_machines: 218,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'vqe3w brand',
        model: 'example Okj'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(6),
    emails: ['hhmple@www.gmail.de'],
    contacts: [
      {
        email: 'ehhle@www.gmail.de'
      }
    ],
    technologies: ['teshklo', 'Upiiir'],
    certification: ['Quiol yoir'],
    n_machines: 90,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Branis hereeee',
        model: 'SAo koli'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(1),
    contacts: [
      {
        email: 'shle@bkjhbample.de'
      }
    ],
    technologies: ['What is it'],
    certification: ['Lbkjb v'],
    n_machines: 122,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Loki jyhiu',
        model: 'Roliaui'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'quy.y@humple.de'
      }
    ],
    phone_numbers: generateRandomPhoneNumbers(4),
    technologies: ['Lokidef lop'],
    certification: ['Aqivpvp'],
    n_machines: 45,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'gqergqerg brand',
        model: 'QWbv model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(2),
    contacts: [
      {
        email: 'ep;@vnevmrrrple.com'
      },
      {
        email: 'exrw2ample@www.exafmrrrple.com'
      }
    ],
    technologies: ['Kolikger', '32 nhljknb nlk'],
    certification: ['Tyloo'],
    n_machines: 981,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Blo hld',
        model: 'eLoli ke'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(6),
    contacts: [
      {
        email: 'Olple@wfg23le.de'
      },
      {
        email: 'loli@www.xampr3le.com'
      }
    ],
    technologies: ['Gooolis', 'Techingol'],
    certification: ['Lhkj vew'],
    n_machines: 987,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Doli pop',
        model: 'Wehyrbkjb'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'rrgjh.mple@kle.de'
      },
      {
        email: 'fwbegvqervle@w.example.uk'
      }
    ],
    phone_numbers: generateRandomPhoneNumbers(2),
    technologies: ['Tech is here', 'Retiing'],
    certification: ['HCert akk', 'ppUi'],
    n_machines: 49,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Kio',
        model: 'SH54 r'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(1),
    contacts: [
      {
        email: 'bke@wfwefww.em.de'
      }
    ],
    emails: ['bkhjxample@www.em.de'],
    technologies: ['UilkT jioy'],
    certification: ['TPol eny'],
    n_machines: 873,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Lok in',
        model: 'Mod45'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(4),
    contacts: [
      {
        email: 'hjoihle@www.exghvple.de'
      },
      {
        email: 'gerge@ww.de'
      }
    ],
    technologies: ['EJok tteou'],
    certification: ['Eoo vervvv'],
    n_machines: 87,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'vHuk l',
        model: 'TIo ljd jiii'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: generateRandomPhoneNumbers(5),
    contacts: [
      {
        email: 'ggermle@www.mple.de'
      }
    ],
    technologies: ['Kio lojvx', 'Wotlple techgy'],
    certification: ['Ukm veration'],
    n_machines: 98,
    employee_count: generateRandomNEmployee(),
    machines: [
      {
        brand: 'Lorand',
        model: 'ltiolj jjf'
      }
    ]
  }
];
