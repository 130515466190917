import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { regenerateChatMessage } from 'actions/chatsActions';
import { fetchCoins } from 'actions/commonActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes, queryKeys } from 'enums';
import { queryClient } from 'index';

import { useAnswerFromStream } from './useAnswerFromStream';

export const useRegenerateChatMessage = ({
  abortController
}: {
  abortController: MutableRefObject<AbortController | null>;
}) => {
  const { t } = useTranslation();
  const { setAnswerStatementFromStream } = useAnswerFromStream();

  const { mutate, isPending: isMessageSending } = useMutation<
    {
      id: string;
      messageId: string;
      statement: string;
      dashboardId: string;
    } | void,
    ApiError,
    { messageId: string; currentChatId: string }
  >({
    mutationFn: ({ messageId, currentChatId }) => {
      if (abortController?.current) {
        abortController?.current?.abort();
      }

      // eslint-disable-next-line no-param-reassign
      abortController.current = new AbortController();

      return regenerateChatMessage(
        currentChatId!,
        messageId,
        abortController,
        setAnswerStatementFromStream(currentChatId)
      );
    },
    async onSuccess(successData, { currentChatId }) {
      if (!successData) return;

      await queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      await queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: false })
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: true })
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(successData.dashboardId || '')
      });

      queryClient.setQueryData(
        queryKeys.chatMessages(currentChatId || ''),
        (previousMessages: Message[]) =>
          (previousMessages || []).map((previousMessage) => {
            if (previousMessage.id === successData.id) {
              return {
                ...previousMessage,
                ...successData,
                isNotFull: false
              };
            }

            return previousMessage;
          })
      );
    },
    onError(error, { currentChatId }) {
      const setMessageData = setAnswerStatementFromStream(currentChatId);
      const id = `error-${Math.random()}`;

      if (error?.message === 'User is inactive') {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.DeactivatedAccountErrorDefaultAnswer'),
          type: MessageTypes.answer
        });
      } else {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.ErrorDefaultAnswer'),
          type: MessageTypes.answer
        });
      }
    }
  });

  return {
    mutate,
    isPending: isMessageSending,
    isRegenerateMessageRequestPending: isMessageSending
  };
};
