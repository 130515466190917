import { useMutation } from '@tanstack/react-query';
import { logout } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { useAuthContext } from 'hooks/useAuthContext';
import { useSnackbar } from 'hooks/useSnackbar';

export const useLogout = () => {
  const snackbar = useSnackbar();
  const { logOut } = useAuthContext();

  return useMutation<void, ApiError>({
    mutationFn: async () => {
      await logout();
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess() {
      logOut();
    }
  });
};
