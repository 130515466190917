export enum InsightsType {
  Histogram = 'histogram',
  Chat = 'chat',
  Blob = 'blob',
  Treemap = 'treemap',
  TreemapV2 = 'treemap2',
  BarChart = 'bar_chart',
  ContactUs = 'contact_us',
  DashboardHistory = 'dashboard_history',
  HierarchyIndustries = 'hierarchy_industries',
  BigNumber = 'big_number',
  MapDots = 'map_dots',
  PieChart = 'pie_chart',
  HierarchyProducts = 'hierarchy_products',
  CRMBoosterAd = 'crm_booster',
  ManufacturerProfiles = 'manufacturer_profiles',
  ReportAd = 'report',
  RequestDemoCall = 'request_demo_call',
  TextSummary = 'text_summary',
  TextPies = 'text_pies',
  TextTrees = 'text_trees',
  TextProducts = 'text_products',
  TopModels = 'top_models'
}

export const InsightsTypeToReadable = Object.freeze({
  [InsightsType.Chat]: 'Chat History',
  [InsightsType.Blob]: 'Particle Cloud',
  [InsightsType.MapDots]: 'Map',
  [InsightsType.Treemap]: 'Treemap',
  [InsightsType.TreemapV2]: 'Treemap V2',
  [InsightsType.PieChart]: 'Pie Chart',
  [InsightsType.BarChart]: 'Bar Chart',
  [InsightsType.BigNumber]: 'Big Number',
  [InsightsType.Histogram]: 'Histogram',
  [InsightsType.HierarchyProducts]: 'Hierarchy Products',
  [InsightsType.HierarchyIndustries]: 'Hierarchy Industries',
  [InsightsType.ManufacturerProfiles]: 'Manufacturer Profiles',
  [InsightsType.ContactUs]: 'Contact Us',
  [InsightsType.DashboardHistory]: 'Dashboard History',
  [InsightsType.CRMBoosterAd]: 'CRM Booster',
  [InsightsType.ReportAd]: 'Report Add',
  [InsightsType.RequestDemoCall]: 'Request demo call',
  [InsightsType.TextSummary]: 'Text Summary',
  [InsightsType.TextPies]: 'Text Pies',
  [InsightsType.TextTrees]: 'Text Trees',
  [InsightsType.TextProducts]: 'Text Products',
  [InsightsType.TopModels]: 'Top Models'
});

export const InsightsTypesClasses = Object.freeze({
  [InsightsType.Chat]: 'large',
  [InsightsType.Blob]: 'medium',
  [InsightsType.MapDots]: 'medium',
  [InsightsType.Treemap]: 'medium',
  [InsightsType.TreemapV2]: 'medium',
  [InsightsType.PieChart]: 'small',
  [InsightsType.BarChart]: 'small',
  [InsightsType.Histogram]: 'large',
  [InsightsType.BigNumber]: 'xsmall',
  [InsightsType.ManufacturerProfiles]: 'large',
  [InsightsType.HierarchyProducts]: 'large',
  [InsightsType.HierarchyIndustries]: 'large',
  [InsightsType.DashboardHistory]: 'medium',
  [InsightsType.ContactUs]: 'medium',
  [InsightsType.CRMBoosterAd]: 'small',
  [InsightsType.ReportAd]: 'small',
  [InsightsType.TextSummary]: 'small',
  [InsightsType.TextPies]: 'small',
  [InsightsType.RequestDemoCall]: 'small',
  [InsightsType.TextTrees]: 'small',
  [InsightsType.TextProducts]: 'small',
  [InsightsType.TopModels]: 'large'
});

export const InsightsTypeLabels = Object.freeze({
  [InsightsType.Chat]: 'Page.Dashboard.WidgetLabels.Chat',
  [InsightsType.Blob]: 'Page.Dashboard.WidgetLabels.Blob',
  [InsightsType.MapDots]: 'Page.Dashboard.WidgetLabels.MapDots',
  [InsightsType.Treemap]: 'Page.Dashboard.WidgetLabels.Treemap',
  [InsightsType.TreemapV2]: 'Page.Dashboard.WidgetLabels.TreemapV2',
  [InsightsType.BarChart]: 'Page.Dashboard.WidgetLabels.BarChart',
  [InsightsType.Histogram]: 'Page.Dashboard.WidgetLabels.Histogram',
  [InsightsType.BigNumber]:
    'Page.Dashboard.WidgetLabels.BigNumberManufacturers',
  [InsightsType.PieChart]: 'Page.Dashboard.WidgetLabels.PieChartMachineBrands',
  [InsightsType.ManufacturerProfiles]:
    'Page.Dashboard.WidgetLabels.ManufacturerProfiles',
  [InsightsType.ContactUs]: 'Page.Dashboard.WidgetLabels.ContactUs',
  [InsightsType.HierarchyIndustries]:
    'Page.Dashboard.WidgetLabels.HierarchyIndustries',
  [InsightsType.HierarchyProducts]:
    'Page.Dashboard.WidgetLabels.HierarchyProducts',
  [InsightsType.CRMBoosterAd]: 'Page.Dashboard.WidgetLabels.CRMBooster',
  [InsightsType.ReportAd]: 'Page.Dashboard.WidgetLabels.Report',
  [InsightsType.TopModels]: 'Page.Dashboard.WidgetLabels.TopModels'
});
