import { ApiError } from 'entities/ApiError.entity';
import { Notification } from 'entities/Notification.entity';
import { NotificationsApi } from 'services/API/Notifications/NotificationsApi';

export interface NotificationsFilters {
  isViewed?: boolean;
  offset?: number;
  limit?: number;
}

export const fetchNotifications = async (
  filters?: NotificationsFilters
): Promise<Notification[]> => {
  try {
    const response = await NotificationsApi.fetchNotifications(filters);

    return response.data.map(Notification.deserialize);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateNotificationsStatus = async (
  ids: string[]
): Promise<void> => {
  try {
    await NotificationsApi.updateNotificationsStatus(ids);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};
