import { SubscriptionCycles, SubscriptionStatuses } from 'enums';
import { date, deserialize, serializable } from 'serializr';

export class Subscription {
  @serializable
  id: string = '';

  @serializable
  userId: string = '';

  @serializable
  period: SubscriptionCycles = SubscriptionCycles.Monthly;

  @serializable
  subscriptionId?: string | undefined = undefined;

  @serializable
  status?: SubscriptionStatuses = SubscriptionStatuses.Active;

  @serializable
  price?: number | undefined = 0;

  @serializable(date())
  expiresAt?: Date = new Date();

  @serializable
  customerId: string = '';

  @serializable(date())
  createdAt?: Date = new Date();

  static deserialize(json: Object | string): Subscription {
    return deserialize(Subscription, json);
  }
}
