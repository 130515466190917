import { useMutation } from '@tanstack/react-query';
import { updateDashboardTitle } from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useUpdateDashboardTitle = (
  dashboardId: string,
  onSuccess: (newTitle: string) => void
) => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, { title: string }>({
    mutationFn: ({ title }) => updateDashboardTitle({ dashboardId, title }),
    onSuccess: async (_data, { title }) => {
      await onSuccess(title);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
