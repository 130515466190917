import { EmailTypes } from 'enums';

import { ApiBase } from '../ApiBase';

export interface EmailPreferences {
  token: string;
  type: EmailTypes;
  isSubscribed: boolean;
}

class PreferencesApi extends ApiBase {
  updateEmailPreferences(data: EmailPreferences) {
    return this.client.put<void>('/email', data);
  }
}

const instance = new PreferencesApi('/preferences');

export { instance as PreferencesApi };
