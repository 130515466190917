import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Routes } from 'enums';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { useAuthContext } from 'hooks/useAuthContext';

export interface Props {
  redirectPath?: string;
}

export const WithCompleteUserInfoRoute: FC<Props> = ({
  redirectPath = Routes.CompleteUserInfo
}) => {
  const [searchParams] = useSearchParams();
  const { logOut } = useAuthContext();
  const { isError, isLoading, data } = useUserInfo();

  if (isLoading) return null;

  if (isError) logOut();

  let path = redirectPath;

  if (searchParams.size) {
    path = `${path}?${searchParams}`;
  }

  return !data?.hasCompleteInfo ? <Navigate replace to={path} /> : <Outlet />;
};
