import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const MoreDataSuccessModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleClassName={styles.title}
        title={t('Common.MoreDataModal.Confirm.Title')}
      >
        <p className={styles.subtitle}>
          {t('Common.MoreDataModal.Confirm.Subtitle')}
        </p>

        <div className={styles.actions}>
          <Button
            size="medium"
            color="secondary"
            onClick={onClose}
            variant="outlined"
          >
            {t('Common.Close')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
