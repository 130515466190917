import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, SubTitle } from 'components';
import { Routes } from 'enums';
import { useUnpaidSubscription } from 'hooks';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { LocalStorage } from 'services/LocalStorage';
import { SHOW_LOGIN_MODAL } from 'utils/constants';

import styles from './styles.module.scss';

export const FreeUsersInformationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const showLoginModal = LocalStorage.getItem(SHOW_LOGIN_MODAL);

  const removeModalStateFromLS = () =>
    LocalStorage.removeItem(SHOW_LOGIN_MODAL);

  const handleClose = () => {
    setIsOpen(false);
    removeModalStateFromLS();
  };

  const handleUpgradeButtonClick = () => {
    handleClose();
    navigate(Routes.SubscriptionPlans);
  };

  const { unpaidSubscription } = useUnpaidSubscription();

  useEffect(() => {
    if (userInfo) {
      const isModalVisible =
        userInfo.isFreeUser &&
        !userInfo.isFirstLogin &&
        userInfo.lastName &&
        !unpaidSubscription &&
        showLoginModal;

      if (isModalVisible) {
        setIsOpen(true);
      } else {
        handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, showLoginModal, unpaidSubscription]);

  return (
    <Modal
      title={t('Common.FreeUsersInformationModal.Title')}
      titleClassName={styles.title}
      isOpen={isOpen}
    >
      <SubTitle className={styles.subtitle}>
        {t('Common.FreeUsersInformationModal.Subtitle')}
      </SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          className={cn(styles.button, 'brilliance')}
          onClick={handleUpgradeButtonClick}
        >
          {t('Common.FreeUsersInformationModal.UpgradeButton')}
        </Button>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="outlined"
          className={cn(styles.button)}
          onClick={handleClose}
        >
          {t('Common.FreeUsersInformationModal.ContinueButton')}
        </Button>
      </div>
    </Modal>
  );
};
