import { createTheme } from '@mui/material/styles';

import styles from './styles.module.scss';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          boxShadow: 'none',
          borderRadius: 0,
          '&.Mui-disabled': {
            opacity: 0.35,
            backgroundColor: styles.PrimaryDisabled,
            color: styles.PrimaryText
          },
          '&:hover': {
            cursor: 'pointer',
            boxShadow: 'none',
            backgroundColor: styles.PrimaryHover
          }
        },
        outlined: {
          color: styles.PrimaryText,
          borderColor: styles.Primary,
          '&.Mui-disabled': {
            opacity: 0.35,
            color: styles.PrimaryText,
            borderColor: styles.Primary,
            backgroundColor: 'transparent'
          },
          '&:hover': {
            color: styles.PrimaryText,
            borderColor: styles.PrimaryHover,
            backgroundColor: 'transparent'
          }
        },
        contained: {
          backgroundColor: styles.Primary,
          color: styles.PrimaryText,
          '&.Mui-disabled': {
            opacity: 0.35,
            backgroundColor: styles.PrimaryHover,
            color: styles.PrimaryText
          },
          '&:hover': {
            backgroundColor: styles.PrimaryHover,
            color: styles.PrimaryText
          }
        },
        text: {
          color: styles.PrimaryText,
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            color: styles.PrimaryText,
            backgroundColor: 'transparent'
          },
          '&:hover': {
            color: styles.PrimaryText,
            backgroundColor: 'transparent',
            textDecoration: 'underline'
          }
        },
        textSecondary: {
          color: styles.Secondary,
          '&.Mui-disabled': {
            opacity: 0.35,
            backgroundColor: 'transparent',
            color: styles.Secondary
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: styles.Secondary
          }
        },
        containedSecondary: {
          backgroundColor: styles.Secondary,
          color: styles.SecondaryText,
          '&.Mui-disabled': {
            opacity: 0.35,
            backgroundColor: styles.SecondaryDisabled,
            color: styles.SecondaryText
          },
          '&:hover': {
            backgroundColor: styles.SecondaryHover,
            color: styles.SecondaryText
          }
        },
        outlinedSecondary: {
          color: styles.Secondary,
          borderColor: styles.Secondary,
          backgroundColor: 'transparent',
          '&:hover': {
            color: styles.SecondaryHover,
            borderColor: styles.SecondaryHover,
            backgroundColor: 'transparent'
          },
          '&.Mui-disabled': {
            opacity: 0.35,
            color: styles.Secondary,
            borderColor: styles.Secondary,
            backgroundColor: 'transparent'
          }
        },
        sizeLarge: {
          padding: '16px 32px',
          fontSize: '16px'
        },
        sizeMedium: {
          fontSize: '16px',
          padding: '12px 24px'
        },
        sizeSmall: {
          fontSize: '14px',
          padding: '12px 24px'
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 16,
          padding: '8px 7px 8px 0',
          color: styles.PrimaryText,
          backgroundColor: styles.White
        },
        deleteIcon: {
          color: styles.PrimaryText,
          '&:hover': {
            color: styles.Secondary
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: styles.LightGrey,
          color: styles.PrimaryText,

          '& fieldset': {
            borderColor: 'transparent'
          },
          '& ::placeholder': {
            color: '#afafaf',
            fontWeight: 400,
            fontSize: 16
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: styles.Error
          },
          '&.Mui-disabled:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
          },
          '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
          },
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: styles.Primary
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: styles.Primary,
            borderWidth: '1px'
          }
        },

        input: {
          fontSize: '16px',
          padding: '12px',
          '&::placeholder': {
            color: '#afafaf',
            fontWeight: 500
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: 'transparent',
            borderRadius: 0,
            '-webkit-text-fill-color': '#777a8c'
          }
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: styles.Error
          }
        }
      }
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '100%',
          verticalAlign: 'top'
        },
        head: {
          verticalAlign: 'bottom',
          height: '100%'
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #636677'
        }
      }
    }
  }
});
