import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import {
  fetchUserAvatar,
  fetchUserInfo,
  updateUserAvatar
} from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useUpdateUserAvatar = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, { file: FormData; onSuccess: () => void }>(
    {
      mutationFn: async ({ file }: { file: FormData }) => {
        await updateUserAvatar(file);
      },
      onSuccess(_data, { onSuccess }) {
        onSuccess();
        queryClient.invalidateQueries({
          queryKey: queryKeys.userInfo
        });
        queryClient.fetchQuery({
          queryKey: queryKeys.userInfo,
          queryFn: fetchUserInfo
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.userAvatar
        });
        queryClient.fetchQuery({
          queryKey: queryKeys.userAvatar,
          queryFn: fetchUserAvatar
        });

        snackbar.success.commonSuccess(t('Notifications.ChangesSaved'));
      },
      onError(error) {
        snackbar.error.commonError(error);
      }
    }
  );
};
