import { FC, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { DashboardParams } from 'entities/DashboardParams.entity';

import { HistogramRect } from './HistogramRect';

import './styles.scss';

interface Props {
  data: DashboardParams;
  width: number;
  height: number;
}

const MARGIN = { top: 20, right: 20, bottom: 20, left: 20 };

export interface Bucket {
  x0: number;
  x1: number;
  length: number;
}

export const Histogram: FC<Props> = ({ data, width, height }) => {
  const firstItem = data?.data ? data.data[0] : null;
  const lastItem = data?.data ? data.data[data.data.length - 1] : null;
  const step =
    lastItem?.bin_end && lastItem.bin_start
      ? lastItem.bin_end - lastItem.bin_start
      : 5;

  const mappedData = useMemo(() => {
    return (
      data?.data
        // eslint-disable-next-line camelcase
        ?.map(({ count, bin_start }) => {
          return Array(count).fill(bin_start);
        })
        .flat() || []
    );
  }, [data?.data]);

  const axesRef = useRef<SVGGElement>(null);
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const xScale = useMemo(() => {
    const max = Math.max(...mappedData) + step + 1;
    const min = Math.min(...mappedData) - 1;
    return d3.scaleLinear().domain([min, max]).range([10, boundsWidth]);
  }, [boundsWidth, mappedData, step]);

  // @ts-expect-error type error
  const buckets: Bucket[] = useMemo(() => {
    const bucketGenerator = d3
      .bin()
      .value((d) => d)
      .domain([firstItem?.bin_start || 0, lastItem?.bin_end || 1])
      .thresholds(
        d3.range(firstItem?.bin_start || 0, lastItem?.bin_end || 1, step)
      );
    return bucketGenerator(mappedData);
  }, [firstItem?.bin_start, lastItem?.bin_end, mappedData, step]);

  const yScale = useMemo(() => {
    const max = Math.max(...buckets.map((bucket) => bucket?.length));
    return d3.scaleLinear().range([boundsHeight, 0]).domain([0, max]).nice();
  }, [boundsHeight, buckets]);

  useEffect(() => {
    const svgElement = d3.select(axesRef.current).attr('class', 'text');

    svgElement.selectAll('*').remove();

    const xAxisGenerator = d3
      .axisBottom(xScale)
      .ticks(width / 45)
      .tickSizeOuter(0)
      .tickFormat(d3.format('d'));

    svgElement
      .append('g')
      .attr('transform', `translate(0,${boundsHeight})`)
      .call(xAxisGenerator);

    const yAxisGenerator = d3.axisLeft(yScale).ticks(height / 40);
    svgElement
      .append('g')
      .attr('transform', `translate(10,0)`)
      .call(yAxisGenerator);
  }, [
    xScale,
    yScale,
    boundsHeight,
    width,
    height,
    step,
    firstItem?.bin_start,
    lastItem?.bin_end
  ]);

  const allRects = buckets.map((bucket, i) => (
    <HistogramRect
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      xScale={xScale}
      yScale={yScale}
      bucket={bucket}
      boundsHeight={boundsHeight}
    />
  ));

  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth: '100%' }}
      viewBox={`0, 0, ${width}, ${height}`}
      className="histogram"
    >
      <g
        ref={axesRef}
        width={boundsWidth}
        height={boundsHeight}
        transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
      />
      <g
        width={boundsWidth}
        height={boundsHeight}
        id="histogram-rect-container"
        transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
      >
        {allRects}
      </g>
    </svg>
  );
};
