import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components';
import { TitleWithBackButton } from 'components/TitleWithBackButton';
import { useLanguage } from 'hooks';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';

import {
  NewDevelopmentsItem,
  type NewDevelopmentsItemData
} from './NewDevelopmentsItem';

import styles from './styles.module.scss';

export const NewDevelopments: FC = () => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [notesData, setNotesData] = useState<NewDevelopmentsItemData[] | null>(
    null
  );
  const language = useLanguage();

  useEffect(() => {
    if (userInfo) {
      switch (language) {
        case 'de':
          import('./data/de.json').then((response) =>
            setNotesData(response.default)
          );
          break;
        case 'en':
        default:
          import('./data/en.json').then((response) =>
            setNotesData(response.default)
          );
          break;
      }
    }
  }, [userInfo, language]);

  useEffect(() => {
    if (userInfo?.showReleaseNotes) {
      updateUserInfo({ showReleaseNotes: false });
    }
  }, [userInfo?.showReleaseNotes, updateUserInfo]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <TitleWithBackButton title={t('Page.NewDevelopments.Title')} />

          {(notesData as NewDevelopmentsItemData[])?.map((props, index) => (
            <NewDevelopmentsItem
              key={props.title}
              {...props}
              language={language}
              isLastItem={notesData ? index === notesData.length - 1 : false}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
