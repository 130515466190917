import { FC, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = Omit<NavLinkProps, 'to'> & {
  to: string;
  children: ReactNode;
};

export const AppLink: FC<Props> = ({ to, children, ...props }) => {
  if (to.indexOf('http://') === 0 || to.indexOf('https://') === 0) {
    return (
      <a
        href={to}
        data-testid="external-link"
        className={props.className as string}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink {...props} to={to}>
      {children}
    </NavLink>
  );
};
