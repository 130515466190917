import { useMutation } from '@tanstack/react-query';
import { requestProductsData } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useRequestProductsData = () => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    { payload: URLSearchParams; onSuccess: () => void }
  >({
    mutationFn: async ({ payload }: { payload: URLSearchParams }) => {
      await requestProductsData(payload);
    },
    onSuccess(_data, { onSuccess }) {
      onSuccess();
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
