import { FC, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ItemProps, TableComponents, TableVirtuoso } from 'react-virtuoso';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { CommonTooltip, Modal, WidgetEmptyState } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardCompany } from 'entities/DashboardCompany.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, queryKeys } from 'enums';
import {
  useContactGieniUrl,
  useExternalDashboardLink,
  useIsExternalUserDashboard
} from 'hooks';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { ManufactureCard } from '../ManufactureCard';
import { columns } from './columns';
import { mockedCompanies } from './mockedCompanies';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  data?: DashboardParams;
}

export type DashboardCompanyExtended = DashboardCompany & {
  hidden?: boolean;
};

const VirtuosoTableComponents: TableComponents<DashboardCompanyExtended> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
    />
  ),
  TableHead,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  ))
};

const renderTableRow = ({
  item,
  props
}: {
  item: DashboardCompanyExtended;
  props: ItemProps<DashboardCompanyExtended>;
}) => {
  if (item.hidden) {
    return <TableRow {...props} className={cn(styles.row, styles.hidden)} />;
  }

  return <TableRow {...props} className={styles.row} />;
};

const MAX_VISIBLE_MANUFACTURES = 10;

export const CompaniesTable: FC<Props> = ({ title, data }) => {
  const { dashboardId, messageId } = useParams();
  const contactGieniUrl = useContactGieniUrl();
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const ref = useRef<HTMLDivElement>(null);
  const [expandedRowName, setExpandedRowName] = useState<string>('');

  const [activeCompany, setActiveCompany] = useState<DashboardCompany | null>(
    null
  );

  const externalDashboardLink = useExternalDashboardLink();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const hasTreemap = dashboardData?.widgets?.some(
    (widget) => widget.type === InsightsType.Treemap
  );

  const tableColumns = useMemo(
    () =>
      hasTreemap
        ? columns
        : columns.filter((column) => column.dataKey !== 'machines'),
    [hasTreemap]
  );

  const visibleCompaniesAmount = data?.companies?.length || 0;

  const fullDataList: DashboardCompanyExtended[] = data?.max_companies
    ? [
        ...(data?.companies || []),
        ...new Array(
          data.max_companies - (data?.companies?.length || 0) >
          MAX_VISIBLE_MANUFACTURES
            ? MAX_VISIBLE_MANUFACTURES
            : data.max_companies
        )
          .fill(mockedCompanies[0])
          .map((item, index) =>
            !index
              ? item
              : mockedCompanies[
                  Math.floor(Math.random() * mockedCompanies.length)
                ]
          )
      ]
    : data?.companies || [];

  const closeDetailsModal = () => setActiveCompany(null);
  const openDetailsModal = (company: DashboardCompany) =>
    setActiveCompany(company);

  const onMoreDataClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      mixpanel?.track(MixpanelEvents.ContactGieniClick, {
        'Dashboard ID': dashboardId,
        // eslint-disable-next-line no-restricted-globals
        'Dashboard link': externalDashboardLink
      });

      e.stopPropagation();

      window.open(contactGieniUrl, '_blank');
    },
    [contactGieniUrl, dashboardId, externalDashboardLink]
  );

  const rowContent = useCallback(
    (index: number, row: DashboardCompanyExtended) => {
      const isOdd = index % 2 === 0;
      return (
        <>
          {tableColumns.map((column, columnIndex) => {
            const isFocused = expandedRowName === row.company_name;
            const isExpanded = index === visibleCompaniesAmount;
            // @ts-expect-error interface error
            const cellData = row[column.dataKey];

            const cell =
              column.renderCell && cellData
                ? column.renderCell({
                    data: cellData,
                    setExpandedRowName,
                    isExpanded: isFocused || isExpanded
                  })
                : cellData;

            const showOverlayInfo =
              columnIndex === 0 && index === visibleCompaniesAmount;

            if (showOverlayInfo) {
              return (
                <TableCell
                  align="left"
                  key={column.id}
                  onClick={() => openDetailsModal(row)}
                  className={cn(
                    styles['body-cell'],
                    isOdd && styles.odd,
                    isFocused && styles.expanded,
                    column.dataKey === 'company_name' && styles.sticky,
                    column.dataKey === 'company_name' && styles.scrolled,
                    row.hidden && styles.hidden
                  )}
                >
                  {cell || '-'}
                  <span
                    className={styles.overlay}
                    style={{ width: ref?.current?.clientWidth }}
                  >
                    <span className={styles.title}>
                      {t('Page.Dashboard.ManufactureProfile.MoreContacts')}
                    </span>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      className={styles.button}
                      onClick={onMoreDataClick}
                      disabled={isExternalUserDashboard}
                    >
                      {t('Common.ContactGieni')}
                    </Button>
                    {(data?.max_companies || 0) > visibleCompaniesAmount && (
                      <span className={styles.subtitle}>
                        {t(
                          'Page.Dashboard.ManufactureProfile.MaxManufacturesAmount',
                          {
                            maxAmount: data?.max_companies
                              ? data.max_companies
                              : 0,
                            amount: visibleCompaniesAmount
                          }
                        )}
                      </span>
                    )}
                  </span>
                </TableCell>
              );
            }

            return (
              <TableCell
                align="left"
                key={column.id}
                onClick={() => openDetailsModal(row)}
                className={cn(
                  styles['body-cell'],
                  isOdd && styles.odd,
                  isFocused && styles.expanded,
                  column.dataKey === 'company_name' && styles.sticky,
                  row.hidden && styles.hidden
                )}
              >
                {cell || '-'}
              </TableCell>
            );
          })}
        </>
      );
    },
    [
      t,
      tableColumns,
      expandedRowName,
      visibleCompaniesAmount,
      isExternalUserDashboard,
      onMoreDataClick,
      data?.max_companies
    ]
  );

  const fixedHeaderContent = () => (
    <TableRow>
      {tableColumns.map((column, index) => (
        <TableCell
          align="left"
          variant="head"
          key={column.id}
          style={{ width: column.width }}
          className={cn(
            styles['header-cell'],
            index === 0 && styles.sticky,
            index === 0 && styles.scrolled
          )}
        >
          {t(column.label)}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      <Modal
        paperClassName={styles.modal}
        isOpen={!!activeCompany?.company_name}
        onClose={closeDetailsModal}
      >
        {activeCompany && <ManufactureCard data={activeCompany} />}
      </Modal>
      <div className={styles.heading}>
        <CommonTooltip
          className={styles.title}
          title={
            title || t('Page.Dashboard.ManufactureProfile.ManufactureList')
          }
        />
      </div>
      {data?.companies?.length ? (
        <div className={styles['table-wrapper']} ref={ref}>
          <TableVirtuoso
            data={fullDataList}
            className={styles.table}
            itemContent={rowContent}
            components={{
              ...VirtuosoTableComponents,
              TableRow: ({ item, ...props }) =>
                renderTableRow({
                  item,
                  props: {
                    ...props,
                    item
                  }
                })
            }}
            fixedHeaderContent={fixedHeaderContent}
          />
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </>
  );
};
