import { useMutation } from '@tanstack/react-query';
import { rateChatMessage } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useRateChatMessage = (chatId: string, messageId: string) => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    { text?: string; feedbackType: FeedbackTypes }
  >({
    mutationFn: ({ text, feedbackType }) => {
      return rateChatMessage({
        text,
        feedbackType,
        chatId: chatId || '',
        messageId: messageId || ''
      });
    },
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess() {
      const prevMessages: Message[] | undefined = queryClient.getQueryData(
        queryKeys.chatMessages(chatId)
      );

      if (!prevMessages) return;

      const updatedMessages = prevMessages?.map((message) => {
        if (message.messageId === messageId) {
          return {
            ...message,
            hasFeedback: true
          };
        }
        return message;
      });

      if (updatedMessages?.length) {
        queryClient.setQueryData(
          queryKeys.chatMessages(chatId),
          updatedMessages
        );
      }
    }
  });
};
