import { FC } from 'react';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { shortifyNumber } from 'utils/numberUtils';

import styles from './styles.module.scss';

interface Props {
  data: DashboardParams;
  className?: string;
}

export const BigNumber: FC<Props> = ({ data, className }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.heading}>
      <span className={styles.title}>{shortifyNumber(data?.value || 0)}</span>
    </div>
    <CommonTooltip
      title={data?.title || '-'}
      className={cn(styles.subtitle, 'overflowed-text-multiline')}
    />
  </div>
);
