import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';
import { macos } from 'platform-detect';

import styles from './styles.module.scss';

interface ShortcutData {
  explanation: string;
  combination: string[];
}

const META_KEY = macos ? '⌘' : 'Ctrl';

export const GlobalShortcutsModal = () => {
  const { t } = useTranslation();
  const shortcutsContext = useGlobalShortcutsContext();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const toggleModal = () => setIsOpen((prevState) => !prevState);

  const SHORTCUTS: ShortcutData[] = [
    {
      explanation: t('Common.ShortcutsModal.OpenNewChat'),
      combination: [META_KEY, 'Shift', 'O']
    },
    {
      explanation: t('Common.ShortcutsModal.FocusChatInput'),
      combination: ['Shift', 'Esc']
    },
    {
      explanation: t('Common.ShortcutsModal.CopyLastAnswer'),
      combination: [META_KEY, 'Shift', 'C']
    },
    {
      explanation: t('Common.ShortcutsModal.ToggleSidebar'),
      combination: [META_KEY, 'Shift', 'S']
    },
    {
      explanation: t('Common.ShortcutsModal.ArchiveChat'),
      combination: [META_KEY, 'Shift', '⌫']
    },
    {
      explanation: t('Common.ShortcutsModal.ShowShortcuts'),
      combination: [META_KEY, '/']
    }
  ];

  useEffect(() => {
    shortcutsContext.setShortcutsModalVisibilityHandler(() => toggleModal);

    return () => shortcutsContext.setShortcutsModalVisibilityHandler(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderShortcut = ({ explanation, combination }: ShortcutData) => (
    <div key={explanation} className={styles.shortcut}>
      <span className={styles.explanation}>{explanation}</span>
      <span className={styles.combination}>
        {combination.map((item) => (
          <span key={item} className={styles.item}>
            {item}
          </span>
        ))}
      </span>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={styles['modal-wrapper']}
      title={t('Common.ShortcutsModal.Title')}
      titleClassName={styles.title}
    >
      <div className={styles.container}>{SHORTCUTS.map(renderShortcut)}</div>
    </Modal>
  );
};
