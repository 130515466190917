import { useQuery } from '@tanstack/react-query';
import { fetchOtherMapData } from 'actions/dashboardActions';
import { queryKeys } from 'enums';

export type OtherMapData = [number, number, number][];

export const useFetchOtherMapData = () =>
  useQuery<OtherMapData>({
    queryKey: queryKeys.dashboardOtherMapData,
    queryFn: () => fetchOtherMapData(),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
