import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { Machine } from 'entities/Machine.entity';

import { MachineRow } from './MachineRow';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Machine[];
}

export const MachineParkModal: FC<Props> = ({ isOpen, onClose, data }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      titleClassName={styles.title}
      paperClassName={styles.modal}
      title={t('Page.Dashboard.ManufactureProfile.MachinePark')}
    >
      <div className={styles.rows}>
        {data.map((row) => (
          <MachineRow data={row} key={row.model} />
        ))}
      </div>
    </Modal>
  );
};
