import { useMutation } from '@tanstack/react-query';
import { archiveChat } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useArchiveChat = (id: string, hasSuccessSnackbar?: boolean) => {
  const snackbar = useSnackbar();

  return useMutation<Chat, ApiError, { isArchived: boolean }>({
    mutationFn: ({ isArchived }) => archiveChat(id, { isArchived }),
    onSuccess(data) {
      const previousArchivedData: Chat[] =
        queryClient.getQueryData(
          queryKeys.filteredChats({ includeArchived: true })
        ) || [];
      const previousData: Chat[] =
        queryClient.getQueryData(
          queryKeys.filteredChats({ includeArchived: false })
        ) || [];

      queryClient.setQueryData(
        queryKeys.filteredChats({ includeArchived: true }),
        [...previousArchivedData.filter(({ id }) => data.id !== id), data]
      );
      queryClient.setQueryData(
        queryKeys.filteredChats({ includeArchived: false }),
        previousData.filter(({ id }) => data.id !== id)
      );

      if (hasSuccessSnackbar) {
        snackbar.success.chatArchivationSuccess();
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
