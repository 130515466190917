import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { validateEmail, validateRequired } from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type ForgotPasswordFormData = {
  email: string;
};

interface Props {
  isLoading: boolean;
  onSubmit: (data: ForgotPasswordFormData) => void;
}

export const ForgotPasswordForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors }
  } = useFormContext<ForgotPasswordFormData>();

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.label}>{t('Form.Label.Email')}</div>
      <Controller
        name="email"
        rules={{
          validate: (value: ForgotPasswordFormData['email']) => {
            const trimmedEmail = value.trim();
            if (!validateRequired(trimmedEmail)) {
              return t('Form.Validation.Email.Required');
            }

            if (!validateEmail(trimmedEmail)) {
              return t('Form.Validation.Email.NotValid');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="email"
            size="medium"
            placeholder={t('Form.Placeholder.Email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />
      <LoadingButton
        size="medium"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        className={styles.submit}
      >
        {t('Page.Auth.ForgotPassword.Submit')}
      </LoadingButton>
    </form>
  );
};
