import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { useContactGieniUrl, useLandingUrl } from 'hooks';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

export const CheckoutFooter: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const landingUrl = useLandingUrl();
  const contactGieniUrl = useContactGieniUrl();

  return (
    <footer className={cn(styles.footer, className)}>
      <div className={styles.technical}>
        <Link
          target="_blank"
          className={styles.link}
          to={`${landingUrl}/terms-and-conditions`}
        >
          {t('Common.TermsAndConditions')}
        </Link>
        <Link
          target="_blank"
          className={styles.link}
          to={`${landingUrl}/privacy-policy`}
        >
          {t('Common.PrivacyPolicy')}
        </Link>
      </div>
      <div className={styles.info}>
        <Button
          color="secondary"
          size="medium"
          variant="contained"
          className={styles['contact-button']}
          href={contactGieniUrl}
        >
          {t('Common.ContactGieni')}
        </Button>
        <span className={styles.year}>
          {t('Common.RightsReserved', {
            currentYear: new Date().getFullYear()
          })}
        </span>
      </div>
    </footer>
  );
};
