import { useInfiniteQuery } from '@tanstack/react-query';
import {
  fetchNotifications,
  NotificationsFilters
} from 'actions/notificationsActions';
import { Notification } from 'entities/Notification.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';

import { useUserInfo } from './useUserInfo';

const defaultNotificationsLimit = 5;
const defaultNotificationsOffset = 0;

export const usePaginatedNotifications = (filters?: NotificationsFilters) => {
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();

  return useInfiniteQuery<Notification[]>({
    enabled: isAuthenticated && !userInfo?.isFirstLogin,
    queryKey: queryKeys.paginatedNotifications,
    queryFn: ({ pageParam }) => {
      const offset = pageParam as number;
      const limit = filters?.limit || defaultNotificationsLimit;

      return fetchNotifications({
        ...filters,
        limit,
        offset
      });
    },
    initialPageParam: filters?.offset || defaultNotificationsOffset,
    getNextPageParam: (lastPage, pages) => {
      const limit = filters?.limit || defaultNotificationsLimit;
      const nextOffset = limit * pages.length;

      if (lastPage?.length < limit) return null;

      return nextOffset;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
};
