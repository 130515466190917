import { InsightsType } from 'enums';
import { deserialize, serializable } from 'serializr';

export class DashboardFilter {
  @serializable
  type: InsightsType = InsightsType.Treemap;

  @serializable
  value: string = '';

  @serializable
  name: string = '';

  @serializable
  typeName: string = '';

  static deserialize(json: Object | string): DashboardFilter {
    return deserialize(DashboardFilter, json);
  }
}
