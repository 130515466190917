import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { FeedbackTypes, InsightsType, MapTypes } from 'enums';
import { OtherMapData } from 'hooks/api/useFetchOtherMapData';
import { RegionsMapData } from 'hooks/api/useFetchRegionsMapData';

import { ApiBase } from '../ApiBase';

export interface NestedDashboardPayload {
  type: InsightsType;
  value: string;
  name: string;
  typeName: string;
}
class DashboardApi extends ApiBase {
  fetchNestedDashboards(dashboardId: string) {
    return this.client.get<DashboardData[]>(
      `/${dashboardId}/nested-dashboards`
    );
  }

  fetchDashboardById(dashboardId: string) {
    return this.client.get<DashboardData>(`/${dashboardId}`);
  }

  rateDashboardWidget({
    text,
    widgetId,
    dashboardId,
    feedbackType
  }: {
    text?: string;
    dashboardId: string;
    feedbackType: FeedbackTypes;
    widgetId: DashboardComponent['id'];
  }) {
    return this.client.post<void>(`/${dashboardId}/widgets/${widgetId}/rate`, {
      text,
      feedbackType
    });
  }

  createNestedDashboard(
    dashboardId: string,
    data: Array<NestedDashboardPayload>
  ) {
    return this.client.post<{ dashboardId: string }>(
      `/${dashboardId}/nested-dashboards`,
      data
    );
  }

  updateWidgetFilter({
    dashboardId,
    widgetId,
    filters,
    mapType
  }: {
    dashboardId: string;
    widgetId: DashboardComponent['id'];
    filters: string[];
    mapType?: MapTypes;
  }) {
    return this.client.patch<void>(
      `/${dashboardId}/widgets/${widgetId}/filters`,
      { filters, mapType }
    );
  }

  updateDashboardTitle({
    dashboardId,
    title
  }: {
    dashboardId: string;
    title: string;
  }) {
    return this.client.patch<void>(`/${dashboardId}`, { title });
  }

  fetchOtherMapData() {
    return this.client.get<OtherMapData>('/data/map-other-data.json', {
      baseURL: ''
    });
  }

  fetchRegionsMapData() {
    return this.client.get<RegionsMapData>('/data/map-regions-data.json', {
      baseURL: ''
    });
  }
}

const instance = new DashboardApi('/dashboards');

export { instance as DashboardApi };
