import { useMemo } from 'react';
import { SubscriptionStatuses } from 'enums';

import { useSubscriptions } from './api';

export const useUnpaidSubscription = () => {
  const { data: subscriptions, isLoading } = useSubscriptions();

  const unpaidSubscription = useMemo(() => {
    if (!subscriptions?.length) {
      return null;
    }

    return subscriptions.find(
      ({ status }) =>
        status === SubscriptionStatuses.PaymentFailed ||
        status === SubscriptionStatuses.PaymentActionRequired
    );
  }, [subscriptions]);

  return { unpaidSubscription, isLoading };
};
