import { FC, MouseEvent, useMemo } from 'react';
import { ReactComponent as BellIcon } from 'assets/BellIcon.svg';
import { ReactComponent as CrossIconOutlined } from 'assets/CrossIconOutlined.svg';
import { ReactComponent as ReleaseNotesIcon } from 'assets/ReleaseNotesIcon.svg';
import cn from 'classnames';
import { NotificationTypes } from 'enums/NotificationsTypes.enum';

import styles from './styles.module.scss';

interface Props {
  title: string;
  body: string;
  type: NotificationTypes;
  onClick(): void;
  onClose(): void;
}

export const NotificationCard: FC<Props> = ({
  type,
  body,
  title,
  onClick,
  onClose
}) => {
  const icon = useMemo(() => {
    switch (type) {
      case NotificationTypes.NewDevelopments:
        return <ReleaseNotesIcon />;
      default:
        return <BellIcon />;
    }
  }, [type]);

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();

    onClose();
  };

  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.icon}>{icon}</div>

      <div className={styles.content}>
        <div className={styles.heading}>
          <span className={cn(styles.title, 'overflowed-text')}>{title}</span>

          <CrossIconOutlined className={styles.close} onClick={handleClose} />
        </div>
        <span className={cn(styles.body, 'overflowed-text')}>{body}</span>
      </div>
    </div>
  );
};
