import React, { FC } from 'react';
import cn from 'classnames';
import { WidgetEmptyState } from 'components';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { MessageTypes } from 'enums';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data?: DashboardParams;
  isTextWidgetsProcessing?: boolean;
}

export const TextSummary: FC<Props> = ({
  data,
  className,
  isTextWidgetsProcessing
}) => (
  <div className={cn(styles.container, className)}>
    {data?.text || isTextWidgetsProcessing ? (
      <>
        <div className={styles.subtitle}>
          <ChatStatement
            maxLettersAmount={450}
            readMoreClassName={styles.statement}
            isTextWidgetsProcessing={isTextWidgetsProcessing}
            data={{ type: MessageTypes.answer, statement: data?.text }}
          />
        </div>
      </>
    ) : (
      <WidgetEmptyState />
    )}
  </div>
);
