import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CongratsIcon } from 'assets/congrats.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { Card, Footer, Loader, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useSubscriptionConfirmation } from 'hooks/api/useSubscriptionConfirmation';

import styles from './styles.module.scss';

export const SubscriptionConfirm: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sessionId = searchParams.get('session_id');

  if (!sessionId) navigate(Routes.SubscriptionPlans);

  const {
    isError,
    isPending,
    mutate: getSubscriptionConfirmation
  } = useSubscriptionConfirmation(sessionId || '');

  const hasError = !sessionId || isError;

  useEffect(() => {
    getSubscriptionConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    navigate(hasError ? Routes.SubscriptionPlans : Routes.NewChat);
  };

  const { icon, title, subtitle, buttonLabel } = hasError
    ? {
        title: 'Page.SubscriptionConfirm.ErrorTitle',
        subtitle: 'Page.SubscriptionConfirm.ErrorSubtitle',
        buttonLabel: 'Page.SubscriptionConfirm.BackToPlans',
        icon: <ErrorIcon />
      }
    : {
        title: 'Page.SubscriptionConfirm.SuccessTitle',
        subtitle: 'Page.SubscriptionConfirm.SuccessSubtitle',
        buttonLabel: 'Page.SubscriptionConfirm.StartJourney',
        icon: <CongratsIcon />
      };

  return (
    <>
      <div className={styles.container}>
        {isPending ? (
          <Loader loaderClassName={styles.loader} />
        ) : (
          <Card>
            {icon}
            <Title className={styles.title} title={t(title)} />
            <SubTitle className={styles.subtitle}>{t(subtitle)}</SubTitle>

            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={onClick}
            >
              {t(buttonLabel)}
            </Button>
          </Card>
        )}
      </div>
      <Footer />
    </>
  );
};
