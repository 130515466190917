import { useMutation } from '@tanstack/react-query';
import { fetchNestedDashboards } from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useNestedDashboards = (cb: (data: DashboardData[]) => void) => {
  const snackbar = useSnackbar();

  return useMutation<
    DashboardData[],
    ApiError,
    {
      dashboardId: string;
      originDashboardId?: string;
    }
  >({
    mutationFn: ({ dashboardId }) => fetchNestedDashboards(dashboardId),
    onSuccess(data, { dashboardId }) {
      cb(data);

      if (dashboardId) {
        queryClient.setQueryData(queryKeys.nestedDashboards(dashboardId), data);
      }
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
