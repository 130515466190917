import { FC, useState } from 'react';
import cn from 'classnames';
import Markdown from 'markdown-to-jsx';

import styles from './styles.module.scss';

interface Props {
  text: string;
  className?: string;
  maxLettersAmount?: number;
}

export const ReadMore: FC<Props> = ({
  text,
  className,
  maxLettersAmount = 300
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const countSymbolPair = (sentence: string, pair = '**') => {
    let count = 0;

    // Loop through the sentence
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sentence.length - 1; i++) {
      if (sentence[i] + sentence[i + 1] === pair) {
        // eslint-disable-next-line no-plusplus
        count++;
      }
    }

    return count;
  };

  const getBeginText = (sentence: string) => {
    const repeatsAmount = countSymbolPair(sentence);
    const needsClosingTag = repeatsAmount % 2 !== 0;

    return needsClosingTag ? `${sentence}**` : sentence;
  };

  const itCanOverflow = text.length > maxLettersAmount;
  const beginText = itCanOverflow
    ? getBeginText(text.slice(0, maxLettersAmount - 1))
    : text;
  const endText = text.slice(maxLettersAmount - 1);

  return (
    <p className={cn(className, !isExpanded && 'collapsed')}>
      {!isExpanded && <Markdown>{beginText}</Markdown>}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>... </span>}
          {isExpanded ? (
            <Markdown options={{ forceWrapper: true, forceBlock: true }}>
              {text}
            </Markdown>
          ) : (
            <span
              aria-hidden={!isExpanded}
              className={isExpanded ? '' : styles.hidden}
            >
              <Markdown>{endText}</Markdown>
            </span>
          )}
          <span
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            className={styles['see-more']}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'show less' : 'show more'}
          </span>
        </>
      )}
    </p>
  );
};
