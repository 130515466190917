import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { InsightsType } from 'enums';

import { TREEMAP_COLORS, TreemapComponentExtended } from './TreemapChart';

export const buildTreemapData = (
  widgetType: DashboardComponent['type'],
  data: DashboardParamsData[]
): TreemapComponentExtended[] => {
  if (!data.length) {
    return [];
  }

  if (widgetType === InsightsType.Treemap) {
    return data.map((item, index) => ({
      ...item,
      color:
        TREEMAP_COLORS[Math.floor((index / (data?.length || 1)) * data.length)]
    }));
  }

  if (data.length > 1) {
    return data.map((item, index) => ({
      ...item,
      name: item.name || item.brand || '',
      color:
        TREEMAP_COLORS[Math.floor((index / (data?.length || 1)) * data.length)]
    }));
  }

  const technologiesAsParentList: DashboardParamsData[] = [];

  for (let i = 0; i < data?.length; i += 1) {
    const brand = data[i];

    if (brand.children?.length) {
      for (let k = 0; k < brand.children?.length; k += 1) {
        const child = brand.children[k];

        if (child.technologies?.length) {
          child.technologies.forEach((technology) => {
            const selectedTechnology = technologiesAsParentList.find(
              ({ name }) => technology === name
            );

            if (selectedTechnology) {
              selectedTechnology?.children?.push(child);
            } else {
              technologiesAsParentList.push({
                brand: brand?.brand,
                name: technology,
                children: [child],
                label: '',
                value: [[0, 0]]
              });
            }
          });
        }
      }
    }
  }

  return technologiesAsParentList.map((technology, index) => ({
    ...technology,
    color:
      TREEMAP_COLORS[
        Math.floor(
          (index / (technologiesAsParentList?.length || 1)) *
            technologiesAsParentList.length
        )
      ]
  }));
};
