import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useManagePayments } from 'hooks/api';

import styles from './styles.module.scss';

export const BillingDetails: FC = () => {
  const { t } = useTranslation();
  const { mutate: managePayments } = useManagePayments();

  const handleButtonClick = () => {
    managePayments();
  };

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <span className={styles.title}>
          {t('Page.Profile.Billing.BillingDetailsTitle')}
        </span>
        <span className={styles.subtitle}>
          {t('Page.Profile.Billing.BillingDetailsSubtitle')}
        </span>
      </div>
      <div className={styles.actions}>
        <Button
          fullWidth
          size="small"
          color="primary"
          variant="outlined"
          className={styles.button}
          onClick={handleButtonClick}
        >
          {t('Page.Profile.Billing.Buttons.ViewBillingDetails')}
        </Button>
      </div>
    </div>
  );
};
