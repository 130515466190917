import { useEffect, useState } from 'react';

const MAX_TABLET_WIDTH = 1056;
const MAX_MOBILE_WIDTH = 672;
export const useTablet = () => {
  const [isTablet, setIsTablet] = useState(
    window.innerWidth < MAX_TABLET_WIDTH && window.innerWidth > MAX_MOBILE_WIDTH
  );

  const handleResize = () => {
    if (
      window.innerWidth < MAX_TABLET_WIDTH &&
      window.innerWidth > MAX_MOBILE_WIDTH
    ) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return isTablet;
};
