import { FC } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Chat } from 'components/Chat';
import { Message } from 'entities/Message.entity';
import { useUserInfo } from 'hooks/api';
import { useChatDetails } from 'hooks/api/useChatDetails';

import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
  isChatHidden: boolean;
  hasMessages: boolean;
  isRegenerateMessageSending: boolean;
  isMessageSending: boolean;
  extendedMessages: Message[] | null;
  handleOpenMoreMoreDataModal: () => void;
  regenerateMessage: (messageId: string) => void;
  handleSuggestionClick: (text: string) => void;
  handleDashboardClick: (dashboardId: string, isPaid: boolean) => void;
  handleActivateAccount: () => void;
}

export const ChatContainer: FC<Props> = ({
  hasMessages,
  isLoading,
  isMessageSending,
  isRegenerateMessageSending,
  isChatHidden,
  extendedMessages,
  handleSuggestionClick,
  handleDashboardClick,
  regenerateMessage,
  handleOpenMoreMoreDataModal,
  handleActivateAccount
}) => {
  const { chatId, industry } = useParams();
  const { data: userInfo } = useUserInfo();
  const { data: chatDetails } = useChatDetails(chatId);

  const subject = chatDetails?.subject;

  if (!industry && !subject && (!hasMessages || !extendedMessages?.length))
    return null;

  const isRateButtonHidden = !!userInfo?.deactivatedAt || !!industry;

  return (
    <div
      className={cn(styles.chat, {
        [styles.hidden]: isChatHidden
      })}
    >
      <Chat
        isLoading={isLoading}
        isMessageSending={isMessageSending}
        isRateButtonHidden={isRateButtonHidden}
        messages={extendedMessages as Message[]}
        onSuggestionClick={handleSuggestionClick}
        regenerateChatMessage={regenerateMessage}
        onShowDashboardClick={handleDashboardClick}
        onRequestMoreDataClick={handleOpenMoreMoreDataModal}
        onActivateAccountClick={handleActivateAccount}
        isRegenerateMessageSending={isRegenerateMessageSending}
      />
    </div>
  );
};
