import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'enums';

import styles from './styles.module.scss';

export const UpgradeNote = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <strong className={styles.title}>
        {t('Page.Chat.UpgradeNote.Title')}
      </strong>
      <span className={styles.description}>
        {t('Page.Chat.UpgradeNote.Description')}
      </span>
      <Link className={styles.cta} to={Routes.SubscriptionPlans}>
        {t('Page.Chat.UpgradeNote.Cta')}
      </Link>
    </div>
  );
});
