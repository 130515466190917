import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';

import { Bucket } from '../Histogram';

import '../styles.scss';

interface Props {
  bucket: Bucket;
  boundsHeight: number;
  xScale: (data: number) => number;
  yScale: (data: number) => number;
}

const BUCKET_PADDING = 1;

export const HistogramRect: FC<Props> = ({
  bucket,
  xScale,
  yScale,
  boundsHeight
}) => {
  const { t } = useTranslation();

  const renderTooltip = () => {
    d3.select('#histogram-rect-container')
      .append('rect')
      .attr('width', 80)
      .attr('height', 40)
      .attr('style', 'stroke-width: 2px; stroke: #E2E4F9FF')
      .attr('fill', '#ffffff')
      .attr('x', xScale(bucket.x0) - 36 + (bucket.x1 - bucket.x0))
      .attr('y', yScale(bucket.length) - 42)
      .attr('id', `histogram-tooltip-${bucket.x0}`);

    d3.select('#histogram-rect-container')
      .append('text')
      .attr('text-anchor', 'start')
      .attr('fill', '#676767FF')
      .attr('x', xScale(bucket.x0) - 36 + (bucket.x1 - bucket.x0) + 12)
      .attr('y', yScale(bucket.length) - 24)
      .attr('font-size', 14)
      .text(t('Common.Amount'))
      .attr('id', `histogram-tooltip-text-label-${bucket.x0}`);

    d3.select('#histogram-rect-container')
      .append('text')
      .attr('text-anchor', 'start')
      .attr('fill', '#676767FF')
      .attr('x', xScale(bucket.x0) - 36 + (bucket.x1 - bucket.x0) + 12)
      .attr('y', yScale(bucket.length) - 8)
      .attr('font-size', 12)
      .attr('font-weight', 500)
      .text(bucket.length)
      .attr('id', `histogram-tooltip-text-${bucket.x0}`);
  };

  const hideTooltip = () => {
    d3.select(`#histogram-tooltip-${bucket.x0}`).remove();
    d3.select(`#histogram-tooltip-text-label-${bucket.x0}`).remove();
    d3.select(`#histogram-tooltip-text-${bucket.x0}`).remove();
  };

  return (
    <rect
      rx={2}
      fill="#FFCB7D"
      y={yScale(bucket.length)}
      onMouseEnter={renderTooltip}
      onMouseLeave={hideTooltip}
      className="histogram-bar"
      x={xScale(bucket.x0) + BUCKET_PADDING / 2}
      z={20}
      width={xScale(bucket.x1) - xScale(bucket.x0)}
      height={boundsHeight - yScale(bucket.length) + 2}
    />
  );
};
