import { useQuery } from '@tanstack/react-query';
import { fetchRegionsMapData } from 'actions/dashboardActions';
import { queryKeys } from 'enums';

export type RegionsMapData = {
  ux_name_en: string;
  ux_name_de: string;
  region_name: string;
  is_region: boolean | null;
  is_country: boolean | null;
  country_code: string;
  geom: string;
  code: string;
}[];

export const useFetchRegionsMapData = () =>
  useQuery<RegionsMapData>({
    queryKey: queryKeys.dashboardRegionsMapData,
    queryFn: () => fetchRegionsMapData(),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
