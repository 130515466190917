import { useQuery } from '@tanstack/react-query';
import { getActiveSubscription } from 'actions/paymentsActions';
import { Subscription } from 'entities/Subscription.entity';
import { queryKeys } from 'enums';

import { useAuthContext } from '../useAuthContext';
import { useUserInfo } from './useUserInfo';

export const useActiveSubscription = () => {
  const { data: userInfo } = useUserInfo();
  const { isAuthenticated } = useAuthContext();

  return useQuery<Subscription>({
    enabled: isAuthenticated && userInfo?.isPremiumUser,
    queryKey: queryKeys.activeSubscriptionDetails,
    queryFn: () => getActiveSubscription(),
    refetchOnWindowFocus: false,
    retry: 1
  });
};
