import { NotificationsFilters } from 'actions/notificationsActions';

export const queryKeys = {
  updateEmailConfirm: ['updateEmailConfirm'],
  notifications: (filters?: NotificationsFilters) => ['notifications', filters],
  paginatedNotifications: ['notifications', 'paginated'],
  subscriptionPaymentsConfirmation: [
    'payments',
    'subscription',
    'confirmation'
  ],
  oneTimePaymentConfirmation: ['payments', 'oneTime', 'confirmation'],
  subscriptions: ['subscriptions'],
  activeSubscriptionDetails: ['subscription', 'active'],
  userInfo: ['user', 'info'],
  userAvatar: ['user', 'avatar'],
  userCoins: ['user', 'coins'],
  chats: ['chats'],
  chatsAnnouncement: ['chats', 'announcement'],
  filteredChats: (filters: { includeArchived: boolean }) => [
    ...queryKeys.chats,
    { filters }
  ],
  dashboard: ['dashboard'],
  dashboardOtherMapData: ['dashboard', 'map', 'otherData'],
  dashboardRegionsMapData: ['dashboard', 'map', 'regions'],
  dashboards: ['dashboards'],
  messages: ['messages'],
  questions: ['questions'],
  dashboardDetails: (messageId: string): string[] => [
    ...queryKeys.dashboard,
    'details',
    messageId
  ],
  chatMessages: (chatId: string): string[] => [
    ...queryKeys.chats,
    chatId,
    ...queryKeys.messages
  ],
  chatDetails: (chatId: string): string[] => [...queryKeys.chats, chatId],
  nestedDashboards: (dashboardId: string): string[] => [
    ...queryKeys.dashboards,
    dashboardId,
    'filtered'
  ]
};
