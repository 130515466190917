import { FC, useCallback, useState } from 'react';
import { ReactComponent as CrossIconOutlined } from 'assets/CrossIconOutlined.svg';
import { useChatAnnouncement } from 'hooks/api/useChatAnnouncement';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
}

export const AnnouncementBar: FC<Props> = ({ isActive }) => {
  const { data } = useChatAnnouncement();
  const [isClosed, setClosed] = useState(false);
  const now = new Date();
  const isExpired = data?.dueDate ? now >= new Date(data.dueDate) : true;
  const isAnnouncementVisible =
    data?.body && isActive && !isExpired && !isClosed;

  const handleCloseClick = useCallback(() => {
    setClosed(true);
  }, []);

  return isAnnouncementVisible ? (
    <div className={styles.container}>
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: data?.body }}
      />
      <CrossIconOutlined onClick={handleCloseClick} className={styles.close} />
    </div>
  ) : null;
};
