import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const LimitedCompanyListNote = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <strong className={styles.title}>
        {t('Page.Chat.LimitedCompanyListNote.Title')}
      </strong>
      <span className={styles.description}>
        {t('Page.Chat.LimitedCompanyListNote.Description')}
      </span>
      <a
        className={styles.cta}
        target="_blanc"
        href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ07cJw9mR8dzFSXr0J-vaB2yUh4cdwxhaoyTYRfmpu1YDsvEpSQuE6eFuJ8WD5XxQ19SKDkXmzR"
      >
        {t('Page.Chat.LimitedCompanyListNote.Cta')}
      </a>
    </div>
  );
});
