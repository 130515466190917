import { useMutation } from '@tanstack/react-query';
import { updateChat } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useUpdateChat = (id: string, onSuccess: () => void) => {
  const snackbar = useSnackbar();

  return useMutation<Chat, ApiError, { title: string }>({
    mutationFn: ({ title }) => updateChat(id, { title }),
    onSuccess: async () => {
      await onSuccess();
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
