/* eslint-disable react/no-unknown-property */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import { ReactComponent as ParticleCloudIcon } from 'assets/partical-cloud.svg';
import { ReactComponent as PieChartIcon } from 'assets/pie-chart.svg';
import cn from 'classnames';
import { CommonTooltip, IconSwitch, Tooltip } from 'components';
import { GroupedSelect } from 'components/GroupedSelect';
import { GroupOption } from 'components/GroupedSelect/GroupedSelect';
import { BlobOptionValue } from 'entities/BlobOptionValue.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { useIsExternalUserDashboard } from 'hooks';
import { useUpdateWidgetFilter } from 'hooks/api';
import { valueToPercentage } from 'utils/numberUtils';

import { ParticlePieChartView } from './ParticlePieChartView';
import { ParticleView } from './ParticleView';

import styles from './styles.module.scss';

export enum ParticleColors {
  Green = '#7DEAB2',
  Orange = '#FFCB7D',
  Gray = '#BFC2D6'
}

interface Props {
  data: DashboardComponent;
  isDashboardDownloading?: boolean;
}

enum ParticleCloudViews {
  Absolute = 'absolute',
  Relative = 'relative'
}

export interface ParticleSelectData {
  groupLabel: string;
  label: string;
  value: string;
}

export const ParticleCloud: FC<Props> = ({ data, isDashboardDownloading }) => {
  const { t } = useTranslation();
  const { filters } = data;
  const { dashboardId } = useParams();
  const [chartView, setChartView] = useState(ParticleCloudViews.Absolute);
  const [isNumberTooltipOpen, setIsNumberTooltipOpen] = useState(false);
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const [isNumberOffTooltipOpen, setIsNumberOffTooltipOpen] = useState(false);
  const [isPercentageTooltipOpen, setIsPercentageTooltipOpen] = useState(false);
  const [isPercentageOffTooltipOpen, setIsPercentageOffTooltipOpen] =
    useState(false);

  const openNumberTooltip = () => setIsNumberTooltipOpen(true);
  const closeNumberTooltip = () => setIsNumberTooltipOpen(false);

  const openNumberOffTooltip = () => setIsNumberOffTooltipOpen(true);
  const closeNumberOffTooltip = () => setIsNumberOffTooltipOpen(false);

  const openPercentageTooltip = () => setIsPercentageTooltipOpen(true);
  const closePercentageTooltip = () => setIsPercentageTooltipOpen(false);

  const openPercentageOffTooltip = () => setIsPercentageOffTooltipOpen(true);
  const closePercentageOffTooltip = () => setIsPercentageOffTooltipOpen(false);
  const toggleChartView = (checked: boolean) => {
    setChartView(
      checked ? ParticleCloudViews.Relative : ParticleCloudViews.Absolute
    );
  };

  const options = data?.params?.data;

  const { mutate: updateWidgetsFiltersMutation } = useUpdateWidgetFilter({
    dashboardId: dashboardId || '',
    widgetId: data.id
  });

  const getOptionName = (option: DashboardParamsData, index: number) =>
    `${option?.title}__${option?.values?.[index].label}__${option?.values?.[index]?.value}`;

  const buildOptionName = (
    selectedOption: BlobOptionValue,
    groupTitle?: string
  ) => `${groupTitle}__${selectedOption.label}__${selectedOption?.value}`;

  const filterItems = (values: BlobOptionValue[]) =>
    values.filter(({ label }) => (label ? filters?.includes(label) : false));

  const getPreselectedOptions = () => {
    const result: string[] = [];
    const defaultOptions = ['', ''];

    if (!options?.length) return defaultOptions;

    for (let i = 0; i < options?.length; i += 1) {
      if (result.length > 1) {
        return result;
      }

      const option = options[i];
      const matchedOptions = option.values?.length
        ? filterItems(option.values)
        : [];

      if (matchedOptions?.length) {
        matchedOptions
          .sort((a, b) =>
            filters ? filters.indexOf(a.label) - filters.indexOf(b.label) : 1
          )
          ?.map((matchedOption) =>
            result.push(buildOptionName(matchedOption, option.title))
          );
      }
    }

    return result?.length > 1 ? result : defaultOptions;
  };

  const [firstPreselectedValue, secondPreselectedValue] =
    getPreselectedOptions();
  const [selectedFirstFilter, setSelectedFirstFilter] = useState<string>(
    firstPreselectedValue
  );
  const [selectedSecondFilter, setSelectedSecondFilter] = useState<string>(
    secondPreselectedValue
  );

  useEffect(() => {
    setSelectedFirstFilter(firstPreselectedValue);
    setSelectedSecondFilter(secondPreselectedValue);
  }, [firstPreselectedValue, secondPreselectedValue]);

  const getFilterData = useCallback((filterData: string) => {
    const splitedStr = filterData.split('__');

    return {
      groupLabel: splitedStr[0],
      label: splitedStr[1]?.replace(/__/g, ' '),
      value: splitedStr[2]
    };
  }, []);

  const firstFilter = useMemo(
    () => getFilterData(selectedFirstFilter),
    [getFilterData, selectedFirstFilter]
  );

  const secondFilter = useMemo(
    () => getFilterData(selectedSecondFilter),
    [getFilterData, selectedSecondFilter]
  );

  const updateBothFilters = (
    selectedFirstOptionName: string,
    selectedSecondOptionName: string
  ) => {
    const selectedSecondOption = getFilterData(selectedSecondOptionName);

    updateWidgetsFiltersMutation({
      filters: [selectedFirstOptionName, selectedSecondOption.label]
    });
  };

  const handleFirstFilterChange = (e: SelectChangeEvent) => {
    setSelectedFirstFilter(e.target.value);

    const selectedOption = getFilterData(e.target.value);
    const isGroupChanged = firstFilter.groupLabel !== selectedOption.groupLabel;

    if (!isGroupChanged && !isExternalUserDashboard) {
      updateWidgetsFiltersMutation({
        filters: [selectedOption.label, secondFilter.label]
      });
    } else {
      const foundOption = options?.find(
        (option) => option.title === selectedOption.groupLabel
      );
      const index = foundOption?.values?.findIndex(
        (value) => value.label !== selectedOption.label
      );

      if (!foundOption || index == null) return;

      const optionName = getOptionName(foundOption, index);

      setSelectedSecondFilter(optionName);

      if (!isExternalUserDashboard) {
        updateBothFilters(selectedOption.label, optionName);
      }
    }
  };

  const handleSecondFilterChange = (e: SelectChangeEvent) => {
    setSelectedSecondFilter(e.target.value);
    const selectedOption = getFilterData(e.target.value);

    updateWidgetsFiltersMutation({
      filters: [firstFilter.label, selectedOption.label]
    });
  };

  const handleDisableMenuItem = (groupLabel: string) => {
    const geolocationGroups = ['region', 'country_code'];

    if (
      geolocationGroups.includes(groupLabel) &&
      geolocationGroups.includes(firstFilter.groupLabel)
    ) {
      return false;
    }

    return groupLabel !== firstFilter.groupLabel;
  };

  const filterColors = useMemo(
    () => ({
      first:
        firstFilter.label.toLowerCase() ===
          t('Page.Dashboard.ParticleCloud.Others').toLowerCase() &&
        chartView === ParticleCloudViews.Relative
          ? ParticleColors.Gray
          : ParticleColors.Green,
      second:
        secondFilter.label.toLowerCase() ===
          t('Page.Dashboard.ParticleCloud.Others').toLowerCase() &&
        chartView === ParticleCloudViews.Relative
          ? ParticleColors.Gray
          : ParticleColors.Orange
    }),
    [chartView, firstFilter.label, secondFilter.label, t]
  );

  const totalAmount = useMemo(
    () =>
      [firstFilter, secondFilter].reduce(
        (partialSum, a) => partialSum + +a.value,
        0
      ),
    [firstFilter, secondFilter]
  );

  useEffect(() => {
    if (isDashboardDownloading) {
      setChartView(ParticleCloudViews.Relative);
    }
  }, [isDashboardDownloading]);

  if (!options?.length) return null;

  return (
    <div className={styles.container}>
      <div className={styles.switch}>
        <IconSwitch
          onChange={toggleChartView}
          checkedHandleIcon={
            <Tooltip
              open={isNumberOffTooltipOpen}
              title={
                chartView === ParticleCloudViews.Absolute
                  ? t('Page.Dashboard.ParticleCloud.Absolute')
                  : t('Page.Dashboard.ParticleCloud.Relative')
              }
            >
              <PieChartIcon
                className={styles.icon}
                onMouseOver={openNumberOffTooltip}
                onMouseLeave={closeNumberOffTooltip}
              />
            </Tooltip>
          }
          uncheckedHandleIcon={
            <Tooltip
              open={isPercentageOffTooltipOpen}
              title={t('Page.Dashboard.ParticleCloud.Absolute')}
            >
              <ParticleCloudIcon
                className={styles.icon}
                onMouseOver={openPercentageOffTooltip}
                onMouseLeave={closePercentageOffTooltip}
              />
            </Tooltip>
          }
          checkedIcon={
            <Tooltip
              open={isPercentageTooltipOpen}
              title={t('Page.Dashboard.ParticleCloud.Absolute')}
            >
              <ParticleCloudIcon
                onMouseOver={openPercentageTooltip}
                onMouseLeave={closePercentageTooltip}
              />
            </Tooltip>
          }
          uncheckedIcon={
            <Tooltip
              open={isNumberTooltipOpen}
              title={t('Page.Dashboard.ParticleCloud.Relative')}
            >
              <PieChartIcon
                onMouseOver={openNumberTooltip}
                onMouseLeave={closeNumberTooltip}
              />
            </Tooltip>
          }
          checked={chartView === ParticleCloudViews.Relative}
        />
      </div>
      <div className={styles.content}>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={data?.title || t('Page.Dashboard.ParticleCloud.Title')}
        />
        <span className={styles.subtitle}>
          {t('Page.Dashboard.ParticleCloud.Subtitle')}
        </span>

        <div className={styles.note}>
          <InfoIcon />
          {t('Page.Dashboard.ParticleCloud.Note')}
        </div>

        <div
          className={cn(
            styles.filters,
            isExternalUserDashboard && styles.disabled
          )}
        >
          <GroupedSelect
            value={selectedFirstFilter}
            label={firstFilter.groupLabel || t('Common.EmptySelectLabel')}
            options={options as GroupOption[]}
            onChange={handleFirstFilterChange}
            valueColor={filterColors.first}
            anotherLabel={secondFilter.label}
          />
          <GroupedSelect
            value={selectedSecondFilter}
            label={secondFilter.groupLabel || t('Common.EmptySelectLabel')}
            options={options as GroupOption[]}
            onChange={handleSecondFilterChange}
            valueColor={filterColors.second}
            handleDisableMenuItem={handleDisableMenuItem}
            anotherLabel={firstFilter.label}
          />
        </div>
      </div>
      <div className={styles.content}>
        {chartView === ParticleCloudViews.Absolute ? (
          <ParticleView
            filters={{ first: firstFilter, second: secondFilter }}
          />
        ) : (
          <ParticlePieChartView
            totalAmount={totalAmount}
            data={[firstFilter, secondFilter]}
          />
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.row}>
          <span
            className={styles.color}
            style={{ backgroundColor: ParticleColors.Green }}
          />
          <span className={styles.label}>{firstFilter.label}</span>
          <span className={styles.value}>
            {valueToPercentage(
              chartView === ParticleCloudViews.Relative
                ? +firstFilter.value / totalAmount
                : firstFilter.value
            )}
          </span>
        </div>
        <span className={styles.separator} />

        <div className={styles.row}>
          <span
            className={styles.color}
            style={{ backgroundColor: ParticleColors.Orange }}
          />
          <span className={styles.label}>{secondFilter.label}</span>
          <span className={styles.value}>
            {valueToPercentage(
              chartView === ParticleCloudViews.Relative
                ? +secondFilter.value / totalAmount
                : secondFilter.value
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
