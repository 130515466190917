import { useMutation } from '@tanstack/react-query';
import { updateNotificationsStatus } from 'actions/notificationsActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { DEFAULT_NOTIFICATIONS_FILTERS } from 'utils/constants';

export const useUpdateNotificationsStatus = () => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, string[]>({
    mutationFn: (ids) => updateNotificationsStatus(ids),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: queryKeys.notifications(DEFAULT_NOTIFICATIONS_FILTERS)
      });
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
