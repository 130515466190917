import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { WidgetEmptyState } from 'components';
import { Chat } from 'components/Chat';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { Routes } from 'enums/Routes';

import styles from './styles.module.scss';

interface Props {
  data: DashboardParams;
}

export const ChatHistory: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatId } = useParams();

  const messages = useMemo(
    () => [...(data?.history || [])].reverse(),
    [data?.history]
  );

  const goToChat = () => {
    if (!chatId) {
      return;
    }

    navigate(Routes.Chat.replace(':chatId', chatId));
  };

  if (!messages.length) {
    return (
      <div className={styles['empty-history']}>
        {t('Page.Dashboard.ChatHistory.NoMessages')}
      </div>
    );
  }

  return (
    <div className={cn(styles.container)}>
      <h2
        className={cn(styles.title)}
        title={t('Page.Dashboard.ChatHistory.Title')}
      >
        {t('Page.Dashboard.ChatHistory.Title')}
      </h2>
      {messages?.length ? (
        <div className={styles.chat}>
          <Chat
            messages={messages}
            isRateButtonHidden
            isDashboardButtonHidden
          />
        </div>
      ) : (
        <WidgetEmptyState />
      )}

      <div className={styles['button-container']}>
        <Button
          color="primary"
          size="medium"
          variant="contained"
          onClick={goToChat}
        >
          {t('Page.Dashboard.ChatHistory.BackToChat')}
        </Button>
      </div>
    </div>
  );
};
