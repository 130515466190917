import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArchiveIcon } from 'assets/archive.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import cn from 'classnames';
import { DashboardData } from 'entities/Dashboard.entity';
import { Routes } from 'enums/Routes';
import { useArchiveChat } from 'hooks/api/useArchiveChat';
import { useUpdateChat } from 'hooks/api/useUpdateChat';

import { EditableTitle } from '../EditableTitle';
import { ChatCardCollapse } from './ChatCardCollapse';

import styles from './styles.module.scss';

interface Props {
  id: string;
  title: string;
  archived?: boolean;
  className?: string;
  refetch: () => void;
  isActive: boolean;
  dashboards: DashboardData[];
}

export const ChatCard: FC<Props> = ({
  id,
  title,
  refetch,
  className,
  archived,
  isActive,
  dashboards
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isEditable, setEditable] = useState<boolean>(false);

  const { mutate: archiveMutation } = useArchiveChat(id);

  const toggleEditable = useCallback(() => {
    setEditable((value) => !value);
  }, []);

  const onUpdateChatSuccess = async () => {
    await refetch();
    toggleEditable();
  };

  const { mutate: updateMutation } = useUpdateChat(id, onUpdateChatSuccess);

  const goToChat = () => {
    navigate(Routes.Chat.replace(':chatId', id));
  };

  const onToggleArchiveChat = () => {
    archiveMutation({ isArchived: !archived });
  };

  const onUpdateChat = (value: string) => {
    updateMutation({ title: value });
  };

  return (
    <div
      data-testid="card"
      className={cn(
        styles.card,
        isActive && styles.active,
        archived && styles.archived,
        className
      )}
    >
      <div className={styles.header}>
        {isEditable ? (
          <EditableTitle
            value={title}
            onBlur={onUpdateChat}
            label={t('Form.Placeholder.ChatName')}
            placeholder={t('Form.Placeholder.ChatName')}
          />
        ) : (
          <>
            <div
              onClick={goToChat}
              className={cn(styles.title, 'overflowed-text')}
            >
              {title}
            </div>
            <div className={styles.actions}>
              <span onClick={toggleEditable}>
                <EditIcon className={styles['action-icon']} />
              </span>
              <span data-testid="archive" onClick={onToggleArchiveChat}>
                <ArchiveIcon className={styles['action-icon']} />
              </span>
            </div>
          </>
        )}
      </div>
      {!!dashboards.length && (
        <div>
          {(dashboards || []).map((dashboard) => (
            <ChatCardCollapse data={dashboard} key={dashboard.id} />
          ))}
        </div>
      )}
    </div>
  );
};
