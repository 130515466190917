import { useQuery } from '@tanstack/react-query';
import { fetchUserInfo } from 'actions/userActions';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';

export const useUserInfo = () => {
  const { isAuthenticated } = useAuthContext();

  return useQuery<User>({
    enabled: isAuthenticated,
    queryKey: queryKeys.userInfo,
    queryFn: fetchUserInfo,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    staleTime: 100
  });
};
