import { useUserInfo } from './api';
import { useLandingUrl } from './useLandingUrl';

export const useContactGieniUrl = () => {
  const { data: userInfo } = useUserInfo();
  const landigUrl = useLandingUrl();

  if (!userInfo) return `${landigUrl}/contact`;

  const { firstName, lastName, email } = userInfo;

  return `${landigUrl}/contact?firstName=${firstName}&lastName=${lastName}&email=${encodeURIComponent(
    email
  )}`;
};
