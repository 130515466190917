import { useQuery } from '@tanstack/react-query';
import {
  fetchNotifications,
  NotificationsFilters
} from 'actions/notificationsActions';
import { Notification } from 'entities/Notification.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';

import { useUserInfo } from './useUserInfo';

export const useNotifications = (filters: NotificationsFilters) => {
  const { isAuthenticated } = useAuthContext();
  const { data: userInfo } = useUserInfo();

  return useQuery<Notification[]>({
    enabled: isAuthenticated && !userInfo?.isFirstLogin,
    queryKey: queryKeys.notifications(filters),
    queryFn: () => fetchNotifications(filters),
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
};
