import { deserialize, list, primitive, serializable } from 'serializr';

export class DashboardSequence {
  @serializable(list(primitive()))
  firstColumn: string[] | null = null;

  @serializable(list(primitive()))
  secondColumn: string[] | null = null;

  static deserialize(json: Object | string): DashboardSequence {
    return deserialize(DashboardSequence, json);
  }
}
