import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TextField } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';
import cn from 'classnames';
import { Keys } from 'enums/KeyboardKeys';

import styles from './styles.module.scss';

interface Props {
  value: string;
  label: string;
  placeholder?: string;
  inputClassName?: string;
  className?: string;
  onBlur: (value: string) => void;
}

export const EditableTitle: FC<Props> = ({
  value,
  className,
  inputClassName,
  placeholder,
  label,
  onBlur
}) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const onSubmit = useCallback(
    (e?: MouseEvent<HTMLSpanElement>) => {
      e?.stopPropagation();
      if (!inputValue) {
        setInputValue(value);
        onBlur(value);
      } else {
        onBlur(inputValue);
      }
    },
    [inputValue, onBlur, value]
  );

  const clearInput = (e?: MouseEvent<HTMLSpanElement>) => {
    e?.stopPropagation();
    setInputValue('');
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setInputValue(event.target.value);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter) {
      onSubmit();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <TextField
        size="small"
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        id={label.toLowerCase()}
        placeholder={placeholder}
        className={cn(styles.container, className)}
        inputProps={{
          maxLength: 110,
          className: cn(styles.input, inputClassName)
        }}
      />
      <div>
        <span onClick={clearInput}>
          <CrossIcon className={styles['action-icon']} />
        </span>
        <span onClick={onSubmit}>
          <CheckIcon className={styles['action-icon']} />
        </span>
      </div>
    </>
  );
};
