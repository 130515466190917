import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { GlobalShortcutsContextProvider } from 'hooks/useGlobalShortcutsContext';

export const GlobalShortcutsProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [chatId, setChatId] = useState<string | null>(null);
  const [chatInput, setChatInput] = useState<HTMLInputElement | null>(null);
  const [sidebarVisibilityHandler, setSidebarVisibilityHandler] =
    useState<Function | null>(null);
  const [shortcutsModalVisibilityHandler, setShortcutsModalVisibilityHandler] =
    useState<Function | null>(null);

  return (
    <GlobalShortcutsContextProvider
      value={{
        chatId,
        setChatId,
        chatInput,
        setChatInput,
        sidebarVisibilityHandler,
        setSidebarVisibilityHandler,
        shortcutsModalVisibilityHandler,
        setShortcutsModalVisibilityHandler
      }}
    >
      {children}
    </GlobalShortcutsContextProvider>
  );
};
