export const shortifyNumber = (num: number) => {
  const hundredDivider = 1000;
  const billionDivider = 1000000;

  if (num >= billionDivider) {
    return `${(num / billionDivider).toFixed(1).replace(/\.0$/, '')}m`;
  }
  if (num >= hundredDivider) {
    return `${(num / hundredDivider).toFixed(1).replace(/\.0$/, '')}k`;
  }
  return num;
};

export const valueToPercentage = (value: number | string) =>
  `${Math.round(+value * 100)}%`;
