import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { InsightsType } from 'enums';
import {
  SelectedFilterItem,
  WidgetFilter
} from 'views/DashboardV2/WidgetFilter';

import styles from './styles.module.scss';

interface Props {
  type: string;
  title?: string;
  hasFilter: boolean;
  headerRef: RefObject<HTMLDivElement>;
  selectedItem: SelectedFilterItem;
}

export const MapHeader: FC<Props> = ({
  type,
  title,
  headerRef,
  hasFilter,
  selectedItem
}) => {
  const { t } = useTranslation();

  return (
    <div ref={headerRef} className={styles.header}>
      <CommonTooltip
        title={title || t('Page.Dashboard.Map.Title')}
        className={cn(styles.title, 'overflowed-text-multiline')}
      />

      {hasFilter && (
        <WidgetFilter
          type={type}
          className={styles.filter}
          tooltip={t('Page.Dashboard.Map.ChooseFilter')}
          selectedItem={selectedItem}
          widgetType={InsightsType.MapDots}
          notes={{
            active: t('Page.Dashboard.Map.NoteActive', {
              region: selectedItem?.name || '-'
            }),
            inactive: t('Page.Dashboard.Map.Note')
          }}
        />
      )}
    </div>
  );
};
