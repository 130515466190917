import { useMutation } from '@tanstack/react-query';
import { getOneTimePaymentSecret } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { OneTimePurchasePlanEnum } from 'enums';

import { useSnackbar } from '../useSnackbar';

export const useOneTimePaymentSecret = (
  onSuccess: (secret: string) => void
) => {
  const snackbar = useSnackbar();

  return useMutation<
    { clientSecret: string },
    ApiError,
    { count: OneTimePurchasePlanEnum }
  >({
    mutationFn: ({ count }) => getOneTimePaymentSecret({ count }),
    onSuccess: async (data) => {
      await onSuccess(data.clientSecret);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
