import { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';
import { useMobile } from 'hooks';

import { SelectedFilterItem } from '../../WidgetFilter';
import { renderZoomableTreemapChart } from './renderZoomableTreemapChart';

import './styles.scss';

interface Props {
  data: DashboardParamsData;
  selectItem: (selectedItem: SelectedFilterItem) => void;
  chartColors: { start: string; end: string; root: string };
}

const MOBILE_DIMENTIONS = {
  width: 800,
  height: 1200
};

const DESKTOP_DIMENTIONS = {
  width: 1000,
  height: 650
};

export const ZoomableTreemap: FC<Props> = ({
  data,
  selectItem,
  chartColors
}) => {
  const isMobile = useMobile();
  const { width, height } = isMobile ? MOBILE_DIMENTIONS : DESKTOP_DIMENTIONS;
  const svgRef = useRef<SVGGElement>(null);
  const modifiedData = {
    name: data.name,
    children: data.children
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    renderZoomableTreemapChart({
      width,
      height,
      isMobile,
      selectItem,
      chartColors,
      data: modifiedData,
      svgElement: svg
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <svg
      // @ts-expect-error svg type
      ref={svgRef}
      style={{
        maxWidth: width,
        maxHeight: height + 99.5
      }}
      viewBox={`0.5, -100.5, ${width}, ${height + 99.5}`}
    />
  );
};
