import { useQuery } from '@tanstack/react-query';
import { getSubscriptions } from 'actions/paymentsActions';
import { Subscription } from 'entities/Subscription.entity';
import { queryKeys } from 'enums';

import { useAuthContext } from '../useAuthContext';

export const useSubscriptions = () => {
  const { isAuthenticated } = useAuthContext();

  return useQuery<Subscription[]>({
    enabled: isAuthenticated,
    queryKey: queryKeys.subscriptions,
    queryFn: () => getSubscriptions(),
    refetchOnWindowFocus: false,
    retry: 1
  });
};
