import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef
} from 'react';
import cn from 'classnames';
import * as d3 from 'd3';

import styles from './styles.module.scss';

interface Props {
  width: number;
  height?: number;
  endYear: number;
  setActiveYear: Dispatch<SetStateAction<number | null>>;
  startYear: number;
  activeYear: number;
  foundationsByYear: { [key: number]: number };
}

const thumbWidth = 14;

export const FoundationYearFilter: FC<Props> = ({
  height = 20,
  width,
  endYear,
  startYear,
  activeYear,
  foundationsByYear,
  setActiveYear
}) => {
  const axisRef = useRef<SVGSVGElement | null>(null);
  const chartWidth = width - thumbWidth;

  useEffect(() => {
    const xScale = d3
      .scaleLinear()
      .domain([startYear, endYear])
      .range([0, chartWidth]);

    const axisSvg = d3.select(axisRef.current);

    if (axisSvg) {
      axisSvg.selectChildren().remove();

      axisSvg.append('g').call(
        d3
          .axisBottom(xScale)
          .tickFormat(d3.format('d'))
          .tickSize(8)
          .ticks(Math.min(chartWidth / 60, endYear - startYear))
      );

      axisSvg.select('.domain').remove();

      axisSvg.selectAll('.tick').select('line').style('stroke', '#BFC2D6');

      axisSvg
        .selectAll('.tick')
        .select('text')
        .style('fill', '#676767')
        .style('font-family', 'Figtree')
        .style('font-size', '12px')
        .style('font-weight', '400')
        .style('line-height', '18px');

      axisSvg
        .select('.tick:first-of-type')
        .select('text')
        .style('transform', 'translateX(10px)');

      axisSvg
        .select('.tick:last-of-type')
        .select('text')
        .style('transform', 'translateX(-10px)');

      axisSvg
        .selectAll('.tick')
        .filter((d) => d === activeYear)
        .select('line')
        .style('stroke', '#FF9600');

      axisSvg
        .selectAll('.tick')
        .filter((d) => d === activeYear)
        .select('text')
        .style('fill', '#FF9600')
        .style('font-weight', '600')
        .style('text-align', 'left');
    }
  }, [activeYear, chartWidth, endYear, startYear, width]);

  const { foundationsByYearArray, maxFoundations } = useMemo(() => {
    const foundationsByYearArray = Object.entries(foundationsByYear);
    const maxFoundations = Math.max(
      ...foundationsByYearArray.map(([, value]) => value)
    );

    return { foundationsByYearArray, maxFoundations };
  }, [foundationsByYear]);

  const renderHistogram = () => (
    <div className={styles.histogram}>
      {foundationsByYearArray.map(([year, foundations]) => {
        const percent = (foundations / maxFoundations) * 100;
        return (
          <div
            key={year}
            className={cn(
              styles['histogram-item'],
              Number(year) === activeYear && styles['histogram-item-active']
            )}
            style={{ height: `${percent}%` }}
          />
        );
      })}
    </div>
  );

  const handleActiveYearChange = (e: ChangeEvent<HTMLInputElement>) =>
    setActiveYear(+e.target.value);

  return (
    <div className={styles.container}>
      {renderHistogram()}

      <input
        className={styles.input}
        onChange={handleActiveYearChange}
        type="range"
        min={startYear}
        max={endYear}
        value={activeYear}
      />

      <svg
        ref={axisRef}
        height={height}
        width={chartWidth}
        className={styles.scale}
        viewBox={`0, 0, ${chartWidth}, ${height}`}
      />
    </div>
  );
};
