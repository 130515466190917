export const validateRequired = (field = '') =>
  !!field.replace(/\s/g, '').length;

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,4}$/
    );
};

export const validatePasswordIsNotLikeEmail = (
  password: string,
  email: string
) => password !== email;

export const validatePasswordPattern = (password: string) => {
  // HINT: Min 8 characters, Max 32 characters, at least one uppercase letter, lowercase letter, symbol and number
  const passwordPatternRegexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]{8,32}$/;

  return passwordPatternRegexp.test(password);
};
