import { deserialize, serializable } from 'serializr';

export class CompanyContact {
  @serializable
  classification: string = '';

  @serializable
  email: string = '';

  @serializable
  first_name?: string = '';

  @serializable
  gender?: string = '';

  @serializable
  last_name?: string = '';

  static deserialize(json: Object | string): CompanyContact {
    return deserialize(CompanyContact, json);
  }
}
