import { FC, useMemo } from 'react';
import { CircleLayer } from 'react-map-gl';
import { MapTypes } from 'enums';
import { Feature, FeatureCollection } from 'geojson';

import { DOT_COLORS } from '../constants/mapSettings';
import { DataGroup } from '../hooks/useMapDataGroups';
import { MapLayer } from '../MapLayer';

interface Props {
  mapType: MapTypes;
  activeYear: number | null;
  dataGroups: DataGroup[];
  groupFilter: string[];
}

export const DotsMapLayer: FC<Props> = ({
  mapType,
  activeYear,
  dataGroups,
  groupFilter
}) => {
  const layers = useMemo(() => {
    let beforeId = 0;

    return dataGroups.map(({ value, isOther, label, name }, index) => {
      if (!value.length) return null;

      const layerData: CircleLayer = {
        id: `${mapType}-layer-${index}`,
        type: 'circle',
        paint: {
          'circle-radius': {
            base: 1,
            stops: [
              [6, 2],
              [10, 4]
            ]
          },
          'circle-color': isOther
            ? DOT_COLORS[DOT_COLORS.length - 1]
            : DOT_COLORS[index]
        }
      };

      const sourceData: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
      };

      if (groupFilter.includes(isOther ? name : label)) {
        value
          .filter(
            (item) => item[2] !== undefined && item[2] <= Number(activeYear)
          )
          .forEach((coordinates) => {
            sourceData.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coordinates[1], coordinates[0]]
              }
            } as Feature);
          });
      }

      if (index) beforeId = index - 1;

      return (
        <MapLayer
          key={layerData.id}
          index={index}
          mapType={mapType}
          beforeId={beforeId}
          layerData={layerData}
          sourceData={sourceData}
        />
      );
    });
  }, [activeYear, mapType, dataGroups, groupFilter]);

  return <>{layers}</>;
};
