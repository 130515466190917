import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { trackActivity } from 'actions/userActions';
import { ReactComponent as CheckIcon } from 'assets/check-small.svg';
import { ReactComponent as LogoIcon } from 'assets/logo-sidebar.svg';
import { ReactComponent as CloseIcon } from 'assets/sidebar-close.svg';
import { ReactComponent as UncheckIcon } from 'assets/uncheck-small.svg';
import { format, isSameMonth, isSameYear } from 'date-fns';
import { Chat } from 'entities/Chat.entity';
import { Routes } from 'enums/Routes';
import { useFilteredChats } from 'hooks/api/useFilteredChats';
import debounce from 'lodash.debounce';

import { Loader } from '../Loader';
import { WidgetEmptyState } from '../WidgetEmptyState';
import { ChatCard } from './ChatCard';

import styles from './styles.module.scss';

const trackUserActivity = debounce(trackActivity, 500);

interface Props {
  open?: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const Sidebar: FC<Props> = ({ open = false, onClose, onOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [showArchived, setShowArchived] = useState(true);

  const { data, refetch, isLoading } = useFilteredChats(showArchived);

  const currentChatId = useMemo(() => {
    const splittedUrl = location.pathname.split('/');

    return splittedUrl?.[2];
  }, [location]);

  const onArchivedChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await setShowArchived(e.target.checked);
    await refetch();
  };

  const groupedChats = (data || [])
    .filter(({ title }) => title?.toLowerCase()?.includes(search.toLowerCase()))
    .reduce((acc: Record<string, Chat[]>, chat) => {
      const isCurrentMonth =
        isSameMonth(Date.now(), chat.createdAt) &&
        isSameYear(Date.now(), chat.createdAt);
      const formattedDateLabel = isCurrentMonth
        ? 'Latest'
        : format(chat.createdAt, 'MMMM, yyyy');

      acc[formattedDateLabel] = acc[formattedDateLabel]?.length
        ? [...acc[formattedDateLabel], chat]
        : [chat];

      return acc;
    }, {});

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    trackUserActivity();
    setSearch(e.target.value);
  };

  const createNewChat = () => {
    navigate(Routes.NewChat);
    onClose();
  };

  const renderEmptyState = () =>
    isLoading ? <Loader /> : <WidgetEmptyState />;

  return (
    <SwipeableDrawer
      open={open}
      anchor="left"
      onOpen={onOpen}
      onClose={onClose}
      classes={{
        paper: styles.paper,
        root: styles.root
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      <div className={styles.container} data-testid="sidebar-content">
        <div className={styles['logo-container']}>
          <Link data-testid="logo" to={Routes.NewChat} className={styles.logo}>
            <LogoIcon />
          </Link>
          <CloseIcon
            onClick={onClose}
            data-testid="close"
            className={styles.close}
          />
        </div>

        <div className={styles.content}>
          <Button
            fullWidth
            color="primary"
            size="medium"
            variant="contained"
            onClick={createNewChat}
          >
            {t('Common.CreateChat')}
          </Button>
          <TextField
            fullWidth
            autoComplete="none"
            size="medium"
            value={search}
            onChange={onSearchChange}
            placeholder={t('Form.Placeholder.Search')}
            className={styles['input-container']}
            InputProps={{
              className: styles.input,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="search-icon"
                    edge="end"
                    onClick={() => {}}
                    onMouseDown={() => {}}
                  >
                    <SearchIcon className={styles['search-icon']} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControlLabel
            label={t('Form.Label.ShowArchived')}
            classes={{
              root: styles['checkbox-label']
            }}
            control={
              <Checkbox
                data-testid="archive-checkbox"
                color="primary"
                checked={showArchived}
                onChange={onArchivedChange}
                icon={<UncheckIcon />}
                checkedIcon={<CheckIcon />}
                classes={{
                  root: styles['checkbox-container']
                }}
              />
            }
          />
        </div>

        <div className={styles.cards}>
          {Object.keys(groupedChats)?.length
            ? Object.entries(groupedChats).map(([key, value]) => (
                <div key={key} className={styles.group}>
                  <p data-testid="chat-title" className={styles.title}>
                    {key}
                  </p>
                  {value.map((chat) => (
                    <ChatCard
                      id={chat.id}
                      key={chat.id}
                      refetch={refetch}
                      title={chat.title || ''}
                      archived={!!chat.archivedAt}
                      dashboards={chat.dashboards}
                      isActive={currentChatId === chat.id}
                    />
                  ))}
                </div>
              ))
            : renderEmptyState()}
        </div>
      </div>
    </SwipeableDrawer>
  );
};
