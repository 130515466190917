import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/ArrowLeftIcon.svg';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  children?: ReactNode;
}

export const TitleWithBackButton: FC<Props> = ({ title, children }) => {
  const navigate = useNavigate();
  const handleBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <h1 className={styles.title}>
      <ArrowLeftIcon className={styles.back} onClick={handleBackArrowClick} />
      {title || children}
    </h1>
  );
};
