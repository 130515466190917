import { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { IconButton, InputAdornment } from '@mui/material';

import styles from './styles.module.scss';

interface Props {
  isVisible: boolean;
  onTogglePassword: () => void;
}

export const PasswordAdornment: FC<Props> = ({
  isVisible,
  onTogglePassword
}) => (
  <InputAdornment position="end" className={styles.container}>
    <IconButton
      edge="end"
      onClick={onTogglePassword}
      onMouseDown={(e) => e.preventDefault()}
      className={styles.button}
      data-testid="eye-icon-button"
    >
      {isVisible ? (
        <RemoveRedEyeOutlinedIcon
          className={styles.icon}
          data-testid="visibility-on"
        />
      ) : (
        <VisibilityOffOutlinedIcon
          className={styles.icon}
          data-testid="visibility-off"
        />
      )}
    </IconButton>
  </InputAdornment>
);
