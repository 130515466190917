import { useMutation } from '@tanstack/react-query';
import { fetchChatById } from 'actions/chatsActions';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums';
import { queryClient } from 'index';

import { useSnackbar } from '../useSnackbar';

export const useChatById = () => {
  const snackbar = useSnackbar();

  return useMutation<Chat, ApiError, { chatId: string }>({
    mutationFn: ({ chatId }) => fetchChatById(chatId),
    onSuccess(data) {
      const previousArchivedData: Chat[] =
        queryClient.getQueryData(
          queryKeys.filteredChats({ includeArchived: true })
        ) || [];

      queryClient.setQueryData(
        queryKeys.filteredChats({ includeArchived: true }),
        [
          ...previousArchivedData.map((chatData) => {
            if (data.id !== chatData.id) {
              return chatData;
            }

            return data;
          })
        ]
      );
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
