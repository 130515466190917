import { useEffect, useRef } from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { fetchNestedDashboards } from 'actions/dashboardActions';
import axios from 'axios';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { Notification } from 'entities/Notification.entity';
import { InsightsType, queryKeys, Routes } from 'enums';
import { queryClient } from 'index';
import { DEFAULT_NOTIFICATIONS_FILTERS } from 'utils/constants';
import { clearStorage } from 'utils/helpers/authHelpers';

import { useAuthContext } from '../useAuthContext';
import { useSnackbar } from '../useSnackbar';

interface DashboardTextWidgetsData {
  dashboardId: string;
  widgets: DashboardComponent[];
}

interface DashboardTitleData {
  title: string;
  dashboardId: string;
  originDashboardId?: string;
}

export const useSSE = () => {
  const evtSource = useRef<EventSource | null>(null);
  const snackbar = useSnackbar();
  const { isAuthenticated } = useAuthContext();

  const updateDashboardTextWidgets = ({
    dashboardId,
    widgets
  }: DashboardTextWidgetsData) => {
    if (!dashboardId) {
      return;
    }

    const prevDashboardData: DashboardData | undefined =
      queryClient.getQueryData(queryKeys.dashboardDetails(dashboardId));

    if (!prevDashboardData) return;

    const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
      const matchedWidget = widgets.find(({ type }) => type === widget.type);

      if (matchedWidget) {
        return matchedWidget;
      }

      return widget;
    });

    if (updatedWidgets?.length) {
      queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
        ...prevDashboardData,
        widgets: updatedWidgets
      });
    }
  };

  const invalidateSidebarQueries = (originDashboardId?: string) => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.filteredChats({ includeArchived: false })
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.filteredChats({ includeArchived: true })
    });

    if (originDashboardId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(originDashboardId)
      });

      queryClient.fetchQuery({
        queryKey: queryKeys.nestedDashboards(originDashboardId),
        queryFn: () => fetchNestedDashboards(originDashboardId)
      });
    }
  };

  const updateDashboardTitle = ({
    dashboardId,
    title,
    originDashboardId
  }: DashboardTitleData) => {
    if (!dashboardId) {
      return;
    }

    invalidateSidebarQueries(originDashboardId);

    const prevDashboardData: DashboardData | undefined =
      queryClient.getQueryData(queryKeys.dashboardDetails(dashboardId));

    if (!prevDashboardData) return;

    const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
      if (widget.type === InsightsType.DashboardHistory) {
        const updatedData = widget?.params?.data?.map((item) => {
          if (item.id === dashboardId) {
            return {
              ...item,
              title
            };
          }

          return item;
        });

        return {
          ...widget,
          params: {
            ...widget.params,
            data: updatedData
          }
        };
      }

      return widget;
    });

    if (updatedWidgets?.length) {
      queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
        ...prevDashboardData,
        widgets: updatedWidgets
      });
    }
  };

  const updateNotifications = (notification: Notification) => {
    queryClient.setQueryData(
      queryKeys.notifications(DEFAULT_NOTIFICATIONS_FILTERS),
      (notifications: Notification[]) => {
        const filteredNotifications = (notifications || []).filter(
          ({ id }) => id !== notification.id
        );

        return [...filteredNotifications, notification].slice(
          -DEFAULT_NOTIFICATIONS_FILTERS.limit
        );
      }
    );

    queryClient.setQueryData<InfiniteData<Notification[]>>(
      queryKeys.paginatedNotifications,
      (data) => {
        if (!data) return data;

        const isNotificationAlreadyAdded = data.pages[0].find(
          ({ id }: Notification) => id === notification.id
        );

        if (isNotificationAlreadyAdded) return data;

        const newData = JSON.parse(JSON.stringify(data));

        newData.pages[0].unshift(notification);

        for (let i = 0; i < newData.pages.length; i += 1) {
          const currentPage = newData.pages[i];

          if (currentPage.length > DEFAULT_NOTIFICATIONS_FILTERS.limit) {
            const overflowItem = currentPage.pop();

            if (
              newData.pages[i + 1] &&
              newData.pages[i + 1].length < DEFAULT_NOTIFICATIONS_FILTERS.limit
            ) {
              newData.pages[i + 1].unshift(overflowItem);
            }
          }
        }

        const lastPage = newData.pages[newData.pages.length - 1];

        if (lastPage.length > DEFAULT_NOTIFICATIONS_FILTERS.limit) {
          lastPage.pop();
        }

        return newData;
      }
    );
  };

  const createEventSource = () => {
    evtSource.current = new EventSource(
      `${process.env.REACT_APP_BASE_URL}/sse`,
      {
        withCredentials: true
      }
    );

    evtSource.current.addEventListener('dashboard-text-widgets', (event) => {
      try {
        const parsedData = JSON.parse(event?.data) as DashboardTextWidgetsData;
        updateDashboardTextWidgets(parsedData);
      } catch (error) {
        snackbar.error.commonError();
      }
    });

    evtSource.current.addEventListener('dashboard-title', (event) => {
      try {
        const parsedData = JSON.parse(event?.data) as DashboardTitleData;
        updateDashboardTitle(parsedData);
      } catch (error) {
        snackbar.error.commonError();
      }
    });

    evtSource.current.addEventListener('unauthorized-error', (event) => {
      try {
        const parsedData = JSON.parse(event?.data) as DashboardTitleData;
        updateDashboardTitle(parsedData);
      } catch (error) {
        snackbar.error.commonError();
      }
    });

    evtSource.current.addEventListener('notification', (event) => {
      try {
        const parsedData = JSON.parse(event?.data) as Notification;
        const notification = Notification.deserialize(parsedData);

        updateNotifications(notification);
      } catch (error) {
        snackbar.error.commonError();
      }
    });

    evtSource.current.onerror = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkAuth();
    };
  };

  const checkAuth = async () => {
    try {
      // Preliminary check to see if we get a 401 response
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sse`, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status === 401) {
        // Token is expired, try refreshing it
        await axios.post('/auth/refresh-token', undefined, {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true
        });

        evtSource.current?.close();
        evtSource.current = null;
        // If the token is valid, proceed to create the EventSource
        createEventSource();
      }
    } catch (error) {
      evtSource.current?.close();
      evtSource.current = null;
      await axios.post('/auth/logout', undefined, {
        baseURL: process.env.REACT_APP_BASE_URL
      });
      clearStorage();
      queryClient.removeQueries();

      window.location.href = Routes.Auth;
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !evtSource.current) {
      createEventSource();
    }

    if (!isAuthenticated && evtSource.current) {
      evtSource.current?.close();
      evtSource.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};
