import { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

import styles from './styles.module.scss';

export interface NewDevelopmentsItemData {
  date: string;
  title: string;
  content: string;
  isHidden?: boolean;
  imagePath?: string;
}

interface Props extends NewDevelopmentsItemData {
  language: string;
  isLastItem: boolean;
}

export const NewDevelopmentsItem: FC<Props> = ({
  date,
  title,
  content,
  language,
  isHidden,
  imagePath,
  isLastItem
}) =>
  !isHidden ? (
    <div className={styles.container}>
      <div className={cn(styles.timeline, isLastItem && styles.last)}>
        <span className={styles.date}>
          {format(date, 'LLL d, y, p', {
            locale: language === 'de' ? de : enUS
          })}
        </span>
      </div>

      <div className={styles.item}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.content}>{content}</span>

        {imagePath && (
          <div className={styles.image}>
            <img src={imagePath} alt={title} />
          </div>
        )}
      </div>
    </div>
  ) : null;
