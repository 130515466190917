import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import cn from 'classnames';
import { Badge } from 'components/Badge';
import { format } from 'date-fns';
import { Subscription } from 'entities/Subscription.entity';
import {
  Routes,
  SubscriptionCycles,
  SubscriptionPlans,
  SubscriptionStatuses,
  Themes
} from 'enums';
import { useScheduleSubscription, useUserInfo } from 'hooks/api';
import { getPlanCost, getPlanCycle } from 'utils/helpers/subscriptionHelpers';

import styles from './styles.module.scss';

const subscriptionToThemeMap = Object.freeze({
  [SubscriptionStatuses.Active]: Themes.Success,
  [SubscriptionStatuses.Canceled]: Themes.Success,
  [SubscriptionStatuses.Scheduled]: Themes.Warning,
  [SubscriptionStatuses.PaymentFailed]: Themes.Error,
  [SubscriptionStatuses.Inactive]: Themes.Error,
  [SubscriptionStatuses.PaymentActionRequired]: Themes.Error
});

interface Props {
  status: SubscriptionStatuses;
  cycle: SubscriptionCycles;
  plan: SubscriptionPlans;
  renewalDate?: Date;
  scheduledSubscription?: Subscription | null;
}

export const PlanDetails: FC<Props> = ({
  status,
  plan,
  cycle,
  scheduledSubscription,
  renewalDate
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { mutate: scheduleSubscription } = useScheduleSubscription();

  const subscriptionToLabelMap = useMemo(
    () => ({
      [SubscriptionStatuses.Active]: t('Common.SubscriptionStatuses.Active'),
      [SubscriptionStatuses.Inactive]: t(
        'Common.SubscriptionStatuses.Inactive'
      ),
      [SubscriptionStatuses.Canceled]: t('Common.SubscriptionStatuses.Active'),
      [SubscriptionStatuses.PaymentFailed]: t(
        'Common.SubscriptionStatuses.Unpaid'
      ),
      [SubscriptionStatuses.PaymentActionRequired]: t(
        'Common.SubscriptionStatuses.Unpaid'
      ),
      [SubscriptionStatuses.Scheduled]: t(
        'Common.SubscriptionStatuses.Scheduled'
      )
    }),
    [t]
  );

  const title = useMemo(() => {
    const currencyTitlePart = '€';
    const planTitlePart =
      plan === SubscriptionPlans.Premium
        ? t('Common.SubscriptionPlans.Premium')
        : t('Common.SubscriptionPlans.Free');
    const costTitlePart = getPlanCost(plan, cycle);
    const cycleTitlePart = getPlanCycle(plan, cycle);

    let result = `${planTitlePart}, ${costTitlePart}${currencyTitlePart}`;

    if (plan === SubscriptionPlans.Premium) {
      result += ` / ${t(cycleTitlePart)}`;
    }

    return result;
  }, [t, plan, cycle]);

  const note = useMemo(() => {
    if (userInfo?.deactivatedAt) {
      return t('Common.DeactivatedAccountNote');
    }

    if (plan === SubscriptionPlans.Free) return;

    if (!!scheduledSubscription && renewalDate) {
      return t(
        'Page.Profile.Billing.RenewalDateNoteWithScheduledSubscription',
        {
          date: format(renewalDate, 'PP')
        }
      );
    }

    if (renewalDate && status === SubscriptionStatuses.Active) {
      return t('Page.Profile.Billing.RenewalDateNote', {
        date: format(renewalDate, 'PP')
      });
    }

    if (renewalDate && status === SubscriptionStatuses.Canceled) {
      return t('Page.Profile.Billing.RenewalDateNoteCanceled', {
        date: format(renewalDate, 'PP')
      });
    }
  }, [
    userInfo?.deactivatedAt,
    plan,
    scheduledSubscription,
    renewalDate,
    status,
    t
  ]);

  const buttonSettings = useMemo(() => {
    if (plan === SubscriptionPlans.Free) {
      return {
        label: t('Page.Profile.Billing.Buttons.UpgradePlan'),
        hasBrillianceEffect: true,
        onClick: () => navigate(Routes.SubscriptionPlans)
      };
    }

    if (cycle === SubscriptionCycles.Monthly) {
      return {
        label: t('Page.Profile.Billing.Buttons.ChangeYearly'),
        hasBrillianceEffect: true,
        tooltip: (
          <span className={styles['button-tooltip']}>
            {t('Page.Profile.Billing.SaveAmount')}
          </span>
        ),
        onClick: () => {
          scheduleSubscription({ period: SubscriptionCycles.Yearly });
        }
      };
    }

    return {
      label: t('Page.Profile.Billing.Buttons.DowngradeToMonthly'),
      onClick: () => {
        scheduleSubscription({ period: SubscriptionCycles.Monthly });
      }
    };
  }, [cycle, navigate, plan, scheduleSubscription, t]);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Badge
          label={subscriptionToLabelMap[status]}
          theme={subscriptionToThemeMap[status]}
        />

        <span className={styles.title}>{title}</span>

        {note && <span className={styles.note}>{note}</span>}
      </div>
      {!scheduledSubscription && (
        <div className={styles.actions}>
          <div className={styles['button-container']}>
            <LoadingButton
              fullWidth
              className={cn(
                styles.button,
                buttonSettings.hasBrillianceEffect && 'brilliance'
              )}
              size="small"
              color="primary"
              variant="contained"
              onClick={buttonSettings.onClick}
              disabled={!!userInfo?.deactivatedAt}
            >
              {buttonSettings.label}
            </LoadingButton>

            {buttonSettings.tooltip}
          </div>
        </div>
      )}
    </div>
  );
};
