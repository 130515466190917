import {
  ConfirmRestorePasswordPayload,
  ResendEmailPayload,
  RestorePasswordPayload,
  SigninPayload,
  SignupPayload
} from 'actions/authActions';

import { ApiBase } from '../ApiBase';

class AuthApi extends ApiBase {
  signUp({
    userData,
    question,
    appRedirectPath
  }: Omit<SignupPayload, 'shouldRedirect'>) {
    return this.client.post<void>('/sign-up', userData, {
      params: {
        question,
        appRedirectPath
      }
    });
  }

  signIn(payload: SigninPayload) {
    const {
      userData: { password, email: username },
      question,
      appRedirectPath
    } = payload;
    this.client.defaults.auth = {
      password,
      username
    };
    return this.client.post<void>('/sign-in', undefined, {
      params: {
        question,
        appRedirectPath
      }
    });
  }

  confirmEmail(token: string) {
    return this.client.post<void>('/email-confirmation', {
      token
    });
  }

  updateEmailConfirm(token: string) {
    return this.client.post<void>('/email-changed-confirmation', {
      token
    });
  }

  resendEmail({ email, question, appRedirectPath }: ResendEmailPayload) {
    return this.client.post<void>(
      '/resend-confirmation',
      {
        email
      },
      {
        params: {
          question,
          appRedirectPath
        }
      }
    );
  }

  restorePassword({
    email,
    question,
    appRedirectPath
  }: RestorePasswordPayload) {
    return this.client.post<void>(
      '/restore-password',
      {
        email
      },
      {
        params: {
          question,
          appRedirectPath
        }
      }
    );
  }

  logout() {
    return this.client.post<void>('/logout');
  }

  confirmRestorePassword({
    token,
    password,
    question,
    appRedirectPath
  }: ConfirmRestorePasswordPayload) {
    return this.client.post<void>(
      '/confirm-restored-password',
      {
        token,
        password
      },
      {
        params: {
          question,
          appRedirectPath
        }
      }
    );
  }
}

const instance = new AuthApi('/auth');

export { instance as AuthApi };
