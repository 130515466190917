import { useMutation } from '@tanstack/react-query';
import { restorePassword, RestorePasswordPayload } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useRestorePassword = () => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError, RestorePasswordPayload>({
    mutationFn: async (payload) => {
      await restorePassword(payload);
    },
    onSuccess() {
      snackbar.success.passwordResetSuccess();
    },
    onError(error) {
      if (error.statusCode === 403) {
        snackbar.warning.googleResetPasswordWarning();
      } else {
        snackbar.error.commonError(error);
      }
    }
  });
};
