import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AuthLayout,
  Card,
  SignupForm,
  SignupFormData,
  SubTitle,
  Title
} from 'components';
import { GoogleButton } from 'components/GoogleButton';
import { Routes } from 'enums/Routes';
import { useLanguage } from 'hooks';
import { useAuthContext } from 'hooks/useAuthContext';

import styles from './styles.module.scss';

export const SignUp: FC = () => {
  const { t } = useTranslation();
  const { signUp, isLoading } = useAuthContext();
  const preferredLanguage = useLanguage();

  const methods = useForm<SignupFormData>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    }
  });

  const onSubmit = useCallback(
    ({ email, password, firstName, lastName }: SignupFormData) => {
      const trimedEmail = email.trim();
      const trimedFirstName = firstName.trim();
      const trimedLastName = lastName.trim();

      signUp({
        userData: {
          email: trimedEmail,
          password,
          firstName: trimedFirstName,
          lastName: trimedLastName,
          preferredLanguage
        }
      });
    },
    [signUp, preferredLanguage]
  );

  return (
    <AuthLayout>
      <Card>
        <Title className={styles.title} title={t('Page.Auth.Signup.Title')} />
        <SubTitle className={styles.subtitle}>
          {t('Page.Auth.Signup.Subtitle')}
        </SubTitle>
        <FormProvider {...methods}>
          <SignupForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={t('Page.Auth.Signup.Submit')}
          />
        </FormProvider>

        <GoogleButton hasDivider />

        <SubTitle className={styles['footer-subtitle']}>
          {t('Page.Auth.Signup.Note')}
          <Link className={styles['main-link']} to={Routes.Login}>
            {t('Common.Signin')}
          </Link>
        </SubTitle>
      </Card>
    </AuthLayout>
  );
};
