import { FC, ReactNode } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import cn from 'classnames';

import styles from './styles.module.scss';

export type ModalType = 'success' | 'error';

interface Props {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
  paperClassName?: string;
  titleClassName?: string;
  contentClassName?: string;
}

export const Modal: FC<Props> = ({
  title,
  isOpen,
  onClose,
  children,
  className,
  titleClassName,
  paperClassName,
  contentClassName
}) =>
  isOpen ? (
    <Dialog
      classes={{
        paper: cn(styles.modal, paperClassName)
      }}
      open={isOpen}
      onClose={onClose}
      className={cn(styles.content, className)}
      data-testid="modal"
    >
      {onClose && (
        <div data-testid="close" className={styles.close}>
          <IconButton disableRipple onClick={onClose}>
            <CloseIcon className={styles['close-icon']} />
          </IconButton>
        </div>
      )}
      <div
        data-testid="content"
        className={cn(styles.content, contentClassName)}
      >
        {title && (
          <DialogTitle
            data-testid="title"
            className={cn(styles.title, titleClassName)}
          >
            {title}
          </DialogTitle>
        )}
        <div className={styles['main-content']}>{children}</div>
      </div>
    </Dialog>
  ) : null;
