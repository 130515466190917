import { ApiError } from 'entities/ApiError.entity';
import { Subscription } from 'entities/Subscription.entity';
import { OneTimePurchasePlanEnum, SubscriptionCycles } from 'enums';
import { PaymentsApi } from 'services/API/Payments/PaymentsApi';

export const getSubscriptionConfirmation = async (
  sessionId: string
): Promise<void> => {
  try {
    await PaymentsApi.getSubscriptionConfirmation(sessionId);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getOneTimePaymentConfirmation = async (
  sessionId: string
): Promise<void> => {
  try {
    await PaymentsApi.getOneTimePaymentConfirmation(sessionId);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getSubscriptionSecret = async (paymentData: {
  period: SubscriptionCycles;
  page: string | null;
}): Promise<{ clientSecret: string }> => {
  try {
    const response = await PaymentsApi.getSubscriptionSecret(paymentData);

    return response.data;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const scheduleSubscription = async (paymentData: {
  period: SubscriptionCycles;
}): Promise<void> => {
  try {
    await PaymentsApi.scheduleSubscription(paymentData);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getOneTimePaymentSecret = async (paymentData: {
  count: OneTimePurchasePlanEnum;
}): Promise<{
  clientSecret: string;
}> => {
  try {
    const response = await PaymentsApi.getOneTimePaymentSecret(paymentData);

    return response.data;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getActiveSubscription = async (): Promise<Subscription> => {
  try {
    const response = await PaymentsApi.getActiveSubscription();

    return Subscription.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const getSubscriptions = async (): Promise<Subscription[]> => {
  try {
    const response = await PaymentsApi.getSubscriptions();

    return response.data.map(Subscription.deserialize);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const managePayments = async (): Promise<{ url: string }> => {
  try {
    const response = await PaymentsApi.managePayments();

    return response.data;
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const cancelSubscription = async (): Promise<void> => {
  try {
    await PaymentsApi.cancelSubscription();
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};

export const renewSubscription = async (): Promise<void> => {
  try {
    await PaymentsApi.renewSubscription();
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};
