import { DashboardData } from 'entities/Dashboard.entity';
import { UserCoin } from 'entities/UserCoin.entity';

import { ApiBase } from '../ApiBase';

class CommonApi extends ApiBase {
  fetchCoins() {
    // TODO: Change to Coin on BE
    return this.client.get<UserCoin>('/credits');
  }

  fetchBaseExternalDashboard(chatId: string, messageId: string) {
    return this.client.get<DashboardData>(
      `/admin/chats/${chatId}/messages/${messageId}/widgets`
    );
  }

  fetchExternalDashboardById(dashboardId: string) {
    return this.client.get<DashboardData>(`/admin/dashboards/${dashboardId}`);
  }
}

const instance = new CommonApi('');

export { instance as CommonApi };
