import { useQuery } from '@tanstack/react-query';
import { fetchChatAnnouncement } from 'actions/chatsActions';
import { ChatAnnouncement } from 'entities/ChatAnnouncement.entity';
import { queryKeys } from 'enums';

export const useChatAnnouncement = () =>
  useQuery<ChatAnnouncement>({
    queryKey: queryKeys.chatsAnnouncement,
    queryFn: () => fetchChatAnnouncement(),
    refetchOnWindowFocus: false,
    retry: 1
  });
