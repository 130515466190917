import { useEffect } from 'react';

export const useUnloadHandlers = () => {
  useEffect(() => {
    // @ts-expect-error error type
    window.unloadHandlers = [];

    window.addEventListener('beforeunload', () => {
      // @ts-expect-error error type
      // eslint-disable-next-line no-unused-expressions
      (window.unloadHandlers || []).forEach((handler) => handler());
    });
  }, []);
};
