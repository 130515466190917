import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetEmptyState } from 'components';
import {
  Cell,
  Pie,
  PieChart as PieChartComponent,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';
// eslint-disable-next-line import/no-unresolved
import { PieSectorDataItem } from 'recharts/types/polar/Pie';

import { ParticlePieChartTooltip } from './ParticlePieChartTooltip';

import styles from './styles.module.scss';

const COLORS = ['#7deab2', '#ffcb7d'];
const OTHERS_COLOR = '#BFC2D6';

const renderActiveShape =
  (activeIndex: number | undefined) => (props: PieSectorDataItem) => {
    const {
      cx = 0,
      cy = 0,
      innerRadius = 0,
      outerRadius = 0,
      startAngle,
      endAngle,
      fill
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          cornerRadius={4}
          endAngle={endAngle}
          fill={fill}
        />
        {activeIndex !== undefined && (
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            cornerRadius={2}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
        )}
      </g>
    );
  };

interface Props {
  data: Array<{
    groupLabel: string;
    label: string;
    value: string;
  }>;
  totalAmount: number;
}

export const ParticlePieChartView: FC<Props> = ({ data, totalAmount }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>();

  const mappedData = data.map(({ label, value }, index) => ({
    name: label,
    value: Math.round((+value * 100) / totalAmount),
    color:
      label.toLowerCase() ===
      t('Page.Dashboard.ParticleCloud.Others').toLowerCase()
        ? OTHERS_COLOR
        : COLORS[index % COLORS.length]
  }));

  const onMouseEnter = (_data: unknown, index: number) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  const handleRemoveTooltip = useCallback((event: MouseEvent) => {
    const isPieChartSector = (
      event?.target as SVGPathElement
    )?.classList?.contains('recharts-sector');

    if (!isPieChartSector) {
      setActiveIndex(undefined);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('mouseover', handleRemoveTooltip);

    return () => {
      document.body.removeEventListener('mouseover', handleRemoveTooltip);
    };
  }, [handleRemoveTooltip]);

  return (
    <>
      {mappedData?.length ? (
        <div className={styles['chart-container']}>
          <ResponsiveContainer>
            <PieChartComponent className={styles['pie-chart']}>
              <Tooltip
                wrapperStyle={{
                  display: activeIndex !== undefined ? 'block' : 'none'
                }}
                cursor={false}
                position={{ y: 30 }}
                content={<ParticlePieChartTooltip />}
              />
              <Pie
                // eslint-disable-next-line react/no-unstable-nested-components
                label={(props: { cx: number; cy: number }) => {
                  return (
                    <g>
                      <text
                        x={props.cx}
                        y={props.cy + 10}
                        fill="#161616"
                        textAnchor="middle"
                        className={styles['total-value']}
                      >
                        100%
                      </text>
                    </g>
                  );
                }}
                startAngle={-270}
                endAngle={90}
                radius={4}
                activeIndex={activeIndex}
                cx="50%"
                cy="50%"
                innerRadius={55}
                outerRadius={127}
                fill="#8884d8"
                dataKey="value"
                paddingAngle={3}
                data={mappedData}
                cornerRadius={0}
                className={styles.pie}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                activeShape={renderActiveShape(activeIndex)}
              >
                {(mappedData || []).map((entry) => (
                  <Cell
                    strokeWidth={0}
                    fill={entry.color}
                    key={`cell-${entry.name}`}
                  />
                ))}
              </Pie>
            </PieChartComponent>
          </ResponsiveContainer>
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </>
  );
};
