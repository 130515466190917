import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { ReactComponent as GieniLogo } from 'assets/logo-inverted.svg';
import { AuthLayout, Card, ErrorScreen, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useAuthContext, useContactGieniUrl } from 'hooks';
import { useEmailConfirmation, useLogout } from 'hooks/api';
import { useResendEmail } from 'hooks/api/useResendEmail';

import styles from './styles.module.scss';

export const Confirm: FC = () => {
  const { logOut } = useAuthContext();
  const { mutate: logOutMutation } = useLogout();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = searchParams.get('token');
  let email = token?.split('.')?.[1];
  email = email?.replace(/\s/g, '');
  const emailDecoded = email && JSON.parse(atob(email))?.sub;

  const {
    mutate: handleConfirmEmailMutation,
    isPending,
    isError,
    isSuccess
  } = useEmailConfirmation(token || '');

  const { mutate: handleResend } = useResendEmail();

  const contactGieniUrl = useContactGieniUrl();
  const appRedirectPath = searchParams.get('appRedirectPath') || undefined;
  const question = searchParams.get('question') || undefined;

  if (!token) {
    navigate(Routes.Auth);
  }

  useEffect(() => {
    if (token) {
      logOut();
      logOutMutation();
      handleConfirmEmailMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    if (emailDecoded) {
      handleResend({ email: emailDecoded, question, appRedirectPath });
    }
  };

  let redirectPath = appRedirectPath || Routes.Login;

  if (appRedirectPath && question) {
    redirectPath = `${appRedirectPath}?question=${question}`;
  }

  return (
    <AuthLayout>
      <Card className={styles.card}>
        {isPending && !isSuccess && (
          <CircularProgress
            thickness={6}
            className={styles.progress}
            data-testid="chat-progress"
          />
        )}
        {!isPending && isSuccess && (
          <>
            <GieniLogo className={styles.logo} />
            <Title
              className={styles.title}
              title={t('Page.Auth.Confirm.Title')}
            />
            <SubTitle className={styles.subtitle}>
              {t('Page.Auth.Confirm.Subtitle')}
            </SubTitle>

            <SubTitle className={styles.instructions}>
              {t('Page.Auth.Confirm.Instructions')}
            </SubTitle>
            <Link to={redirectPath} className={styles['button-container']}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {appRedirectPath
                  ? t('Page.Auth.Confirm.BackToChat')
                  : t('Page.Auth.Confirm.Login')}
              </Button>
            </Link>

            <span
              className={styles['support-info']}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('Page.Auth.Confirm.SupportInfo', {
                  url: contactGieniUrl
                })
              }}
            />
          </>
        )}
        {!isPending && isError && (
          <ErrorScreen
            onClick={onClick}
            title={t('Page.Auth.Confirm.Expired.Title')}
            description={t('Page.Auth.Confirm.Expired.Subtitle')}
            buttonLabel={t('Page.Auth.Confirm.Expired.Button')}
          />
        )}
      </Card>
    </AuthLayout>
  );
};
