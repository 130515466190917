import { useState } from 'react';
import { SessionStorage } from 'services/SessionStorage';
import { SAVED_USER_QUERY } from 'utils/constants';

export const useModals = () => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState(false);

  const openAuthModal = (query: string) => {
    SessionStorage.setItem(SAVED_USER_QUERY, query);
    setAuthModalOpen(true);
  };

  const closeAuthModal = () => setAuthModalOpen(false);

  const openMoreDataModal = () => setMoreDataModalOpen(true);
  const closeMoreDataModal = () => setMoreDataModalOpen(false);

  return {
    isAuthModalOpen,
    isMoreDataModalOpen,
    openAuthModal,
    closeAuthModal,
    openMoreDataModal,
    closeMoreDataModal
  };
};
