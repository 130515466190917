import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollPositionReset = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.history.state?.usr?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);
};
