import { FC } from 'react';
import { AnyLayer, Layer, Source } from 'react-map-gl';
import { MapTypes } from 'enums';
import { FeatureCollection } from 'geojson';

interface Props {
  index?: number;
  mapType?: MapTypes;
  beforeId?: number;
  layerData: AnyLayer;
  sourceData: FeatureCollection;
  sourceOptions?: {
    id?: string;
    cluster?: boolean;
    clusterMaxZoom?: number;
    clusterRadius?: number;
  };
}

export const MapLayer: FC<Props> = ({
  index,
  mapType,
  beforeId,
  layerData,
  sourceData,
  sourceOptions = {}
}) => (
  <Source
    // eslint-disable-next-line react/no-array-index-key
    key={`${mapType || 'default'}-layer-${index || 0}`}
    type="geojson"
    data={sourceData}
    {...sourceOptions}
  >
    <Layer
      beforeId={
        index && beforeId != null
          ? `${mapType || 'default'}-layer-${beforeId}`
          : undefined
      }
      {...layerData}
    />
  </Source>
);
