import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';

export const useExternalDashboardLink = () => {
  const { chatId, dashboardId } = useParams();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  return dashboardData?.originDashboardId
    ? // eslint-disable-next-line no-restricted-globals
      `${location.origin}/admin/chats/${chatId}/messages/${dashboardId}/widgets`
    : // eslint-disable-next-line no-restricted-globals
      `${location.origin}/admin/chats/${chatId}/dashboards/${dashboardId}`;
};
