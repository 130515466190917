import { useQuery } from '@tanstack/react-query';
import { fetchChatDetailsById } from 'actions/chatsActions';
import { Chat } from 'entities/Chat.entity';
import { queryKeys } from 'enums';

export const useChatDetails = (chatId?: string) => {
  return useQuery<Chat>({
    enabled: !!chatId,
    queryKey: queryKeys.chatDetails(chatId || ''),
    queryFn: () => fetchChatDetailsById(chatId || '')
  });
};
