import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import {
  AuthLayout,
  Card,
  ErrorScreen,
  PasswordAdornment,
  Title
} from 'components';
import { compareAsc } from 'date-fns';
import { Routes } from 'enums';
import { useAuthContext } from 'hooks';
import { useConfirmRestorePassword, useLogout } from 'hooks/api';
import {
  validatePasswordIsNotLikeEmail,
  validatePasswordPattern,
  validateRequired
} from 'utils/helpers/validators';

import styles from './styles.module.scss';

type FormData = {
  password: string;
  repeatPassword: string;
};

export const NewPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logOut } = useAuthContext();
  const [searchParams] = useSearchParams();

  const { mutate: logOutMutation } = useLogout();

  const token = searchParams.get('token');
  const tokenData = token?.split('.')?.[1];
  const tokenDataDecoded = tokenData && JSON.parse(atob(tokenData));
  const expiredAt = tokenDataDecoded?.expAt;
  const email = tokenDataDecoded?.sub;
  const showExpiredError = compareAsc(expiredAt, new Date()) === -1;

  const { mutate: confirmResetPassword, isPending } =
    useConfirmRestorePassword();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  const [isRepeatPasswordVisible, setRepeatPasswordVisibility] =
    useState<boolean>(false);

  const methods = useForm<FormData>({
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  });

  const onSubmit = useCallback(
    ({ password }: FormData) => {
      const question = searchParams.get('question') || undefined;
      const appRedirectPath = searchParams.get('appRedirectPath') || undefined;

      confirmResetPassword({
        password,
        token: token || '',
        question,
        appRedirectPath
      });
    },
    [confirmResetPassword, token, searchParams]
  );

  const isFormEdited = methods.formState.isDirty;

  useEffect(() => {
    if (token) {
      logOut();
      logOutMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) {
      navigate(Routes.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <Card>
        {showExpiredError ? (
          <ErrorScreen
            onClick={() => navigate(Routes.ForgotPassword)}
            title={t('Page.Auth.ResetPasswordConfirm.Expired.Title')}
            description={t('Page.Auth.ResetPasswordConfirm.Expired.Subtitle')}
            buttonLabel={t('Page.Auth.ResetPasswordConfirm.Expired.Button')}
          />
        ) : (
          <>
            <Title
              className={styles.title}
              title={t('Page.Auth.NewPassword.Title')}
            />
            <FormProvider {...methods}>
              <form
                className={styles.container}
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div className={styles.label}>
                  {t('Form.Label.NewPassword')}
                </div>
                <Controller
                  name="password"
                  rules={{
                    validate: (value: FormData['password']) => {
                      if (!validateRequired(value)) {
                        return t('Form.Validation.Password.NewRequired');
                      }

                      if (!validatePasswordIsNotLikeEmail(value, email || '')) {
                        return t('Form.Validation.Password.LikeEmail');
                      }

                      if (!validatePasswordPattern(value)) {
                        return t('Form.Validation.Password.NotValid');
                      }

                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="medium"
                      id="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      placeholder={t('Form.Label.NewPassword')}
                      error={!!methods.formState.errors.password?.message}
                      helperText={methods.formState.errors.password?.message}
                      className={styles['input-container']}
                      InputProps={{
                        className: styles.input,
                        endAdornment: (
                          <PasswordAdornment
                            isVisible={isPasswordVisible}
                            onTogglePassword={() =>
                              setPasswordVisibility((value) => !value)
                            }
                          />
                        )
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        maxLength: 32
                      }}
                    />
                  )}
                />

                <div className={styles.label}>
                  {t('Form.Label.RepeatPassword')}
                </div>
                <Controller
                  name="repeatPassword"
                  rules={{
                    validate: (value: FormData['repeatPassword']) => {
                      if (!validateRequired(value)) {
                        return t('Form.Validation.Password.RepeatRequired');
                      }

                      if (methods.getValues()?.password !== value) {
                        return t('Form.Validation.Password.NotMatchPassword');
                      }

                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="medium"
                      id="repeatPassword"
                      type={isRepeatPasswordVisible ? 'text' : 'password'}
                      placeholder={t('Form.Label.RepeatPassword')}
                      error={!!methods.formState.errors.repeatPassword?.message}
                      helperText={
                        methods.formState.errors.repeatPassword?.message
                      }
                      className={styles['input-container']}
                      InputProps={{
                        className: styles.input,
                        endAdornment: (
                          <PasswordAdornment
                            isVisible={isRepeatPasswordVisible}
                            onTogglePassword={() =>
                              setRepeatPasswordVisibility((value) => !value)
                            }
                          />
                        )
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        maxLength: 32
                      }}
                    />
                  )}
                />
                <div className={styles.actions}>
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={!isFormEdited}
                    className={styles.submit}
                    loading={isPending}
                  >
                    {t('Common.SaveChanges')}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </>
        )}
      </Card>
    </AuthLayout>
  );
};
