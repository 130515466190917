import { LocalStorage } from 'services/LocalStorage';
import {
  APP_UI_LANGUAGE,
  AVAILABLE_LANGUAGES,
  DEFAULT_PREFERRED_LANGUAGE
} from 'utils/constants';

import { useUserInfo } from './api';

export const useLanguage = () => {
  const { data: userInfo } = useUserInfo();

  const validateLanguage = (language: string) =>
    AVAILABLE_LANGUAGES.includes(language)
      ? language
      : DEFAULT_PREFERRED_LANGUAGE;

  const [language] = (
    userInfo?.preferredLanguage ||
    (LocalStorage.getItem(APP_UI_LANGUAGE) as string) ||
    DEFAULT_PREFERRED_LANGUAGE
  ).split('-');

  return validateLanguage(language);
};
