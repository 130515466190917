import { custom, deserialize, list, object, serializable } from 'serializr';

import { BlobOptionValue } from './BlobOptionValue.entity';
import { DashboardFilter } from './DashboardFIlter.entity';
import { TreemapItem } from './TreemapItem.entity';

export class DashboardParamsData {
  @serializable
  id?: string = '';

  @serializable
  label: string = '';

  @serializable
  model?: string = '';

  @serializable
  control_system?: string = '';

  @serializable
  bar_capacity?: number = 0;

  @serializable
  max_workpiece_length?: number = 0;

  @serializable
  max_workpiece_thickness?: number = 0;

  @serializable
  max_workpiece_weight?: number = 0;

  @serializable
  number_of_axes?: number = 0;

  @serializable
  power?: number = 0;

  @serializable
  spindle_speed?: number = 0;

  @serializable
  technology?: string = '';

  @serializable
  image_url?: string = '';

  @serializable
  turning_diameter?: number = 0;

  @serializable
  turning_length?: number = 0;

  @serializable
  x_axis?: number = 0;

  @serializable
  y_axis?: number = 0;

  @serializable
  z_axis?: number = 0;

  @serializable
  name: string = '';

  @serializable
  title?: string = '';

  @serializable
  brand?: string = '';

  @serializable(list(object(DashboardFilter)))
  filters?: DashboardFilter[] = [];

  @serializable
  bin_end?: number = 0;

  @serializable
  bin_start?: number = 0;

  @serializable
  count?: number = 0;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: Array<[number, number]>) => {
        return jsonValue;
      }
    )
  )
  value: Array<[number, number, number?, number?]> = [[0, 0]];

  @serializable(list(object(BlobOptionValue)))
  values?: BlobOptionValue[] | null = null;

  @serializable(list(object(TreemapItem)))
  children?: TreemapItem[] | null = null;

  static deserialize(json: Object | string): DashboardParamsData {
    return deserialize(DashboardParamsData, json);
  }
}
