import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Routes } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';

import { RoutesMetaConfig } from './routesMetaConfig';

export const SeoSettings: FC = () => {
  const { isAuthenticated } = useAuthContext();
  const currentUrl = new URL(window.location.href);
  const isDashboardPage = currentUrl.pathname.includes('dashboard');
  const isNewChatPage = currentUrl.pathname === Routes.NewChat;
  const isIndustryChatPage = currentUrl.pathname.includes('industry');
  const isChatPage =
    currentUrl.pathname.includes('chats') && !isNewChatPage && !isDashboardPage;

  const getCanonicalUrl = () => {
    if (currentUrl.pathname === Routes.Root && !isAuthenticated) {
      currentUrl.pathname = Routes.Auth;
    }

    return currentUrl.toString();
  };

  const getRouteConfig = () => {
    if (isChatPage || isIndustryChatPage) {
      return RoutesMetaConfig.dynamic.chat;
    }

    if (isDashboardPage) {
      return RoutesMetaConfig.dynamic.dashboard;
    }

    return RoutesMetaConfig.static?.[window.location.pathname] || {};
  };

  const { title, description } = getRouteConfig();

  return (
    <Helmet>
      <link rel="canonical" href={getCanonicalUrl()} />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
