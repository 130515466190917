import { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import { validateRequired } from 'utils/helpers/validators';

import styles from './styles.module.scss';

export type CompleteUserInfoFormData = {
  lastName: string;
  company: string;
};

interface Props {
  isLoading: boolean;
  submitLabel: string;
  children?: ReactNode;
  onSubmit: (data: CompleteUserInfoFormData) => void;
}

export const CompleteUserInfoForm: FC<Props> = ({
  onSubmit,
  isLoading,
  submitLabel,
  children
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors, defaultValues }
  } = useFormContext<CompleteUserInfoFormData>();

  const hasLastName = defaultValues?.lastName;

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!hasLastName && (
        <>
          <div className={styles.label}>{t('Form.Label.LastName')}</div>
          <Controller
            name="lastName"
            rules={{
              validate: (value: CompleteUserInfoFormData['lastName']) => {
                const trimmedLastName = value.trim();

                if (!validateRequired(trimmedLastName)) {
                  return t('Form.Validation.LastName.Required');
                }

                return true;
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="lastName"
                size="medium"
                placeholder={t('Form.Placeholder.LastName')}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                className={styles['input-container']}
                inputProps={{
                  className: styles.input
                }}
              />
            )}
          />
        </>
      )}

      <div className={styles.label}>{t('Form.Label.CompanyName')}</div>
      <Controller
        name="company"
        rules={{
          validate: (value: CompleteUserInfoFormData['company']) => {
            const trimmedCompany = value.trim();

            if (!validateRequired(trimmedCompany)) {
              return t('Form.Validation.CompanyName.Required');
            }

            return true;
          }
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="company"
            size="medium"
            placeholder={t('Form.Placeholder.CompanyName')}
            error={!!errors.company}
            helperText={errors.company?.message}
            className={styles['input-container']}
            inputProps={{
              className: styles.input
            }}
          />
        )}
      />

      {children}

      <LoadingButton
        size="medium"
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        className={styles.submit}
      >
        {submitLabel}
      </LoadingButton>
    </form>
  );
};
