import { loadStripe, Stripe } from '@stripe/stripe-js';

export function initializeStripe(stripePromise: Promise<Stripe | null> | null) {
  if (!stripePromise) {
    // eslint-disable-next-line no-param-reassign
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
  }

  return stripePromise;
}
