import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { deleteUserAvatar, fetchUserInfo } from 'actions/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useDeleteUserAvatar = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  return useMutation<void, ApiError>({
    mutationFn: async () => {
      await deleteUserAvatar();
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.userInfo
      });
      await queryClient.fetchQuery({
        queryKey: queryKeys.userInfo,
        queryFn: fetchUserInfo
      });
      await queryClient.removeQueries({
        queryKey: queryKeys.userAvatar
      });

      snackbar.success.commonSuccess(t('Notifications.ChangesSaved'));
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
