import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { updateEmailConfirm } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';

import { useSnackbar } from '../useSnackbar';

export const useUpdateEmailConfirm = (token: string, onSuccess: () => void) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<null, ApiError>({
    mutationFn: () => updateEmailConfirm(token),
    onError: (error) => {
      if (error.statusCode !== 410) {
        snackbar.error.emailConfirmationError(error);
        navigate(Routes.Auth);
      }
    },
    onSuccess
  });
};
