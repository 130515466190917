import { useMutation } from '@tanstack/react-query';
import { updateWidgetFilter } from 'actions/dashboardActions';
import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { MapTypes, queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useUpdateWidgetFilter = ({
  widgetId,
  dashboardId
}: {
  widgetId: DashboardComponent['id'];
  dashboardId: string;
}) => {
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      filters: string[];
      mapType?: MapTypes;
    }
  >({
    mutationFn: ({ filters, mapType }) =>
      updateWidgetFilter({ dashboardId, widgetId, filters, mapType }),
    onError(error) {
      snackbar.error.commonError(error);
    },
    onSuccess(_data, variables) {
      const prevDashboardData: DashboardData | undefined =
        queryClient.getQueryData(queryKeys.dashboardDetails(dashboardId));

      if (!prevDashboardData) return;

      const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
        if (widget.id === widgetId) {
          return {
            ...widget,
            filters: variables.filters,
            mapType: variables.mapType
          };
        }

        return widget;
      });

      if (updatedWidgets?.length) {
        queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
          ...prevDashboardData,
          widgets: updatedWidgets
        });
      }
    }
  });
};
