import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

export const WidgetEmptyState: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, className)}>
      {t('Common.NothingToDisplay')}
    </div>
  );
};
