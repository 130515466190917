import { ReactNode } from 'react';
import cn from 'classnames';
import { DashboardCard } from 'components';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import {
  ChartUnitTypes,
  ChartUnitTypesThemes,
  InsightsTypesClasses
} from 'enums';
import { InsightsType } from 'enums/InsightsType.enum';
import { DashboardComponentExtended } from 'views/DashboardV1/DashboardV1';

import { BarChart } from './BarChart';
import { BigNumber } from './BigNumber';
import { ChatHistory } from './ChatHistory';
import { ContactUs } from './ContactUs';
import { HistogramChart } from './HistogramChart';
import { ManufactureProfile } from './ManufactureProfile';
import { Map } from './Map';
import { ParticleCloud } from './ParticleCloud';
import { PieChart } from './PieChart';
import { TreemapChart } from './TreemapChart';

import styles from './styles.module.scss';

export const mapWithClassData = (
  widgets: DashboardComponent[]
): DashboardComponentExtended[] =>
  widgets.map((item) => ({
    ...item,
    className: InsightsTypesClasses[item.type]
  }));

export const getDashboardWidget = ({
  isPaid,
  widget,
  isDashboardDownloading
}: {
  widget: DashboardComponentExtended;
  isPaid?: boolean;
  isDashboardDownloading?: boolean;
}) => {
  const curComp: {
    data: ReactNode;
    className?: string;
    iconClassName?: string;
  } = {
    className: '',
    iconClassName: '',
    data: <div />
  };

  if (widget.type === InsightsType.PieChart && widget?.params) {
    curComp.data = <PieChart data={widget.params} />;
  } else if (widget.type === InsightsType.BarChart && widget.params) {
    const type: ChartUnitTypes =
      widget?.params?.type || ChartUnitTypes.Materials;
    const theme = ChartUnitTypesThemes[type];
    curComp.data = <BarChart data={widget.params} theme={theme} />;
  } else if (widget.type === InsightsType.Chat && widget.params) {
    curComp.data = <ChatHistory data={widget.params} />;
  } else if (widget.type === InsightsType.ContactUs) {
    curComp.data = <ContactUs />;
  } else if (widget.type === InsightsType.Histogram && widget.params) {
    curComp.data = <HistogramChart data={widget.params} />;
  } else if (widget.type === InsightsType.Blob) {
    curComp.data = (
      <ParticleCloud
        data={widget}
        isDashboardDownloading={isDashboardDownloading}
      />
    );
  } else if (
    widget.type === InsightsType.Treemap ||
    widget.type === InsightsType.TreemapV2
  ) {
    curComp.data = <TreemapChart data={widget} />;
  } else if (
    widget.type === InsightsType.ManufacturerProfiles &&
    widget.params
  ) {
    curComp.className = styles['no-padding'];
    curComp.data = <ManufactureProfile data={widget.params} />;
  } else if (widget.type === InsightsType.BigNumber && widget.params) {
    curComp.data = <BigNumber data={widget.params} />;
    curComp.className = styles['big-number'];
    curComp.iconClassName = styles['dark-icon'];
  } else if (widget.type === InsightsType.MapDots) {
    curComp.data = <Map data={widget} />;
  } else {
    curComp.data = widget.type;
  }

  return (
    <div key={widget.id} className={cn(styles.card, styles[widget.type])}>
      <DashboardCard
        id={widget.id}
        isPaid={isPaid}
        type={widget.type}
        className={curComp.className}
        hasFeedback={widget.hasFeedback}
        iconClassName={curComp.iconClassName}
      >
        {curComp.data}
      </DashboardCard>
    </div>
  );
};
