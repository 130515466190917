import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export interface GlobalShortcutsContextData {
  chatId: string | null;
  setChatId: Dispatch<SetStateAction<string | null>>;
  chatInput: HTMLInputElement | null;
  setChatInput: Dispatch<SetStateAction<HTMLInputElement | null>>;
  sidebarVisibilityHandler: Function | null;
  setSidebarVisibilityHandler: Dispatch<SetStateAction<Function | null>>;
  shortcutsModalVisibilityHandler: Function | null;
  setShortcutsModalVisibilityHandler: Dispatch<SetStateAction<Function | null>>;
}

export const globalShortcutsContext = createContext<GlobalShortcutsContextData>(
  {
    chatId: null,
    setChatId: () => {},
    chatInput: null,
    setChatInput: () => {},
    sidebarVisibilityHandler: null,
    setSidebarVisibilityHandler: () => {},
    shortcutsModalVisibilityHandler: null,
    setShortcutsModalVisibilityHandler: () => {}
  }
);

export const GlobalShortcutsContextProvider = globalShortcutsContext.Provider;

export const useGlobalShortcutsContext = () =>
  useContext(globalShortcutsContext);
