import { ApiError } from 'entities/ApiError.entity';
import {
  EmailPreferences,
  PreferencesApi
} from 'services/API/Preferences/PreferencesApi';

export const updateEmailPreferences = async (
  data: EmailPreferences
): Promise<void> => {
  try {
    await PreferencesApi.updateEmailPreferences(data);
  } catch (e) {
    // @ts-expect-error error type
    throw ApiError.deserializeFromCatch(e);
  }
};
