import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleIcon } from 'assets/GoogleIcon.svg';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  hasDivider?: boolean;
  redirectUrl?: string;
  question?: string;
}

export const GoogleButton: FC<Props> = ({
  hasDivider,
  redirectUrl,
  question
}) => {
  const { t } = useTranslation();
  const googleAuthUrl = new URL(
    `${process.env.REACT_APP_BASE_URL}/auth/google`
  );

  if (redirectUrl) {
    googleAuthUrl.searchParams.append('appRedirectUrl', redirectUrl);
  }

  if (question) {
    googleAuthUrl.searchParams.append('question', question);
  }

  const handleClick = () => {
    mixpanel?.track(MixpanelEvents.AuthWithGoogle);
  };

  return (
    <div className={styles.container}>
      {hasDivider && (
        <div className={styles.divider}>
          <span>{t('Common.OrDivider')}</span>
        </div>
      )}

      <a
        href={googleAuthUrl.toString()}
        onClick={handleClick}
        className={styles['google-auth']}
      >
        <GoogleIcon data-testid="google-button-icon" />
        {t('Page.Auth.LoginOrSignup.GoogleAuth')}
      </a>
    </div>
  );
};
