import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message } from 'entities/Message.entity';
import { MessageTypes, queryKeys, Routes } from 'enums';
import { useArchiveChat } from 'hooks/api/useArchiveChat';
import { useAuthContext } from 'hooks/useAuthContext';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';
import { macos } from 'platform-detect';

export const useGlobalShortcuts = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { isAuthenticated } = useAuthContext();
  const shortcutsContext = useGlobalShortcutsContext();
  const { mutate: archiveChatMutation } = useArchiveChat(
    shortcutsContext?.chatId || '',
    true
  );

  const handleOpenNewChat = (event: KeyboardEvent) => {
    event.preventDefault();
    navigate(Routes.NewChat);
  };

  const handleFocusChatInput = (event: KeyboardEvent) => {
    if (shortcutsContext?.chatInput) {
      event.preventDefault();
      (shortcutsContext.chatInput as HTMLInputElement).focus();
    }
  };

  const handleCopyLastAnswer = (event: KeyboardEvent) => {
    if (shortcutsContext?.chatId) {
      event.preventDefault();
      const data: Message[] | undefined = queryClient.getQueryData(
        queryKeys.chatMessages(shortcutsContext.chatId)
      );

      if (data?.length) {
        const lastAnswer = data.find(
          (message) => message.type === MessageTypes.answer
        );

        if (lastAnswer?.statement) {
          navigator.clipboard.writeText(lastAnswer.statement);
          snackbar.success.lastAnswerCopySuccess();
        }
      }
    }
  };

  const handleArchiveCurrentChat = (event: KeyboardEvent) => {
    if (shortcutsContext?.chatId) {
      event.preventDefault();

      archiveChatMutation({ isArchived: true });
    }
  };

  const handleToggleSidebar = (event: KeyboardEvent) => {
    if (shortcutsContext?.sidebarVisibilityHandler) {
      event.preventDefault();

      shortcutsContext.sidebarVisibilityHandler();
    }
  };

  const handleOpenShortcutsModal = (event: KeyboardEvent) => {
    if (shortcutsContext?.shortcutsModalVisibilityHandler) {
      event.preventDefault();

      shortcutsContext.shortcutsModalVisibilityHandler();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { metaKey, shiftKey, ctrlKey, key } = event;
    const META_KEY = macos ? metaKey : ctrlKey;

    switch (true) {
      case META_KEY && shiftKey && key === 'o':
        handleOpenNewChat(event);
        break;
      case shiftKey && key === 'Escape':
        handleFocusChatInput(event);
        break;
      case META_KEY && shiftKey && key === 'c':
        handleCopyLastAnswer(event);
        break;
      case META_KEY && shiftKey && key === 'Backspace':
        handleArchiveCurrentChat(event);
        break;
      case META_KEY && shiftKey && key === 's':
        handleToggleSidebar(event);
        break;
      case META_KEY && key === '/':
        handleOpenShortcutsModal(event);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutsContext, isAuthenticated]);
};
