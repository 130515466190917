import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel';

import { useAuthContext } from './useAuthContext';

export const useMixpanelAnalytics = () => {
  const location = useLocation();
  const { isAuthenticated, currentEmail } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && currentEmail) {
      mixpanel?.identify(currentEmail);
    }
  }, [isAuthenticated, currentEmail]);

  useEffect(() => {
    mixpanel?.track_pageview();
  }, [location]);
};
