import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { UseMutateFunction } from '@tanstack/react-query';
import { ApiError } from 'entities/ApiError.entity';
import { Chat } from 'entities/Chat.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes, queryKeys } from 'enums';
import { queryClient } from 'index';
import {
  capitalizeFirstLetter,
  generateTitleFromSlug
} from 'utils/stringUtils';

import { useChatDetails } from './api/useChatDetails';
import { useAuthContext } from './useAuthContext';
import { useLanguage } from './useLanguage';

interface IndustriesConfig {
  [key: string]: {
    title: string;
    suggestions?: string[];
  };
}

interface UseIndustryConversation {
  isMessagesFetched: boolean;
  setMessages: Dispatch<SetStateAction<Message[] | null>>;
  createChat: UseMutateFunction<
    Chat,
    ApiError,
    {
      message?: string;
      subject?: string;
      title?: string;
    },
    unknown
  >;
}

export const useIndustryConversation = ({
  createChat,
  setMessages,
  isMessagesFetched
}: UseIndustryConversation) => {
  const { isAuthenticated } = useAuthContext();
  const { t } = useTranslation();
  const { industry, chatId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [industriesConfig, setIndustriesConfig] =
    useState<IndustriesConfig | null>(null);
  const { data: chatDetails } = useChatDetails(chatId);

  const language = useLanguage();

  const subject = chatDetails?.subject;

  const loadIndustriesConfig = useCallback(() => {
    switch (language) {
      case 'de':
        import('../data/industryConfigs/de.json').then((response) =>
          setIndustriesConfig(response.default)
        );
        break;
      case 'en':
      default:
        import('../data/industryConfigs/en.json').then((response) =>
          setIndustriesConfig(response.default)
        );
        break;
    }
  }, [language]);

  const createChatIfAuthenticated = useCallback(() => {
    if (industry && isAuthenticated && industriesConfig && language) {
      const selectedIndustry = industriesConfig[industry];
      const industryTitle: string = selectedIndustry
        ? capitalizeFirstLetter(selectedIndustry.title)
        : generateTitleFromSlug(industry);

      const chatTitle = t('Page.Chat.Industry.ChatTitleTemplate', {
        industryTitle
      });

      createChat({ subject: industry, title: chatTitle });
    }
  }, [industry, isAuthenticated, industriesConfig, language, t, createChat]);

  const handleSetMessage = (message: Message) => {
    if (chatId) {
      if (!isMessagesFetched) return;

      const messages: Message[] | undefined = queryClient.getQueryData(
        queryKeys.chatMessages(chatId)
      );

      if (messages?.find((m) => m.id === message.id)) return;

      queryClient.setQueryData(
        queryKeys.chatMessages(chatId),
        (previousMessages: Message[]) => [...(previousMessages || []), message]
      );
    } else {
      setMessages((previousMessages) => [...(previousMessages || []), message]);
    }
  };

  const addIndustryMessages = useCallback(() => {
    const activeIndustry = industry || subject;

    if (activeIndustry && industriesConfig) {
      if (industriesConfig[activeIndustry]?.suggestions) {
        const welcomeMessageWithData = {
          id: 'welcome-message-1',
          type: MessageTypes.answer,
          suggestions: industriesConfig[activeIndustry].suggestions,
          requestMoreDataVisible: false,
          statement: t('Page.Chat.Industry.WithData.WelcomeMessage', {
            industry: industriesConfig[activeIndustry].title
          })
        };

        handleSetMessage(welcomeMessageWithData);
      } else {
        const defaultSuggestions = [
          t('Page.Chat.Suggestions.Suggestion1_1'),
          t('Page.Chat.Suggestions.Suggestion1_2'),
          t('Page.Chat.Suggestions.Suggestion1_3'),
          t('Page.Chat.Suggestions.Suggestion1_4')
        ];

        const welcomeMessageWithNoData = {
          id: 'welcome-message-1',
          type: MessageTypes.answer,
          statement: t('Page.Chat.Industry.NoData.WelcomeMessage')
        };
        const welcomeMessageWithDefaultSuggestions = {
          id: 'welcome-message-2',
          type: MessageTypes.answer,
          suggestions: defaultSuggestions,
          statement: t('Page.Chat.Industry.NoData.DefaultSuggestionsMessage'),
          requestMoreDataVisible: false
        };

        handleSetMessage(welcomeMessageWithDefaultSuggestions);
        handleSetMessage(welcomeMessageWithNoData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, industry, industriesConfig, chatId, isMessagesFetched]);

  useEffect(() => {
    if (searchParams.has('lang') || searchParams.has('_gl')) {
      setSearchParams(new URLSearchParams());
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    loadIndustriesConfig();

    return () => {
      setIndustriesConfig(null);
    };
  }, [loadIndustriesConfig]);

  useEffect(() => {
    createChatIfAuthenticated();
  }, [createChatIfAuthenticated]);

  useEffect(() => {
    addIndustryMessages();
  }, [addIndustryMessages]);
};
