import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const SubTitle: FC<Props> = ({ children, className }) => (
  <p className={cn(styles.subtitle, className)}>{children}</p>
);
