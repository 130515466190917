import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useContactGieniUrl } from 'hooks';

import styles from './styles.module.scss';

export const SalesCard = () => {
  const { t } = useTranslation();
  const contactGieniUrl = useContactGieniUrl();

  return (
    <div className={styles.card}>
      <div className={styles.background} />
      <div className={styles.title}>
        {t('Page.Dashboard.ManufactureProfile.SeeMore')}
      </div>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        href={contactGieniUrl}
      >
        {t('Common.ContactGieni')}
      </Button>
    </div>
  );
};
