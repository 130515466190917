import {
  CircleLayer,
  HeatmapLayer,
  type Projection,
  SymbolLayer
} from 'react-map-gl';

export enum HeatmapColors {
  HighDensity = '#E95A5A',
  MediumHighDensity = '#FFA51C',
  MediumLowDensity = '#00D878',
  LowDensity = '#7DEAB2'
}

export const DOT_COLORS = ['#f57a00', '#00aa49', '#C34CC6', '#676767'];
export const MAX_GROUPS_COUNT = 3;
export const DEFAULT_REGIONS_LAYER_ID = 'regions-layer-0';
export const DEFAULT_CLUSTER_LAYER_ID = 'clusters-layer-0';
export const DEFAULT_CLUSTER_SOURCE_ID = 'clusters-source-0';
export const MAP_PROJECTION: Projection = { name: 'mercator' };

export const CLUSTERS_LAYER_DATA: CircleLayer = {
  id: DEFAULT_CLUSTER_LAYER_ID,
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#B5F2D0',
      100,
      '#00BE56',
      750,
      '#FB8A00'
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  }
};

export const COUNT_LAYER_DATA: SymbolLayer = {
  id: 'cluster-layer-1',
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': ['get', 'point_count'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};

export const UNCLUSTERED_LAYER_DATA: CircleLayer = {
  id: 'cluster-layer-2',
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#00BE56',
    'circle-radius': 3
  }
};

export const HEAT_LAYER_DATA: HeatmapLayer = {
  id: `heat-layer-0`,
  type: 'heatmap',
  paint: {
    'heatmap-weight': {
      type: 'identity',
      property: 'point_count'
    },
    'heatmap-intensity': 0.5,
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(181, 242, 208, 0)',
      0.2,
      HeatmapColors.LowDensity,
      0.4,
      HeatmapColors.MediumLowDensity,
      0.5,
      HeatmapColors.MediumHighDensity,
      1,
      HeatmapColors.HighDensity
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20]
  }
};
