import { NotificationTypes } from 'enums/NotificationsTypes.enum';
import { date, deserialize, serializable } from 'serializr';

export class Notification {
  @serializable
  id: string = '';

  @serializable
  userId: string = '';

  @serializable
  type: NotificationTypes = NotificationTypes.Maintenance;

  @serializable
  title: string = '';

  @serializable
  description: string = '';

  @serializable
  link: string | null = null;

  @serializable
  linkTitle: string | null = null;

  @serializable(date())
  viewedAt: Date | null = null;

  @serializable(date())
  createdAt: Date = new Date();

  @serializable
  isHidden?: boolean = false;

  static deserialize(json: Object | string): Notification {
    return deserialize(Notification, json);
  }
}
