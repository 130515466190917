import { AxiosError, AxiosResponse } from 'axios';
import { deserialize, serializable } from 'serializr';

const fallbackError = {
  errorName: 'badRequest',
  message: 'Something went wrong',
  statusCode: 400
};

export class ApiError {
  @serializable
  errorName = '';

  @serializable
  message = '';

  @serializable
  statusCode = 400;

  static deserialize(json: Object | string): ApiError {
    return deserialize(ApiError, json);
  }

  static deserializeFromCatch(error?: AxiosError): ApiError {
    const data: AxiosResponse['data'] = error?.response?.data || fallbackError;

    if (Array.isArray(data?.message)) {
      data.message = String(data.message[0]);
    }

    return ApiError.deserialize(data);
  }
}
