import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { confirmEmail } from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';

export const useEmailConfirmation = (token: string) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return useMutation<null, ApiError>({
    mutationFn: () => confirmEmail(token),
    onError(error) {
      if (error.statusCode !== 410) {
        snackbar.error.emailConfirmationError(error);
        navigate(Routes.Auth);
      }
    }
  });
};
