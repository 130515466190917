import { Themes } from './Themes.enum';

export enum ChartUnitTypes {
  Materials = 'top_materials',
  Models = 'top_models',
  Technologies = 'top_technologies',
  Industries = 'top_industries',
  MachineBrands = 'top_brands',
  ManufacturerCount = 'manufacturer_count',
  CompaniesCount = 'company_count',
  EmployeeCount = 'employee_count',
  MachinesCount = 'machine_count',
  MaterialsPie = 'materials_pie',
  TechnologiesPie = 'technologies_pie'
  // ProductHierarchy = 'product_hierarchy',
  // IndustryHierarchy = 'industry_hierarchy'
}

export const ChartUnitTypesThemes = Object.freeze({
  [ChartUnitTypes.Materials]: Themes.Success,
  [ChartUnitTypes.Models]: Themes.Success,
  [ChartUnitTypes.Technologies]: Themes.Success,
  [ChartUnitTypes.Industries]: Themes.Success,
  [ChartUnitTypes.MachineBrands]: Themes.Success,
  [ChartUnitTypes.ManufacturerCount]: Themes.Success,
  [ChartUnitTypes.MachinesCount]: Themes.Success,
  [ChartUnitTypes.MaterialsPie]: Themes.Success,
  [ChartUnitTypes.TechnologiesPie]: Themes.Success,
  [ChartUnitTypes.CompaniesCount]: Themes.Success,
  [ChartUnitTypes.EmployeeCount]: Themes.Success
});

export const ChartUnitsLabels = Object.freeze({
  [ChartUnitTypes.Materials]: 'Page.Dashboard.WidgetLabels.PieChartMaterials',
  [ChartUnitTypes.Models]: 'Page.Dashboard.WidgetLabels.PieChartModels',
  [ChartUnitTypes.Technologies]:
    'Page.Dashboard.WidgetLabels.PieChartTechnologies',
  [ChartUnitTypes.Industries]: 'Page.Dashboard.WidgetLabels.PieChartIndustries',
  [ChartUnitTypes.MachineBrands]:
    'Page.Dashboard.WidgetLabels.PieChartMachineBrands'
});
