import { useMutation } from '@tanstack/react-query';
import { managePayments } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';

import { useSnackbar } from '../useSnackbar';

export const useManagePayments = () => {
  const snackbar = useSnackbar();

  return useMutation<{ url: string }, ApiError>({
    mutationFn: managePayments,
    onSuccess(data) {
      window.location.href = data.url;
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
