import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import cn from 'classnames';
import { Modal } from 'components';

import styles from './styles.module.scss';

interface Props {
  email?: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}
export const ConfirmEmailChangeModal: FC<Props> = ({
  email = '',
  isOpen,
  onCancel,
  onSubmit
}) => {
  const [confirmEmail, setConfirmEmail] = useState('');

  const changeConfirmEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmEmail(e.target.value);
  };

  const cancelChangeEmail = () => {
    onCancel();
    setConfirmEmail('');
  };

  const submitChangeEmail = () => {
    onSubmit();
    setConfirmEmail('');
  };

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      titleClassName={styles.title}
      title={t('Common.ConfirmEmailChangeModal.Title')}
    >
      <p className={styles.subtitle}>
        {t('Common.ConfirmEmailChangeModal.Subtitle')}
      </p>

      <div className={styles['input-container']}>
        <TextField
          fullWidth
          id="email"
          size="medium"
          value={confirmEmail}
          onChange={changeConfirmEmail}
          error={email !== confirmEmail}
          helperText={
            email !== confirmEmail
              ? t('Common.ConfirmEmailChangeModal.MatchError')
              : ''
          }
          placeholder={t('Form.Placeholder.Email')}
          className={styles['input-container']}
          inputProps={{
            className: styles.input
          }}
        />
      </div>

      <p className={styles['additional-info']}>
        {t('Common.ConfirmEmailChangeModal.AdditionalSubtitle')}
      </p>

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          onClick={submitChangeEmail}
          disabled={email !== confirmEmail}
          className={cn(styles.button, styles.submit)}
        >
          {t('Common.ConfirmEmailChangeModal.Confirm')}
        </Button>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="outlined"
          onClick={cancelChangeEmail}
          className={cn(styles.button, styles.cancel)}
        >
          {t('Common.ConfirmEmailChangeModal.Cancel')}
        </Button>
      </div>
    </Modal>
  );
};
