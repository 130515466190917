import {
  SubscriptionCosts,
  SubscriptionCycles,
  SubscriptionPlans
} from 'enums';

export const getPlanCost = (
  plan: SubscriptionPlans,
  cycle: SubscriptionCycles
): SubscriptionCosts => {
  if (plan === SubscriptionPlans.Free) return SubscriptionCosts.Free;

  return cycle === SubscriptionCycles.Monthly
    ? SubscriptionCosts.Monthly
    : SubscriptionCosts.Yearly;
};

export const getPlanCycle = (
  plan: SubscriptionPlans,
  cycle: SubscriptionCycles
): string => {
  if (plan === SubscriptionPlans.Free) return 'Common.SubscriptionCycles.Month';

  return cycle === SubscriptionCycles.Monthly
    ? 'Common.SubscriptionCycles.Month'
    : 'Common.SubscriptionCycles.Year';
};
