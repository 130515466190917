import { DashboardFilter } from 'entities/DashboardFIlter.entity';
import { DashboardParamsData } from 'entities/DashboardParamsData.entity';

export const deepEqual = (
  firstObj: Record<string, any>,
  secondObj: Record<string, any>
): boolean => {
  if (firstObj === secondObj) return true;
  if (
    typeof firstObj !== 'object' ||
    firstObj === null ||
    typeof secondObj !== 'object' ||
    secondObj === null
  )
    return false;

  const firstObjKeys = Object.keys(firstObj);
  const secondObjKeys = Object.keys(secondObj);

  if (firstObjKeys.length !== secondObjKeys.length) return false;

  return firstObjKeys.every(
    (key) =>
      secondObjKeys.includes(key) && deepEqual(firstObj[key], secondObj[key])
  );
};

export const areArraysOfObjectsEqual = (
  firstArray: Array<Record<string, any>>,
  secondArray: Array<Record<string, any>>
): boolean => {
  if (firstArray.length !== secondArray.length) return false;

  return firstArray.every((firstObj) =>
    secondArray.some((secondObj) => deepEqual(firstObj, secondObj))
  );
};

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const findDuplicatedDashboardFilters = (
  dashboards: DashboardParamsData[],
  filters: DashboardFilter[]
) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dashboards.length; i++) {
    const dashboard = dashboards[i];
    const dashboardFilters = dashboard.filters || [];

    const isDuplicatedFilters = areArraysOfObjectsEqual(
      filters,
      dashboardFilters
    );

    if (isDuplicatedFilters) return dashboard.id;
  }

  return null;
};
