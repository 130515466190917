import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  confirmRestorePassword,
  ConfirmRestorePasswordPayload
} from 'actions/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { Routes } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';

export const useConfirmRestorePassword = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();

  return useMutation<void, ApiError, ConfirmRestorePasswordPayload>({
    mutationFn: async (payload) => {
      await confirmRestorePassword(payload);
    },
    onSuccess: () => {
      let redirectPath: string = Routes.ConfirmRestorePassword;

      if (searchParams.size) {
        redirectPath = `${redirectPath}?${searchParams}`;
      }

      navigate(redirectPath);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
