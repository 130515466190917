import { deserialize, serializable } from 'serializr';

export class BlobOptionValue {
  @serializable
  label: string = '';

  @serializable
  value: number | null = null;

  static deserialize(json: Object | string): BlobOptionValue {
    return deserialize(BlobOptionValue, json);
  }
}
